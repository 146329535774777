import Player from "../player/Player";
import {getWindowDimensions, isAndroid, log, printObj} from "../player/Utils";
import React, {useState, useEffect, useContext} from 'react';
import { useMediaQuery } from 'react-responsive';
import MakerForm from "./MakerForm";
import {MakerContext} from "./MakerContext";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { doc, getDoc, onSnapshot, query, getDocs, where , updateDoc} from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import MaterialButton from "./component/MaterialButton";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import { FacebookAuthProvider, getAuth, getRedirectResult, isSignInWithEmailLink, signInAnonymously, signInWithEmailLink, signOut } from "firebase/auth";
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { abandonChanges, resetStory } from "./MenuController";
import { acceptTermsForUser, cleanMakerQueryString, createUserIfFirstLogin, logloginByRedirect, saveAsPermStory } from "./MakerController";
import * as Sentry from "@sentry/react";
import MyAccountMenu from "../logged/MyAccountMenu";
import Checkbox from '@mui/material/Checkbox';
import { analyticsManager, app } from "..";
import { getId, getInstallations } from "@firebase/installations";
import { isStoryReadyForEdition } from "../logged/StatsController";

function Maker(props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [fbAccessToken, setFbAccessToken] = useState(null);
    const [instagramAccountId, setInstagramAccountId] = useState(null);
    const [instagramLoginType, setInstagramLoginType] = useState(null);
    const [activeForm, setActiveForm] = useState("choose-restaurant");
    const [activePopup, setActivePopup] = useState(null);
    const [featureReset, setFeatureReset] = useState(false);
    const [resetAudio, setResetAudio] = useState(false);
    const [resetStickerOutOfScreen, setResetStickerOutOfScreen] = useState(false);
    const [highlight, setHighlight] = useState(null);
    const [story, setStory] = useState(null);
    const [storyId, setStoryId] = useState(null);
    const [baseStory, setBaseStory] = useState(null);
    const [saving, setIsSaving] = useState(null);
    const [savedTime, setSavedTime] = useState(null);
    const [stickerDrop, setStickerDrop] = useState(null);
    const stickersDraggable = activeForm !== "use-it";
    const [videoLoaded, setVideoLoaded] = useState(null);
    const [reservable, setReservable] = useState(null);
    const [restaurantPhone, setRestaurantPhone] = useState(null);    
    const [previewDisplayed, setPreviewDisplayed] = useState(false); 
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);       
    const [showResetConfirm, setShowResetConfirm] = useState(false);     
    const queryParams = useParams();
    const fbResponse = useContext(AppContext).get("fbResponse");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const [lastLoginStatus, setLastLoginStatus] = useState(null);
    const [showAckSaved, setShowAckSaved] = useState(false); 
    const [showAckError, setShowAckError] = useState(false); 
    const [firstDisplayWarning, setFirstDisplayWarning] = useState(false);     
    const fbInit = useContext(AppContext).get("fbInit");
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [showProgress, setShowProgress] = useState(false); 
    const [showTerms, setShowTerms] = useState(false);
    const [loggedNotAnonymously, setLoggedNotAnonymously] = useState(null);
    const [justSignedFromRedirect, setJustSignedFromRedirect] = useState(null);
    const [storyPreviewCurrentScreen, setStoryPreviewCurrentScreen] = useState(null);
    const navigate = useNavigate();

    const { t } = useTranslation();

    const topBar = 40;
    const makerTotalHeight = makerDesktopHeight + topBar;

    const savingText = saving == null ? "" : saving ? t("Saving") : t("Saved") + new Date(savedTime).toLocaleTimeString();  
    
    document.title = t('TitleMaker');

    document.getElementsByTagName('body')[0].className = 'PatternBackground';
    document.getElementById("theme-color").setAttribute("content", "#b2ede7");

    // TODO mutualize
    const scriptList = document.querySelectorAll("script[type='text/javascript']");
    const convertedNodeList = Array.from(scriptList);
    const hubspotScript = convertedNodeList.find(script => script.id === "hs-script-loader");

    useEffect(() => {
        if (!isTabletOrMobile && hubspotScript == null){
            const script = document.createElement('script');
        
            script.id = "hs-script-loader";
            script.src = "//js-eu1.hs-scripts.com/143936779.js";
            script.async = true;
            script.defer = true;
            script.type = "text/javascript";
        
            document.body.appendChild(script);
        
            // do not remove script when unmounting
        }
      }, []);

    let storySubscription = null;
    async function initStory(authResponse, storyIdFromIG = null){  
        log("initStory");        
        if (!fbInit) return;        

        const db = getFirestore();   
        if (authResponse != null && (authResponse.isAnonymous == null || ! authResponse.isAnonymous)) {                       
            log("connected");            
            if (queryParams.storyId != null || storyIdFromIG != null){
                log("maker with story params");
                const docRef = doc(db, "stories", queryParams.storyId != null ? queryParams.storyId : storyIdFromIG);
                const storyDocument = await getDoc(docRef);
                if (storyDocument.exists()){
                    log("story exists");
                    const story = storyDocument.data();
                    // the story is not published
                    if (story.baseStoryId != null && story.publicationDate == null){   
                        log("the story is not published");      
                        log("storyId: " + storyDocument.id);   
                        setStory(story);
                        setStoryId(storyDocument.id);
                        story.id = storyDocument.id;
                        setBaseStory(story);
                        subscribeToUpdates(storyDocument.id);
                    }
                    // the story is already published
                    else {
                        log("the story is already published");

                        // the story is an anonymous story passed to logged
                        if (storyDocument.data().baseStoryId === storyDocument.id && storyDocument.data().baseStory === true){
                            await updateDoc(docRef, {baseStoryId: null});
                        }

                        const storiesRef = collection(db, "stories");
                        const q = query(storiesRef, where("uid", "==", authResponse.uid), where("baseStoryId", "==", storyDocument.id));
                        log("uid " + authResponse.uid + " baseStoryId " + storyDocument.id);
                        const querySnapshot = await getDocs(q);
                        
                        let existingStoryDoc = null;
                        querySnapshot.forEach((doc) => {
                            existingStoryDoc = doc;
                            });

                        story.id = storyDocument.id;
                        setBaseStory(story);
                        setFirstDisplayWarning(true);
                        
                        // the story has an edition version, we get it    
                        if (existingStoryDoc != null){
                            log("the story has an edition version, we get it");
                            setStory(existingStoryDoc.data());
                            setStoryId(existingStoryDoc.id); 
                            log("storyId: " + existingStoryDoc.id);   
                            subscribeToUpdates(existingStoryDoc.id);                                                         
                        }
                        // the story has no edition version, we create it
                        else {
                            if (! (await isStoryReadyForEdition(story.id))){
                                analyticsManager.logModifyStoryScreenViewTag(story.id, true);
                                navigate("/logged/warningDownsize");
                                return;
                            }
                            log("the story has no edition version, we create it");
                            const storyId = await createStory(authResponse.uid, storyDocument, true);
                            const createdStoryDocRef = doc(db, "stories", storyId);
                            const createdStoryDoc = await getDoc(createdStoryDocRef);
                            setStory(createdStoryDoc.data());
                            setStoryId(storyId); 
                            log("storyId: " + storyId);
                            subscribeToUpdates(storyId);
                        }
                    }
                    const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
                    const storyIdFromQuery = new URLSearchParams(window.location.search).get("story_id");
                    if (popupToOpen != null && storyIdFromQuery != null){
                        setShowProgress(true);
                        getRedirectResult(getAuth())
                            .then((response) => {                  
                                if (response != null){    
                                    // query string used fter redirect (login) 
                                    const credential = FacebookAuthProvider.credentialFromResult(response);                    
                                    const accessToken = credential.accessToken;                     
                                    setFbAccessToken(accessToken);       
                                    logloginByRedirect(analyticsManager, response.user.uid)                                                                  
                                    redirectToPage(popupToOpen);
                                }                                                                
                            }).finally(()=>{setShowProgress(false);});
                    } 
                }
                else {
                    navigate("/logged");
                }
            }
            // the user is logged in but no story is selected in the query params
            else{
                log("maker with no story params");

                const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
                const anonymousUid = new URLSearchParams(window.location.search).get("ani");  
                const storyIdFromQuery = new URLSearchParams(window.location.search).get("story_id");

                const loginTitle = new URLSearchParams(window.location.search).get("login_title");

                //log("popupToOpen " + popupToOpen + " anonymousUid " + anonymousUid + " storyIdFromQuery " + storyIdFromQuery + " loginTitle " + loginTitle);

                // just logged from facebook with redirect for a content, story still not upgraded
                // facebook login with redirect for a content while connected does not pass here (in the standard flow)
                if (story == null && popupToOpen != null && storyIdFromQuery != null){
                    log("Logged : maker after facebook login for a content with redirect");
                    setShowProgress(true);
                    getRedirectResult(getAuth())
                        .then((response) => {                                            
                            if (response != null){    
                                const credential = FacebookAuthProvider.credentialFromResult(response);                    
                                const accessToken = credential.accessToken;                     
                                setFbAccessToken(accessToken); 
                                // login for content from an anonymous id
                                createUserIfFirstLogin(response.user.uid, response.user.email, null, response)
                                    .then((result) => {                         
                                        logloginByRedirect(analyticsManager, response.user.uid);                                                                       

                                        if (result.termsAccepted){
                                            navigationAfterSignInByRedirect(response.user, "facebook");
                                        }
                                        else {
                                            setShowTerms("facebook");   
                                            setShowProgress(false);      
                                        }                           
                                    })
                                    .catch((error) => {                                                                
                                        Sentry.captureMessage(printObj(error));     
                                        setShowProgress(false);                                   
                                    });    
                            }
                        }).catch((error) => {                           
                            if (error.code === 'auth/internal-error') {
                                const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
                                const anonymousUid = new URLSearchParams(window.location.search).get("ani"); 
                                if (popupToOpen === "logged"){                                
                                    navigate("/?errRegistrationLimit=true");      
                                }           
                                else {
                                    // query string used after redirect (login)     
                                    // registration failed -> go back to anonymous 
                                    createOrRetrieveStory(anonymousUid)
                                        .then(()=>{setShowAckError(t('ErrorSubscriptionLimitReached'));redirectToPage(popupToOpen, false);setShowProgress(false);});
                                }
                            }
                            else {
                                navigate("/?error=true");                            
                                Sentry.captureMessage(printObj(error));
                            }
                        });
                } 
                // just logged from facebook with redirect without a content
                else if (story == null && loginTitle != null && anonymousUid != null){
                    log("maker after facebook login without content with redirect");       
                    // TODO should set base story to the story ??             
                    createOrRetrieveStory(anonymousUid, getAuth().currentUser);
                }  
                else if (story == null){                    
                    log("the user is logged in but no story is selected in the query params, we create it");
                    const storyId = await createStory(authResponse.uid, null, true);
                    const docRef = doc(db, "stories", storyId);
                    const storyDocument = await getDoc(docRef);
                    setStory(storyDocument.data());         
                    setStoryId(storyId);                     
                    const baseStory = storyDocument.data();
                    baseStory.id = storyDocument.id;
                    setBaseStory(baseStory);
                    log("storyId: " + storyId);
                    cleanMakerQueryString(storyId);
                    subscribeToUpdates(storyId);  
                }
                // a story is already loaded
                else {
                    log("a story is already loaded");                  
                }                               
            }           
        }
        // the user is not logged in
        else {     
            log("not connected");           
            const auth = getAuth();

            const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
            const anonymousUid = new URLSearchParams(window.location.search).get("ani");  
            const storyIdFromQuery = new URLSearchParams(window.location.search).get("story_id");

            // TODO callback to signin with email should not be in the maker
            // TODO query string if connected from p1 login
            if (isSignInWithEmailLink(auth, window.location.href)) {            
                let email = window.localStorage.getItem('emailForSignIn');
                if (!email) {                
                    email = window.prompt('Please provide your email for confirmation');
                }                    
                if (!email) {
                    navigate("/");
                    return;
                }
                setShowProgress(true);
                signInWithEmailLink(auth, email, window.location.href)
                    .then((response)=> createUserIfFirstLogin(response.user.uid, response.user.email, null, response))
                    .then((result) => {                         
                        logloginByRedirect(analyticsManager, result.getBack.user.uid);
                        log("signInWithEmailLink");                               
                        // Here the email is stored as cookie without popup, but never used outside login        
                        window.localStorage.removeItem('emailForSignIn');                           

                        if (result.termsAccepted){
                            navigationAfterSignInByRedirect(result.getBack.user, "email");
                        }
                        else 
                            setShowTerms("email");
                    })
                    .catch((error) => {                                              
                        if (error.code === 'auth/invalid-action-code') {   
                            Sentry.captureMessage("use of an expired signin link");                         
                            navigate("/?errLinkExpired=true");
                        }
                        else if (error.code === 'auth/internal-error') {
                            const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
                            const anonymousUid = new URLSearchParams(window.location.search).get("ani"); 
                            if (popupToOpen === "logged"){                                
                                navigate("/?errRegistrationLimit=true");      
                            }           
                            else {
                                // query string used after redirect (login)  
                                // registration failed -> go back to anonymous     
                                createOrRetrieveStory(anonymousUid)
                                    .then(()=>{setShowAckError(t('ErrorSubscriptionLimitReached'));redirectToPage(popupToOpen, false);setShowProgress(false);});
                            }
                        }
                        else {
                            navigate("/?error=true");                            
                            Sentry.captureMessage(printObj(error));
                        }
                    });
            }
            // never called?
            /*else if (story == null && popupToOpen != null && storyIdFromQuery != null){
                log("Not logged : maker after facebook login for a content with redirect");
                // TODO should set base story to the story ??
                setShowProgress(true);
                getRedirectResult(getAuth())
                        .then((response) => {                                          
                            if (response != null){    
                                const credential = FacebookAuthProvider.credentialFromResult(response);                    
                                const accessToken = credential.accessToken;                     
                                setFbAccessToken(accessToken); 
                                // login for content from an anonymous id
                                createUserIfFirstLogin(response.user.uid, response.user.email, null, response)
                                    .then((result) => {                         
                                        logloginByRedirect(analyticsManager, response.user.uid);                               

                                        if (result.termsAccepted){
                                            navigationAfterSignInByRedirect(response.user, "facebook");
                                        }
                                        else {
                                            setShowProgress(false);
                                            setShowTerms("facebook");  
                                        }                                  
                                    })
                                    .catch((error) => {                                                                
                                        Sentry.captureMessage(printObj(error));
                                        setShowProgress(false);                                        
                                    });    
                            }
                        }).catch((error) => {                           
                            if (error.code === 'auth/internal-error') {
                                const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
                                const anonymousUid = new URLSearchParams(window.location.search).get("ani"); 
                                if (popupToOpen === "logged"){                                
                                    navigate("/?errRegistrationLimit=true");      
                                }           
                                else {
                                    // query string used after redirect (login)   
                                    // registration failed -> go back to anonymous    
                                    createOrRetrieveStory(anonymousUid)
                                        .then(()=>{setShowAckError(t('ErrorSubscriptionLimitReached'));redirectToPage(popupToOpen, false);setShowProgress(false);});
                                }
                            }
                            else {
                                navigate("/?error=true");                            
                                Sentry.captureMessage(printObj(error));
                            }
                        });
            }*/
            else if (authResponse != null && authResponse.isAnonymous != null && authResponse.isAnonymous){
                createOrRetrieveStory(authResponse.uid);
            }
            else {
                const auth = getAuth();
                const credentials = await signInAnonymously(auth);
                log("user signed in anonymously "/* + printObj(credentials)*/);
                await logAnonymousLogin(credentials.user.uid);       
                analyticsManager.setUserId(credentials.user.uid);
                createOrRetrieveStory(credentials.user.uid);
            }    

        }          
        
        async function logAnonymousLogin(uid){
            const firebaseInstallationId = await getId(getInstallations(app));
            analyticsManager.logFBIdToAnonymousTag(firebaseInstallationId, uid);
        }
    }

    async function navigationAfterSignInByRedirect(user, redirectType){        
        const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
        const anonymousUid = new URLSearchParams(window.location.search).get("ani");   
        const storyId = new URLSearchParams(window.location.search).get("story_id");        
        if (popupToOpen === "logged"){
            if (storyId != null){
                await saveAsPermStory(user, storyId, anonymousUid);
            }
            navigate("/logged");      
        }           
        else {
            // query string used fter redirect (login)      
            createOrRetrieveStory(anonymousUid, user)
                .then(()=>{
                    redirectToPage(popupToOpen);
                    setJustSignedFromRedirect(redirectType);
                    setShowProgress(false);  
                })
                .then(()=>{if (redirectType === "email") cleanMakerQueryString(storyId);});            
        }
    }

    function redirectToPage(redirectTo, withPopup = true){ 
        log("redirectToPage: " + redirectTo);        
        switch (redirectTo){            
            case "storefront":
            case "avatar":
                setActiveForm("choose-restaurant");
                break;            
            case "insidepreview":
            case "menupreview":
            case "menu":
                setActiveForm("choose-stickers");
                break;
            case "profile":
                setActiveForm("choose-actions");
                break;   
        }
        if (withPopup)
            setActivePopup(redirectTo);
    }

    async function createOrRetrieveStory(userId, thenUpgradeWithUser = null){        
        const db = getFirestore();
        const storiesRef = collection(db, "stories");
        const q = query(storiesRef, where("uid", "==", userId));
        const querySnapshot = await getDocs(q);
        
        let existingStoryDoc = null;
        querySnapshot.forEach((doc) => {
            existingStoryDoc = doc;
            });

        // the user has a story, we get it
        if (existingStoryDoc != null){
            log("the user has a story, we get it");            
             
            log("storyId: " + existingStoryDoc.id); 
            if (thenUpgradeWithUser != null){
                await saveAsPermStory(thenUpgradeWithUser, existingStoryDoc.id, userId);
                const docRef = doc(db, "stories", existingStoryDoc.id);
                const storyDocument = await getDoc(docRef);                
                setStory(storyDocument.data());
                setStoryId(storyDocument.id);                   
                const baseStory = storyDocument.data();
                baseStory.id = storyDocument.id;
                setBaseStory(story);
                subscribeToUpdates(storyDocument.id);                
            }
            else {
                setStory(existingStoryDoc.data());
                setStoryId(existingStoryDoc.id);  
                subscribeToUpdates(existingStoryDoc.id);              
            }
        }
        // the user has no story (anonymous case), we create it
        else {
            log("the user has no story, we create it");
            const storyId = await createStory(userId, null, false);
            const docRef = doc(db, "stories", storyId);
            const storyDocument = await getDoc(docRef);
            setStory(storyDocument.data());         
            setStoryId(storyId); 
            log("storyId: " + storyId);             
            subscribeToUpdates(storyId);
        }     
        
    }

    async function createStory(uid, baseStoryDoc, logged){
        const db = getFirestore();
        let storyCreationParams = null;
        if (baseStoryDoc != null){
            storyCreationParams = baseStoryDoc.data();
            storyCreationParams.baseStoryId = baseStoryDoc.id;
            storyCreationParams.baseStory = false;
        }
        else {                        
            storyCreationParams = {                    
                creationDate: new Date().getTime(),
                uid: uid,                 
                width: 460, // placeholder
                height: 1024, // placeholder                
                primaryColor: "#2FC4B5",
                secondaryColor: "#FFFFFF",
                menuColor: "#2FC4B5",
                menuStyle: 'simple',
                withMenu: false,
                withDayMenu: false,                                        
            };   
            
            const playerWidth = isTabletOrMobile ? windowDimensions.width : 360;
            const playerHeight = isTabletOrMobile ? windowDimensions.height : makerDesktopHeight;            
            if (playerHeight / playerWidth > storyCreationParams.height / storyCreationParams.width) {
                storyCreationParams.dimWithOffset = "width";
                let trueWidthOnVideoFormat = Math.round(playerWidth * storyCreationParams.height / playerHeight);
                storyCreationParams.offsetValue = storyCreationParams.width - trueWidthOnVideoFormat;           
            } else {
                storyCreationParams.dimWithOffset = "height";
                let trueWidthOnVideoFormat = playerHeight * storyCreationParams.width / playerWidth;
                storyCreationParams.offsetValue = Math.round(storyCreationParams.height - trueWidthOnVideoFormat);           
            }

            if (! logged){
                storyCreationParams.preview = true; // = not saved with a login
                storyCreationParams.savedTime = new Date().getTime();
                storyCreationParams.publicationDate = new Date().getTime(); // preview stories are published by default
            }
            // story created by a connected user, not published by default
            else {
                storyCreationParams.baseStory = true;
                storyCreationParams.publicationDate = null;
            }
        }
        const story = await addDoc(collection(db, "stories"), storyCreationParams);            
                
        if (baseStoryDoc == null){
            const docRef = doc(db, "stories", story.id);
            const update = {
                storyLink: process.env.REACT_APP_HOST + "/p/" + story.id,
            };   
            if (logged){
                update.baseStoryId = story.id;
            }
            await updateDoc(docRef, update); 
        }

        setStory(story);                   
        setStoryId(story.id);

        return story.id;    
    }

    async function subscribeToUpdates(storyId){
        const db = getFirestore();
        let snapshotInit = true;
        let previousBaseStoryId = null;
        storySubscription = await onSnapshot(doc(db, "stories", storyId), (doc) => {               
            // case where the unsubscribe executes and the story has been deleted
            if (doc.data() == undefined && !document.hidden) {                
                return;
            }
                                   
            const monoVersionStoryEditedInAnotherWindow = doc.data() != undefined && !doc.data().baseStoryId && previousBaseStoryId != null && document.hidden;
            const editionCopyNotExistsAnymore = doc.data() == undefined && document.hidden;            
            if (monoVersionStoryEditedInAnotherWindow || editionCopyNotExistsAnymore){
                // redirect with refresh stories on logged                    
                window.location = window.location.protocol + "//" + window.location.host + "/logged";
                return;
            } 
            previousBaseStoryId = doc.data().baseStoryId;            

            if (! snapshotInit){
                const updateStory = doc.data();            
                updateStory.id = storyId;                
                setStory(updateStory);
                setIsSaving(true);
                setSavedTime(new Date().getTime());
                setTimeout(()=>{setIsSaving(false)}, 2000);
            }
            else {
                snapshotInit = false;
            }
          });
    }

    useEffect(() => {  
        const fromInstagram = new URLSearchParams(window.location.search).get("code") || new URLSearchParams(window.location.search).get("state");     
        async function initStoryFromFB(){
            initStory(fbResponse);            
        }  

        if (fromInstagram == null)        
            initStoryFromFB();       
        
        return () => {
            if (storySubscription != null && storySubscription instanceof Function){
                storySubscription();
            }
        };
      }, [fbInit]);   
      
    useEffect(() => {    
        function handleResize() {                
            setWindowDimensions(getWindowDimensions());                
        }
    
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (fbResponse != null){
            // the user passes from not connected to connected
            if (lastLoginStatus == false && !fbResponse.isAnonymous){
                setShowAckSaved(true);
                setLastLoginStatus(true);
            }
            else if (lastLoginStatus == null)
                setLastLoginStatus(!fbResponse.isAnonymous);
        };
    }, [fbResponse]);        

    useEffect(() => { 
        if (fbResponse == null)
            return;
        
        const igAuthCode = new URLSearchParams(window.location.search).get("code");          
        const storyIdFromIG = new URLSearchParams(window.location.search).get("state");  
        
        if (fbResponse != null && igAuthCode != null && storyIdFromIG != null){  
            // query string used fter redirect (login) 
            setShowProgress(true);
            initStoryAndRedirect();                                                            
        } 
        else if (fbResponse != null && igAuthCode == null && storyIdFromIG != null){
            setShowProgress(true);
            initStory(fbResponse, storyIdFromIG)
                .then(() => {
                    redirectToPage(window.localStorage.getItem("redirect_to"));
                    // TODO use state query params to avoid cookie
                    window.localStorage.removeItem("redirect_to");
                    setShowProgress(false);
                })
                .catch((error) => {      
                    setShowProgress(false);                                  
                    Sentry.captureMessage("Instagram Auth, error when canceling the login: " + printObj(error));
                    window.localStorage.removeItem("redirect_to");
                });
        }     

        return () => {
            if (storySubscription != null && storySubscription instanceof Function){
                storySubscription();
            }
        };
        
        async function initStoryAndRedirect(){
            fetch("https://upreality.store/php/instagramapi.php?request=access_token&code=" + igAuthCode + "&redirect_uri=" + process.env.REACT_APP_HOST + "/maker")
                .then((response) => response.json())                
                .then((response) => {
                    setFbAccessToken(response.access_token);
                    setInstagramAccountId(response.user_id);
                    setInstagramLoginType("ig");
                })
                .then(() => initStory(fbResponse, storyIdFromIG))
                .then(() => {
                    redirectToPage(window.localStorage.getItem("redirect_to"));
                    // TODO use state query params to avoid cookie
                    window.localStorage.removeItem("redirect_to");
                    setShowProgress(false);
                })       
                .catch((error) => {  
                    setShowProgress(false);
                    Sentry.captureMessage("Instagram Basic Auth, request access token error: " + printObj(error));
                    window.localStorage.removeItem("redirect_to");
                });
        }

      }, [fbResponse]); 

    useEffect(() => {     
        if (storyId != null){   
            analyticsManager.logChooseRestaurantScreenViewTag(storyId);            
        }
    }, [storyId]);  
    
    // redirect to HP when login expires (TODO the login never expires)
    useEffect(() => {
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous)) {
            setLoggedNotAnonymously(true);
        } else {
            if (loggedNotAnonymously){
                navigate("/?ackDisconnected=true");
            }
        } 
    }, [fbResponse]);

    useEffect(() => {       
        const fromInstagram = new URLSearchParams(window.location.search).get("code"); 
        if(fromInstagram && isAndroid() && navigator.userAgent.includes("Instagram")){           
            window.location = "intent:" + window.location.href + "#Intent;end;";
        }
    }, []);     
    
    function showPlayer(){        
        const previewStyle: CSSProperties = {};
        
        if (isTabletOrMobile){
            // const visibility = previewDisplayed ? 'visible' : 'hidden';            
            // previewStyle.visibility = visibility;
            const display = previewDisplayed ? "" : 'none';
            previewStyle.display = display;
            previewStyle.position = 'absolute';
            previewStyle.top = 0;
            previewStyle.left = 0;
            previewStyle.width = '100%';
            previewStyle.height = '100%';
            previewStyle.background = '#FFFFFF';
        }

        function showCloseButton(){
            if (isTabletOrMobile && storyPreviewCurrentScreen !== "menu"){
                return(
                    <div style={{position: 'absolute', background: 'white', fontWeight: 'bold', color: '#2FC4B5', borderBottomLeftRadius: 10, padding: 8, top: 0, right: 0}} onClick={() => {analyticsManager.logPreviewStoryTag(storyId, false, activeForm);setPreviewDisplayed(false);setFeatureReset(true);}}>                        
                        {t('ClosePreview')}
                    </div>
                );
            }
            return null;
        }
        
        const playerWidth = isTabletOrMobile ? windowDimensions.width : 360;
        const playerHeight = isTabletOrMobile ? windowDimensions.height : makerDesktopHeight;  
        if (storyId != null){            
            return (
                <div style={{...previewStyle}}>
                    <Player fromMaker={true} storyId={storyId} windowDimensions={{width:playerWidth, height:playerHeight}} stickersDraggable={stickersDraggable} setStickerDrop={setStickerDrop} setVideoLoaded={setVideoLoaded} featureReset={featureReset} setFeatureReset={setFeatureReset} resetAudio={resetAudio} setResetAudio={setResetAudio} resetStickerOutOfScreen={resetStickerOutOfScreen} setResetStickerOutOfScreen={setResetStickerOutOfScreen} highlight={highlight} setHighlight={setHighlight} setStoryPreviewCurrentScreen={setStoryPreviewCurrentScreen} />                    
                    {showCloseButton()}
                </div>
            );
        }
        else {
            return null;
        }
    }

    function showCancelConfirmDialog(){
        function close(){
            setShowCancelConfirm(false);
            analyticsManager.logOpenPopupTag(storyId,"abandon_changes",false);
        }

        if (showCancelConfirm){
            return (
                <Dialog onClose={close} open={showCancelConfirm}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('AbandonChanges')}</DialogTitle>                    
                    <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16,}}>
                        {t('AbandonStoryWarning')}
                    </DialogContent>
                    <DialogActions style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', padding: 16,}}>
                        <MaterialButton style="text" value={t('Cancel')} onClick={close}/>
                        <MaterialButton style="text" value={t('Abandon')} onClick={()=>{analyticsManager.logAbandonChangesTag(storyId, true, activeForm);abandonChanges(baseStory, story, storyId, navigate);}}/>
                    </DialogActions>                    
                </Dialog>
            );
        } else return null;
    }

    function showResetConfirmDialog(){
        function close(){
            analyticsManager.logOpenPopupTag(storyId,"reset_story",false);
            setShowResetConfirm(false);
        }
        
        if (showResetConfirm){            
            return (
                <Dialog onClose={close} open={showResetConfirm}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('RestartStory')}</DialogTitle>                    
                    <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16,}}>
                        {t('RestartStoryWarning')}
                    </DialogContent>
                    <DialogActions style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', padding: 16,}}>
                        <MaterialButton style="text" value={t('Cancel')} onClick={close}/>
                        <MaterialButton style="text" value={t('Restart')} onClick={()=>{analyticsManager.logResetStoryTag(storyId, true, activeForm);resetStory(story, storyId, setShowResetConfirm);}}/>
                    </DialogActions>                    
                </Dialog>
            );
        } else return null;
    }

    function showButtons(){
        if (fbResponse != null && fbResponse.isAnonymous != null && fbResponse.isAnonymous && story != null && story.posId != null){
            return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', background: 'white', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>                                        
                    <IconButton aria-label="restart" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "reset_story", true);setShowResetConfirm(true);}}>
                        <CancelIcon />
                    </IconButton>
                    {showResetConfirmDialog()}
                </div>                
            );
        }
        else if (fbResponse != null && fbResponse.isAnonymous != null && !fbResponse.isAnonymous){
            function showCancelButton(){                
                if (story != null && !story.baseStory && activeForm !== "use-it"){
                    return (
                        <IconButton aria-label="cancel" onClick={()=>{analyticsManager.logOpenPopupTag(storyId,"abandon_changes",true);setShowCancelConfirm(true);}}>
                            <CancelIcon />
                        </IconButton>
                    );
                }
                else return null;
            }

            return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', background: 'white', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>                    
                    {showCancelButton()}
                    {showCancelConfirmDialog()}
                </div>
            );
        }
        else return null;
    }

    function showAckSavedSnack(){
        if (showAckSaved){
            return (
                <Snackbar open={showAckSaved} autoHideDuration={10000} onClose={()=>setShowAckSaved(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}  sx={{ width: "90%" }}>
                    <Alert onClose={()=>setShowAckSaved(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckStorySavedPerm')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showAckErrorSnack(){
        if (showAckError){
            return (
                <Snackbar open={showAckError} autoHideDuration={8000} onClose={()=>setShowAckError(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}  sx={{ width: "90%" }}>
                    <Alert onClose={()=>setShowAckError(false)} severity="error" sx={{ width: '100%' }}>     
                    {t('ErrorSubscriptionLimitReached')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showProgressDialog(){

        if (showProgress){
            return (
                <Dialog onClose={()=>{}} open={showProgress}>       
                    <DialogTitle>{t('Loading')}</DialogTitle>                          
                    <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16, paddingBottom: 32}}>                       
                        <CircularProgress sx={{color: '#2FC4B5', }}/>
                    </DialogContent>                                     
                </Dialog>
            );
        }
        return null;
    }

    function showTermsDialog(){
        if (showTerms){ 
            const label = { inputProps: { 'aria-label': 'Checkbox' } };       
            return (
                <Dialog onClose={()=>{}} open={showTerms}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('TermsTitle')}</DialogTitle>  
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", padding: 16}}>   
                        {t('TermsHelp')}<br/><br/>
                        <span>{t('TermsText1')}<a href="/terms/cgu.pdf" target="_blank">{t('TermsText2')}</a>{t('TermsText3')} <Checkbox {...label} id="terms" name="terms" sx={{
                            color: "#2FC4B5",
                            '&.Mui-checked': {
                            color: "#2FC4B5",
                            },
                        }}/></span>
                        <div style={{marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', alignItems: "flex-end", alignContent: 'flex-end', justifyContent: 'flex-end'}}>
                            <MaterialButton style="outlined-tonal" onClick={()=>cancel()} value={t("Cancel")} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<MaterialButton style="filled" onClick={()=>confirm()} value={t("Confirm")} />
                        </div>
                    </div>                        
                </Dialog>
            );              
        }
        else return null;

        function confirm(){            
            if (document.getElementById("terms").checked === false)
                return;
                
            close();      
                   
            acceptTermsForUser(fbResponse.uid);                
            navigationAfterSignInByRedirect({uid: fbResponse.uid}, showTerms);            
        }

        function cancel(){        
            signOut(getAuth()).then(() => {navigate("/?ackDisconnected=true");}); 
        }

        function close(){
            setShowTerms(false);
        }
    }

    if (isTabletOrMobile) {

        const fromInstagram = new URLSearchParams(window.location.search).get("code"); 
        if(fromInstagram && isAndroid() && navigator.userAgent.includes("Instagram")){
            return (     
                <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center', alignContent: 'center', background: 'white', padding: 16}}>
                <img alt="dead end" src="/images/rolling_pin.png" alt="" style={{width: 64, height: 64 }}/>
                <br/>
                <div style={{fontSize: 18}}>{t('ErrorInstagramInAppBrowserTitle')}</div>
                <br/>
                <div style={{fontSize: 14}}>{t('ErrorInstagramInAppBrowserMsg')}</div>                 
                <div style={{fontSize: 14}}>{t('ErrorInstagramInAppBrowserMsg1')}</div>                 
                <div style={{fontSize: 14}}>{t('ErrorInstagramInAppBrowserMsg2')}</div>                                               
            </div>);
        }
        else return (
            <div style={{width: '100%', height: '100%', background: '#FFFFFF', transform: 'translate(-50%, -50%)', position: 'absolute', top: '50%', left: '50%'}}>                                 
                <MakerContext.Provider value={new Map([["instagramLoginType", instagramLoginType], ["setInstagramLoginType", setInstagramLoginType], ["instagramAccountId", instagramAccountId], ["setInstagramAccountId", setInstagramAccountId], ["fbAccessToken", fbAccessToken], ["setFbAccessToken", setFbAccessToken], ["activeForm", activeForm], ["activePopup", activePopup], ["setActivePopup", setActivePopup], ["setActiveForm", setActiveForm], ["windowDimensions", windowDimensions], ["story", story], ["storyId", storyId], ["baseStory", baseStory], ["stickerDrop", stickerDrop], ["setStickerDrop", setStickerDrop], ["videoLoaded", videoLoaded], ["setVideoLoaded", setVideoLoaded], ["setReservable", setReservable], ["reservable", reservable], ["setRestaurantPhone", setRestaurantPhone], ["restaurantPhone", restaurantPhone], ["setFeatureReset", setFeatureReset], ["setResetAudio", setResetAudio], ["setResetStickerOutOfScreen", setResetStickerOutOfScreen], ["setHighlight", setHighlight], ["savingText", savingText], ["setPreviewDisplayed", setPreviewDisplayed], ["justSignedFromRedirect", justSignedFromRedirect]])}>
                    <MakerForm previewDisplayed={previewDisplayed} firstDisplayWarning={firstDisplayWarning} setFirstDisplayWarning={setFirstDisplayWarning} />  
                    {showPlayer()}  
                    {showAckSavedSnack()}
                    {showAckErrorSnack()}
                    {showTermsDialog()}
                </MakerContext.Provider>                         
            </div>
        );
    }
    else {      
        let savingMarginLeft = 0;
        if (fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous && story != null && !story.baseStory)
            savingMarginLeft = 48;
        else if (fbResponse != null && fbResponse.isAnonymous != null && fbResponse.isAnonymous && story != null && story.posId != null)
            savingMarginLeft = 48;                

        return (
            <div style={{width: "100%", height: "100%", position: "absolute"}}>
                {fbResponse != null && ! fbResponse.isAnonymous &&<div style={{position: 'absolute', top: window.innerHeight/2 - makerTotalHeight/2 + topBar + 0, left: window.innerWidth/2 - 720/2 - 260, backgroundColor: 'white', padding: 8, borderRadius: 10, width: 200}}>
                    <MaterialButton style="text" onClick={()=>{analyticsManager.logListStoriesTag(storyId, activeForm);navigate("/logged");}} value={t('MyStories')}/>
                </div>}
                <MakerContext.Provider value={new Map([["instagramLoginType", instagramLoginType], ["setInstagramLoginType", setInstagramLoginType], ["instagramAccountId", instagramAccountId], ["setInstagramAccountId", setInstagramAccountId], ["fbAccessToken", fbAccessToken], ["setFbAccessToken", setFbAccessToken], ["activeForm", activeForm], ["activePopup", activePopup], ["setActivePopup", setActivePopup], ["setActiveForm", setActiveForm], ["windowDimensions", windowDimensions], ["story", story], ["storyId", storyId], ["baseStory", baseStory], ["stickerDrop", stickerDrop], ["setStickerDrop", setStickerDrop], ["videoLoaded", videoLoaded], ["setVideoLoaded", setVideoLoaded], ["setReservable", setReservable], ["reservable", reservable], ["setRestaurantPhone", setRestaurantPhone], ["restaurantPhone", restaurantPhone], ["setFeatureReset", setFeatureReset], ["setResetAudio", setResetAudio], ["setResetStickerOutOfScreen", setResetStickerOutOfScreen], ["setHighlight", setHighlight], ["savingText", savingText], ["justSignedFromRedirect", justSignedFromRedirect]])}>
                    {/* overflow hidden to hide scroll, otherwise bad scrolls on small screen laptops */}
                    <div style={{width: 720, height: makerTotalHeight, display: "flex", flexDirection: "row", transform: 'translate(-50%, -50%)', position: 'absolute', top: '50%', left: '50%', overflow: "hidden"}}>
                        <div style={{width: 360, height: makerTotalHeight, display: "flex", flexDirection: "column", justifyContent: 'flex-end', }}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: 'flex-end', width: 720}}>                            
                                <div id="saving" style={{height: topBar, width: 720, fontSize: 14, fontStyle: "italic", display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: savingMarginLeft}}>{savingText}</div>
                                {showButtons()}
                            </div>
                            <div style={{width: 360, height: makerDesktopHeight, background: '#FFFFFF', borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>                                
                                <MakerForm firstDisplayWarning={firstDisplayWarning} setFirstDisplayWarning={setFirstDisplayWarning}/>                                                                
                            </div>
                        </div>
                        <div style={{marginTop: topBar, }}>
                            {showPlayer()}
                        </div>
                        {showAckSavedSnack()}
                        {showAckErrorSnack()}
                    </div>    
                    {fbResponse != null && <><MyAccountMenu story={story} storyId={storyId}/></>}
                </MakerContext.Provider>                            
                {showProgressDialog()}
                {showTermsDialog()}
            </div>
        );
    }        
}

export default Maker;