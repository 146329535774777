import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {      
      "ProductPageTitle": "Upreality - The social website of your restaurant!",      
      "ProductPageAdTitle": "Upreality - The immersive Instant Ad of your restaurant !",
      "Yes": "Yes",
      "No": "No",
      "Infos": "Infos",
      "OpeningStatus":"Open · Closes",
      "ClosedStatus": "Close · Opens",
      "MoreInfos": "More infos",
      "CGoogle": "data ©Google",
      "GO": "GO",
      "BOOK": "BOOK",
      "SAVE": "SAVE",
      "ShareTitle": "Want to go out to a restaurant?",
      "ShareText": "The link to {{name}}'s social website: ",
      "ShareTextDefaultName": "\"Restaurant not yet chosen\"",
      "BookTable": "Book a table",
      "Contact": "Contact us",
      "BookTableInfo": "or contact us!",
      "BookTableInfoEmoji": "📅",
      "ContactInfoEmoji": "💬",
      "ShareEmoji": "👋",
      "Apply": "Join the adventure",
      "ApplyEmoji": "🚀",
      "BookOnline": "online",
      "BookPhone": "by phone",
      "BookEmail": "by email",
      "BookChat": "by chat",
      "BookEmailSubject": "Booking",
      "ShareEmail": "by email",
      "ShareFacebook": "with Facebook",
      "ShareCopy": "copy in the clipboard",
      "ShareOther": "other ways",
      "OrderOn": "Order on",    
      "VirtualTourTitle": "inside",
      "MediasGalleryTitle": "menu",
      "DishesGalleryTitle": "dishes",
      "TouchToPlay": "Touch to play!",
      "BookButton": "book",
      "ContactButton": "contact",
      "ApplyButton": "apply",
      "ShareButton": "share",
      "ShareShareButton": "share",
      "ShareDialogTitle": "Share to a friend",
      "Hours": "Hours",    
      "HoursSubtitle": "7 next days",
      "HoursInfoExtended": "extended hours on some restaurants",
      "Scroll": "Scroll",
      "HAPPY_HOUR": "Happy hours",
      "BREAKFAST": "Breakfast",
      "BRUNCH": "Brunch",
      "LUNCH": "Lunch",
      "DINNER": "Dinner",
      "DRIVE_THROUGH": "Drive through",
      "DELIVERY": "Delivery",
      "TAKEOUT": "Take out",
      "KITCHEN": "Kitchen",
      "PICKUP": "Pick up",
      "SENIOR_HOURS": "Senior hours",
      "Reviews": "review",
      "Location": "go",
      "Close": "Close",
      "StoryBgDesc": "Store front",
      "RestaurantContent": "Restaurant's media content",
      "TouchToPlay": "Touch to play!",
      "Star": "star",
      "Menu": "Menu",      
      "Click": "click",
      "Here": "here",
      "ToRead": "to read",
      "Menus": "Menus",
      "Starter": "Starters",
      "MainCourse": "Main courses",
      "Dessert": "Desserts",
      "Wine": "Wines",
      "EndingPage": "Note",
      "Nutrients": "Nutritional values",
      "Saving": "Saving...",
        "Saved": "Saved at ",
      "ChooseRestaurant": "Choose a restaurant",
      "ChooseRestaurantOr": "OR",
      "Login": "Log me in",
      "CreateAccount": "Create an account",
      "AlreadyRegistered": "Already registered?",      
      "PlaceholderAutocompleaterHelp": "Enter the name of your restaurant to configure your social website automatically",
      "PlaceholderAutocompleater": "the name of my restaurant",
      "PlaceholderRestaurantType": "my restaurant's type (eg: bistro, etc.)",
      "PlaceholderRestaurantArea": "my neighborhood or my address",
      "PlaceholderPhone": "my phone number",
      "PlaceholderChatMsg": "my welcome message 😊",
      "ChooseRestaurantToStickers": "Stickers > ",
      "MyRestaurant": "My restaurant",
      "MyLogo": "My logo",
      "ChooseMyLogo": "Choose my logo",
      "MyMusic": "My background music",   
      "ChooseMyMusic": "My music",
      "FromInstagram": "from Instagram",
      "Or": "or",
      "FromTheWeb": "from the web",
      "PasteWebLink": "paste here your web link",
      "FromMyComputer": "from my computer",
      "Ok": "Ok",
      "YourColors": "Button color",
      "PrimaryColorBg": "background",
      "PrimaryColorTxt": "text",
      "PreviewButtonLabel": "preview",
      "ThemeToChooseRestaurant": " < 1. Infos",
      "ThemeToStoreFront": "3. Store front > ",
      "MyStoreFront": "My store front",
      "MyStickers": "My stickers",
      "StickersToRestaurant": " < 1. Restaurant",
      "StickersToActions": "2. Actions > ",
      "RestaurantStoreFront": "Store front of the restaurant",
      "ChooseMyStoreFront": "Choose my store front",
      "ChooseMyStoreFrontHelp": "short video or image",
      "ChooseMyInside": "Choose my inside",
      "ChooseMyInsideHelp": "short video or image (2 max)",
      "ChooseMyPlates": "Choose my plates (+ team)",
      "ChooseMyPlatesHelp": "short video or image (4 max)",
      "ChooseMyPlatesHelp2": "our recommendation : 3 plates photos + 1 team video",
      "RestaurantInside": "Restaurant's inside",
      "RestaurantInsideMax": "2 contents maximum",
      "PlatesPreview": "Preview of the plates (+ team)",
      "PlatesPreviewMax": "4 contents maximum",
       "MyActions" : "My actions",
       "BookButtonTitle": "Book button", 
       "SocialWebsiteConfig": "Social Website Configuration", 
       "AddMyMenu" : "Menu",
       "AddMyMenuDialog" : "Add my menu 📋",
       "AddMyMenuEN" : "Add my menu in english",
       "AddMyMenuENDialog" : "Add my menu in english 📋",
       "AddSymbolFR": "FR",
       "AddSymbolEN": "EN",
       "AddMyMenuHelp" : "web page, image or PDF",
       "AddMyMenuHelp2" : "The layout of your menu will be ready within 48 hours. In the meantime, it will be available in a simpler form (image or pdf)",
       "AddMyDayMenu" : "Add my daily menu",
       "AddMyDayMenuDialog" : "Add my daily menu 📋",
       "ActionsToStickers": " < 2. Stickers",
       "MenuToUse": "4. Use it > ",
       "UseItToMenu": " < 3. Actions",
       "ConfigureMyPixels": "Configure my pixels",
       "ConfigureMyPixelsDialog": "Configure my pixels ⚙️",
       "Meta": "Meta",
        "TikTok": "TikTok",
       "aliasAlreadyExists": "alias already exists",       
       "aliasNotValid": "letters, numbers and -",
       "Congratulation": "Congratulations 🍾",       
        "StoryLink": "Link of your social website",
        "CopyLink": "Copy the link",
        "PossibleUsages": "Possible usages",
        "SocialeProfile": "Social profile: as website link",       
        "SocialAds": "Social ad: as action link",        
        "Website": "as QR Code or full-fledged Website",
        "SaveWork": "Without an UpReality account, your social website will be deleted after 1 month.",
        "Save": "Create an account",
        "MyStorys": "My social websites",
        "ChooseMyCover": "Choose the style of my cover",
        "MenuStyleHelp": "to be displayed in our social website",
        "MenuTitleColor": "Color of the title",
        "MenuTitleExample": "Starter",
        "Cancel": "Cancel",
        "Confirm": "Confirm",
        "Elegant": "Elegant",
        "Friendly": "Friendly",
        "Modern": "Modern",
        "Simple": "Simple",
        "Custom": "Custom",
        "OptimMyMenu": "Optimized format for mobile",                
        "InfoOptimMyMenu": "Login to enable the optimized format",        
        "OptimMyMenuHelp": "menu with less than 50 items",
        "OptimStatus": "requested on {{date}}",
        "MenuPreview": "preview of the page's header:",
        "DishImages": "Dish images and correspondences",
        "Optional": "(optional)",
        "SendWithWeTransfer": "Send with WeTransfer",
        "LoginForOptimizedFormatp1": "Login",
        "LoginForOptimizedFormatp2": " to gain access to the optimized format",
        "ChooseYourMenuStyle": "Layout my menu in mobile format",
        "MenuStyle": "Menu style",
        "CoverStyle": "Cover style",
        "ChooseYourMenuColor":  "Header color of my pages",
        "ChooseYourMenuColorHelp":  "The cover uses the colors defined on tab \"Theme\"",
        "ChooseYourMenuCustom": "I want my own style based on my existing menu.",        
        "ChooseYourMenuCustomAlreadyFormated": "My custom menu already laid out.",
        "ChooseStyleValidationHelp1": "Your menu will be laid out within a few days. Try it by clicking on the \"menu\" sticker of your social website.",
        "ChooseStyleValidationHelp2": "You can use your social website now, the menu will be available in the form of the file or the original page until then.",
        "MyStories": "My stories",
        "MenuEditor": "Edit a menu",
        "XViews": " unique views",
        "XUniqueViews": " unique views",
        "XAvgDurationTime": " s (avg.)",
        "Interest": "Interest: ",
        "XShares": " shares",
        "XBookingIntent": " booking intents",
        "XContact": " contacts",
        "XItineraries": " itineraries",
        "XReturns": " returns",
        "Launch": "Launch",
        "Modify": "Modify",
        "CopyWebLink": "Copy the web link",
        "ChooseAFile": "Choose a file",
        "FromMyCamera": "From my video camera",
        "FromMyFiles": "From my files or my camera",
        "OpenMyCamera": "Open my camera",        
        "ErrorImageFormat": "Please choose an image format",
        "ErrorAudioFormat": "Please choose an audio format",
        "LoginWithFacebookBusiness": "Login with Facebook",
        "LoginWithFacebookBusinessHelp": "You have access to the restaurant's Facebook Business account, which is connected to its Instagram account.",
        "LoginWithInstagram": "Login with Instagram",
        "LoginWithInstagramHelp": "You have access to the restaurant's Instagram Business account.",
        "LoginHelp": "Logging in with Facebook or email link automatically create an UpReality account for you if you don't already have one.",
        "Login": "Login",
        "BackToMyStories": "My stories",
        "PublishMyChanges": "Publish",
        "PublishWarning": "Your customers will be able to see the changes made to your social website, the update will be made immediately.", 
        "Publish": "Publish",
        "DeleteStory": "Delete my social website?",
        "DeleteStoryWarning": "This social website and its stats will be deleted permanently.", 
        "Delete": "Delete",
        "ChangeRestaurantWarning": "Changing restaurant will reset all your input, texts and images already saved.",
        "FileFormatError": "Please choose another file format",
        "FileSizeError": "Please choose a file under {{size}} Mb",
        "NoMediaFound": "No content found",
        "ModifyStoryWarning": "The changes made to this social website will only be applied when you publish them ",
        "CreateNewStory": "Create a new social website",
        "Preview": "Preview",
        "AbandonChanges": "Abandon changes",
        "Abandon": "Abandon",
        "AbandonStoryWarning": "The changes made to this social website will be lost.",
        "RestartStory": "Restart the social website",
        "RestartStoryWarning": "The changes made to this social website will be lost.",
        "Restart": "Restart",
        "AcknowledgeAbandonChanges": "The changes have been successfully abandoned.",   
        "AckStorySavedPerm": "You are now registered on the application and your social website has been permanently saved.",        
        "AckLinkCopied": "Link copied!", 
        "AckHoursThreshold": "Only reviews above 4 are displayed",
        "WarningDownsizeRunning": "An optimization of your multimedia content is in progress, this should only take a few minutes.",        
        "AckDisconnected": "You are now disconnected from your UpReality account.",
        "ErrorTitle": "Something went wrong!", 
        "ErrorMsg": "If the problem persists, please contact us.",
        "ErrorAction": "Refresh",
        "ChangeStyle": "Change style",
        "SelectFBPage": "Select the associated Facebook page",
        "ErrorNoFacebookPage": "You don't have a Facebook page associated with your account. Please connect directly from your restaurant's Instagram account.",
        "ErrorNoInstagramAccount": "You don't have an Instagram account associated with your Facebook page. Please connect directly from your restaurant's Instagram account.",
        "ErrorNoIGProfilePicture": "You don't have an Instagram profile picture. Please select instead from a file or a web link.",          
        "ErrorDefault": "Error, please try again later.",
        "PreviousPage": "Previous page",
        "NextPage": "Next page",    
        "YourWelcomeMsg": "My welcome message",
        "YourInfos": "My infos",
        "LinkBooking": "Link my booking platforms",   
        "ConfigureBooking": "Configure ⚙️",  
        "Booking": "Booking",
        "Ordering": "Ordering",   
        "BookingOptionGoogleMaps": "Google Maps page (default)",
        "BookingReplaceByLink": "replace by a web link",
        "BookingLinkHint": "booking web link",
        "BookingLinkHelp": "do not enter anything if you do not want online booking",
        "BookingReplaceByMaps": "replace by the Google Maps page",
        "OrderingAddAnotherPlatform": "add a platform",
        "OrderingLinkHint": "ordering web link",    
        "BookingOptionLink": "By link",
        "BookingOptionPhoneOn": "By phone",
        "BookingOptionPhoneOff": "By phone (disabled)",
        "BookingPhoneHint": "your phone number",
        "BookingOptionPhoneToOff": "do not allow booking by phone",
        "BookingOptionPhoneToOn": "allow booking by phone",
        "BookingOptionEmail": "By email",
        "BookingEmailHint": "your booking email address",
        "BookingOptionChat": "By chat", 
        "ConnectInstagram": "Connect my Instagram profile",
        "ConnectInstagramHelp": "You will have access to your Instagram content to use in your social website (avatar, store front photo, plates photos, etc.).",
        "IGProfileConnected": "Profile {{name}} connected",
        "DisconnectInstagram": "disconnect my profile",
        "EnterEmail": "Enter your email address to login",
        "EnterEmailHelp": "We will send you a link to login to the app and go back to your social website",
        "EnterEmailHelp2": "You will then be able to connect to Instagram to import your content.",
        "EnterEmailHint": "your email address",
        "EmailSent": "Email sent, please check your inbox to go back to the application with your activated account. The email may be in your spam folder.",
        "ConnectEmailLink": "Login with email link",
        "MyAccount": "My account",
        "LogOut": "Log me out",
        "LogOutWarning": "You will be logged out of UpReality.", 
        "ManageMyAccount": "Manage",
        "Hero1": "The",
        "Hero2": " Social Website",
        "Hero3": "of your restaurant 🍹",        
        "Hero41": "Discovery is made on the social feed,",
        "Hero42": "choice on your UpReality website.",  
        "InstantAdHero1": "An",    
        "InstantAdHero2": "immersive Instant Ad",        
        "InstantAdHero3": "for your restaurant 🍹",
        "InstantAdHero41" : "Choose a social format rather than a web page",
        "InstantAdHero42" : " after your social ad.",          
        "CTA": "Create in 1 click",        
        "InstantAdCTA": "Create my Instant Ad",
        "Create": "Create myself",        
        "ProfileInstagramHint": "Instagram profile of the restaurant",        
        "Create1ClickHelp1": "You can optionally send us photos, videos and menu by WeTransfer.\nWithout WeTransfer, we will create your social website from your online content.",
        "Create1ClickHelp2": "Social website editable upon request or manually through the application.",
        "MandatoryField": "Mandatory field",        
        "AckCreate1Click": "Your request has been successfully taken into account, you will receive an Instagram confirmation message within one day.",        
        "SendMyContentWithWeTransfer": "Send my content with WeTransfer",
        "CTA2": "Create myself",
        "CTAbottom":  "Create in 1 click",
        "Benefit1": "As if you were passing by",
        "SubBenefit11": "The real world rather than a traditional website",
        "SubBenefit11det": "A social format rather than a textual and cold web page",
        "SubBenefit12": "All at hand to decide",
        "SubBenefit12det": "Information and action in 1 click",
        "SubBenefit13": "All that's left is to enter",  
        "SubBenefit13det": "Stats to check that your Social Website is more engaging and converts better",  
        "InstantAdBenefit1": "As if you were passing by",
        "InstantAdSubBenefit11": "The real world rather than the website", 
        "InstantAdSubBenefit11det": "A social format rather than a textual and cold web page",  
        "InstantAdSubBenefit12": "All at hand to decide",
        "InstantAdSubBenefit12det": "Information and action in 1 click",
        "InstantAdSubBenefit13": "All that's left is to enter", 
        "InstantAdSubBenefit13det": "Stats to check that your Instant Ad is more engaging and converts better",
        "Benefit2": "Even easier to create and update",
        "SubBenefit21": "Intelligent, it reuses your existing Google, Instagram data and multimedia material",
        "SubBenefit22": "Light, your content is optimized for faster loading",                
        "SubBenefit23": "Universal, you can use it on all your digital and physical media", 
        "InstantAdBenefit2": "Even easier to create and update",
        "InstantAdSubBenefit21": "Simple, a dedicated tool rather than the integrated editor of an advertising platform",        
        "InstantAdSubBenefit22": "Light, your content is optimized for faster loading",                
        "InstantAdSubBenefit23": "Universal, works on your TikTok and Meta advertising platforms",
        "CompareWebsite": "Compare a website adapted to mobile with an UpReality social website",        
        "WebsiteQ1" : "Text and desktop computer world",                
        "WebsiteQ2" : "Long text content",        
        "WebsiteQ3" : "The big story",        
        "StoryQ1" : "Living and mobility world",        
        "StoryQ2" : "Interactive video content",      
        "StoryQ3" : "Useful and easy",
        "FAQ": "FAQ",
        "FAQ1": "FAQ 1",
        "FAQanswer1": "Answer 1",
        "FAQ2": "FAQ 2",
        "FAQanswer2": "Answer 2",
        "FAQ3": "FAQ 3",
        "FAQanswer3": "Answer 3",
        "FAQ4": "FAQ 4",
        "FAQanswer4": "Answer 4",
        "AboutUs": "About us",
        "AboutUsText": "Jacques ...",
        "Terms": "Terms",
        "Privacy": "Privacy",
        "PublicationDate": "Publication date: ",
        "NotPublished": "not published",
        "Loading": "Loading...",
        "ErrorAccountExistsWithDifferentCredential": "You previously logged in with an email link, to be able to reconnect with Facebook, please log in with an email link and go to your preferences to link your Facebook account to your email.",        
        "ErrorFBNotInitialized": "Please disable your ad blocker and refresh the page to access your Instagram content.",
        "ErrorFBNotInitializedAfterLogin": "Please disable your ad blocker to access your Instagram content. After refreshing, you will need to reselect your social website from the list, your changes will be saved",
        "AckInstagramChat": "Your customers can now use the Instagram chat to contact you.",
        "TermsHelp": "To be able to use the application, you must accept the terms of use.",
        "TermsTitle": "Terms of use",
        "TermsText1": "I acknowledge having read and understood the ",
        "TermsText2": "terms of use",
        "TermsText3": " and accept them.",
        "ErrorEmailLinkExpired": "The email link has expired, please log in again to get a new email link.",
        "TitleLogged": "My social websites",
        "TitleMaker": "Social website maker",
        "ContinueToInstagramTitle": "Continue to Instagram",
        "ContinueToInstagram": "You just logged in with an email link, we will now log you in to Instagram to allow you to select your content.",
        "ContinueToInstagramProfile": "You just logged in with an email link, we will now log you in to Instagram.",
        "HoursFromTo": "{{from}} to {{to}}",
        "InfoSwipe": "Swipe to see more",
        "InfoPreview": "After each modification, click on the 'Preview' button to see the result.",
        "EmptyList": "Nothing in the kitchen!",
        "ClosePreview": "Close preview",
        "TabMaker": "Maker",
        "TabStories": "My stories",
        "TabAccount": "My account",
        "ManageMyAccountTitle": "Manage my account",
        "AssociateAccounts": "Associate my accounts",
        "AssociateFacebookToEmail": "Associate my Facebook account to my email account",
        "AssociateEmailToFacebook": "Associate my email account to my Facebook account",
        "EmailLinkedToFacebook": "Your email account is now linked to your Facebook account.",
        "EmailLinkedToFacebookStatus": "Email account linked to Facebook account",
        "ErrorInstagramInAppBrowserTitle": "Dead end!",
        "ErrorInstagramInAppBrowserMsg": "Accept the popup to return to the application on your original browser.",
        "ErrorInstagramInAppBrowserMsg1": "OR",
        "ErrorInstagramInAppBrowserMsg2": "Click on settings at the top right then \"Open in Chrome\"",        
        "MenuAllergens": "List of allergens",
        "MenuDemoMenusTitle": "{{menu}} set menu (X €)",
        "MenuDemoMenusDesc1": "Main course + Dessert",
        "MenuDemoMenusDesc2": "Starter + Main course + Dessert",
        "MenuDemoStarterTitle": "Starter {{nb}}",
        "MenuDemoStarterDesc": "Starter {{nb}} composition",
        "MenuDemoStarterTitleDemo1": "Plate of raw vegetables",
        "MenuDemoStarterDescDemo1": "Salad, tomatoes, cucumbers, green beans",
        "MenuDemoStarterTitleDemo2": "Hot goat cheese salad",
        "MenuDemoStarterDescDemo2": "Salad, hot goat cheese, tomatoes, nuts",
        "MenuDemoStarterTitleDemo3": "Country terrine",
        "MenuDemoStarterDescDemo3": "Homemade, pickles, salad",
        "MenuDemoStarterTitleDemo4": "Vegetable soup",
        "MenuDemoStarterDescDemo4": "Seasonal vegetable soup",
        "MenuDemoStarterTitleDemo5": "Mussels marinières",
        "MenuDemoStarterDescDemo5": "Cooked in white wine, fries", 
        "MenuDemoMainCourseTitle": "Main course {{nb}}",
        "MenuDemoMainCourseDesc": "Main course {{nb}} composition",
        "MenuDemoMainCourseTitleDemo1": "Grilled pork chop",
        "MenuDemoMainCourseDescDemo1": "Served with green beans",
        "MenuDemoMainCourseTitleDemo2": "Roast chicken",
        "MenuDemoMainCourseDescDemo2": "Served with potatoes",
        "MenuDemoMainCourseTitleDemo3": "Duck confit",
        "MenuDemoMainCourseDescDemo3": "Served with hash browns and green salad",
        "MenuDemoMainCourseTitleDemo4": "Fish of the day",
        "MenuDemoMainCourseDescDemo4": "Depending on availability, served with rice",
        "MenuDemoMainCourseTitleDemo5": "Cheese and bacon burger",
        "MenuDemoMainCourseDescDemo5": "Served with fries",
        "MenuDemoDessertTitle": "Dessert {{nb}}",
        "MenuDemoDessertDesc": "Dessert {{nb}} composition",
        "MenuDemoDessertTitleDemo1": "Raspberry tart",
        "MenuDemoDessertDescDemo1": "Homemade",
        "MenuDemoDessertTitleDemo2": "Crème brûlée",
        "MenuDemoDessertDescDemo2": "Homemade",
        "MenuDemoDessertTitleDemo3": "Chocolate mousse",
        "MenuDemoDessertDescDemo3": "Homemade",
        "MenuDemoDessertTitleDemo4": "Fruit salad",
        "MenuDemoDessertDescDemo4": "Fresh fruit",
        "MenuDemoDessertTitleDemo5": "Ice cream",
        "MenuDemoDessertDescDemo5": "3 balls to choose from",
        "WineDemoTitle": "Wine {{nb}}",
        "WineDemoPrices": "37.5 cl: X € - 75 cl: X €",
        "WineDemoPrices2": "12 cl: X €",
        "EndingPageDemoText": "Net prices including service\n\nOur dishes are prepared with fresh and local products, in collaboration with surrounding farms.\n\nMeans of payment: credit card (visa, master card, american express), etc.",
        "TransferToAnotherAccount": "Transfer to another account",
        "TransferRecipient": "New owner email address",
        "TransferHint": "The social website to transfer",
        "TransferToAnotherAccountWarning": "The social website {{name}}, published on {{date}} will be transferred to the UpReality account associated with the email address entered. If the account does not yet exist, the social website will be automatically assigned when it is created.",
        "TransferAction": "Transfer",
        "TransferInProgress": "{{name}} being transferred toward {{email}}",
        "SnackAcceptTransfer":  "Accept the social website transfer for \"{{name}}\"",    
        "SnackAcceptTransferAction": "Accept",      
        "ErrorSubscriptionLimitReached":  "Sorry, the registration limit for our Beta has been reached. We have taken your email and will contact you as soon as a place becomes available.",
        "DeleteMyAccount": "Delete my account",
        "DeleteMyAccountWarning": "Your account and all your stories will be deleted permanently.",
        "DeleteAction": "Delete",
        "AckAccountDeleted": "Your account has been deleted.",        
        "ErrRecentLogin": "Your last login is too old, we cannot delete your account. Please log back in and click this button again to permanently delete your account.",
        "Help": "Help",
        "ReadFAQ": "Read the FAQ",
        "TranslateWithGoogle": "translate with Google",
        "DefaultTranslation": "in french",
        "CheckMyRights": "Check my rights",
        "ReadLegals": "Read the legal notices",
        "Legals": "Legal notices",
        "Others": "Others",
        "Images": "Images",        
        "FAQPriceQuestion": "Is the service free?",                
        "FAQPriceAnswer": "The service as currently offered is free for life for our first customers (up to 3 social websites per customer), paid options will be offered later.",       
        "FAQWebsiteSubstituteQuestion": "I already have a website, why use UpReality?",        
        "FAQWebsiteSubstituteAnswer": "UpReality is designed to be used on social networks, it is the social version of your website. Your website, more adapted to the world of text and desktop, remains the most relevant for web searches. \n\nYou can use UpReality instead of or in addition to your website on your social media profile.\n\nIf you do not have a website, UpReality can be a more economical, current and easy to update alternative. Indeed, UpReality can be used simply and in total autonomy by a restaurateur.",                        
        "FAQInstantAdQuestion": "What is an Instant Ad?",        
        "FAQInstantAdAnswer": "An Instant Ad is a social advertising format offering a simplified form of the commercial offer for a frictionless experience.\nIt extends in-feed advertising with a full-screen experience in the form of a simplified web page (photos, videos, carousels).",        
        "FAQInstantAdDifferenceQuestion": "What is the difference with TikTok Instant Page and Meta Instant Experience?",        
        "FAQInstantAdDifferenceAnswer": "Whereas Instant Page and Instant Experience propose a simplified web format, UpReality offers a real immersive experience in the form of a social website.\nOur Instant Ad proposes a standard loading time for a more attractive and engaging experience, in line with social content.",
        "FAQEasyToUseQuestion": "Is it easy to use?",        
        "FAQEasyToUseAnswer": "UpReality is designed to be used by restaurateurs, it is simple and intuitive. You can create your social website in a few clicks and update it in real time.",
        "FAQProContentsQuestion": "Can I use photos and videos that I have taken myself or do I have to use a professional?",            
        "FAQProContentsAnswer": "You can give free rein to your creativity and use your own photos and videos. Some tips: \n",          
        "FAQProContentsAnswerl0": "Take your photos in portrait for better use of space on mobile.\nCenter your subject, indeed the edges of the top and bottom can be cut on small phones.",
        "FAQProContentsAnswerl1": "Privilege color and natural light (eg at 11am)",        
        "FAQProContentsAnswerl2": "For your dishes, put yourself in the customer's shoes at the right distance. The overhead shot is the most common, but you can also vary according to the dishes.",        
        "FAQProContentsAnswerl3": "Bet on naturalness, do not abuse filters, but do not hesitate to play on contrasts and brightness to bring out your photos.",        
        "FAQProContentsAnswerl4": "Play with utensils that make up the identity of your restaurant (eg: wooden board, glass of wine, etc.)",        
        "FAQProContentsAnswerl5": "You can also use a professional to get the best result.",
        "FAQGoogleDataQuestion":  "Where do my restaurant's data come from?",        
        "FAQGoogleDataAnswer":  "Your restaurant's data: address, name, phone number, opening hours, price class come from your Google Business account. They are public and available on Google Maps and Google My Business. If you want to change them, you can do so on Google My Business.",        
        "FAQSocialNetworksQuestion": "On which social networks can I use my social website?",            
        "FAQSocialNetworksAnswer": "You can use your social website on all social networks. UpReality is more specifically designed to be used on Instagram, TikTok, Snapchat, and Facebook. \n\nPlease note, UpReality does not publish on social networks as a network content, it's a web link that can be integrated in different places, like the web profile link, the web target of a sticker or a social ad, or simply a link in a message.",
        "FAQSocialProfileQuestion": "How to use my social website on my social profile?",          
        "FAQSocialProfileAnswer":   "Go to the \"website\" section of your social profile and paste the link of your social website. It is possible that on some social networks, you will first have to create a professional account.",                
        "FAQSocialAdDestQuestion": "How to use my social website as a destination for social ads?",        
        "FAQSocialAdDestAnswer": "UpReality stories can be used for campaigns with a Traffic or Conversion objective. They are used as the action link of your social ad instead of the website (destination > website).\nInteraction statistics, especially on the book button, are available in the application on the statistics page. On request, we can also integrate your TikTok or Meta pixels (contact@upreality.store).",
        "FAQWebsiteQuestion": "How to use my social website as a website?",           
        "FAQWebsiteAnswer": "Your social website is already published as a website, you can directly use the link. We will soon set up a service allowing you to connect an existing domain name or order your own domain name.\nIn the meantime, you can connect a subdomain that you own (eg: story.yourrestaurant.com) to the url of your social website using a 301 redirection.",                         
        "FAQSocialAdQuestion": "How to use my social website from an action on a social ad?",        
        "FAQSocialAdAnswer": "You can use your social website as an action link from a social ad on all social platforms, copy the link of the social website instead of the website.",                
        "FAQOriginalWaysQuestion": "What other original ways do you suggest?",        
        "FAQOriginalWaysAnswer": "You can use your social website from: \n",                
        "FAQOriginalWaysAnswerl0": "a QR Code (examples of use: flyer, business card, etc.).",        
        "FAQOriginalWaysAnswerl1": "a sticker on social networks that allow it like Instagram.",
        "FAQOriginalWaysAnswerl2": "an additional link in your linktr.ee",        
        "FAQOriginalWaysAnswerl3": "a pinned post on your facebook profile so that your social website is always visible at the top of the feed",        
        "FAQOriginalWaysAnswerl4": "a Google Business post",        
        "FAQOriginalWaysAnswerl5": "an SMS campaign",
        "FAQStatsNoteQuestion": "How is the interest note calculated?",        
        "FAQStatsNoteAnswer": "The interest note is the average of the interest notes of all your visitors. It is calculated according to the activity of the visitor on your social website.",        
        "FAQStatsNoteAnswerl1": "10s passed on your social website gives 1 point.",        
        "FAQStatsNoteAnswerl2": "A click on \"Menu\", \"Inside\", \"Hours\" or \"Reviews\" gives 2 points.\nA click on \"Menu\" or \"Inside\" and one on \"Hours\" or \"Reviews\" gives 2.5 points.\nA click on \"Menu\" and one on \"Inside\" + one on \"Hours\" or \"Reviews\" gives 3 points.",        
        "FAQStatsNoteAnswerl3": "A click on \"Itinerary\" or \"Share\" gives 4 points.",        
        "FAQStatsNoteAnswerl4": "A click on \"Book\" gives 4.5 points.",        
        "FAQStatsNoteAnswerl5": "A return (closing the web page then return) on the social website gives 4.5 points.",
        "FAQStatsQuestion": "À quoi correspondent les statistiques proposées ?",        
        "FAQStatsAnswerUniqueView": "1 unique view corresponds to the visit of a user. If he closes his web browser and decides to come back to your social website, this new visit will not be counted.",        
        "FAQStatsAnswerMoy": "The average duration corresponds to the average visit duration on your social website.",        
        "FAQStatsAnswerShares": "The number of shares corresponds to the number of times a user has clicked on the \"Share\" button.",        
        "FAQStatsAnswerBooks": "The number of booking intents corresponds to the number of times a user has clicked on the \"Book\" button.",      
        "FAQStatsAnswerItinerary": "The number of itineraries corresponds to the number of times a user has clicked on the \"Itinerary\" button.",        
        "FAQStatsAnswerReturn": "The number of returns corresponds to the number of times a user has closed the web page and then returned to your social website.",        
        "FAQMenuTranslatedQuestion": "Will my menu in optimized mobile format be translated for foreign tourists?",        
        "FAQMenuTranslatedAnswer": "If the menu you send us contains the English translation, it will be integrated into your social website. For menus without English translation, users will be able to obtain a automated translation directly from the social website.",        
        "FAQNoFacebookPageQuestion": "When I try to add content from Facebook, I get the error \"You don't have a Facebook page associated with your account\".",        
        "FAQNoFacebookPageAnswer": "To use this feature, you must have a Facebook page for your restaurant and an Instagram account associated with this page.",        
        "FAQNoFacebookPageAnswer2": "Click here to see how to do it",        
        "FAQVideoFormatQuestion": "What video formats can I use?",        
        "FAQVideoFormatAnswer": "Videos in .mp4 and .mov format (h.264 codec or higher) are compatible.",        
        "FAQVideoSizeQuestion": "What is the maximum size for a video?",        
        "FAQVideoSizeAnswer": "The maximum size for a video is 50 Mb.",             
        "FAQVideoRecommendedResolutionQuestion":  "What video resolution do you recommend?",         
        "FAQVideoRecommendedResolutionAnswer": "We recommend using 720 in width x 1280 in height), the best compromise between size and quality. Videos of higher resolution are automatically resized to this resolution.",
        "FAQImageRecommendedResolutionQuestion": "What image resolution do you recommend?",         
        "FAQImageRecommendedResolutionAnswer":  "We recommend using portrait format images, 9/16 for better use of space, at least 720 in width x 1280 in height for better image quality. Images of higher resolution are automatically resized to this resolution and compressed for optimal weight.",
        "FAQImageFormatQuestion": "What image formats can I use?",        
        "FAQImageFormatAnswer": "Images in .jpg, .png, .gif, .webp, .svg and .bmp format are compatible.",        
        "FAQImageSizeQuestion": "What is the maximum size for an image?",        
        "FAQImageSizeAnswer": "The maximum size for an image is 10 Mb, only the avatar is limited to 512 kb.",            
        "FAQMusicFormatQuestion": "What audio file formats can I use?",        
        "FAQMusicFormatAnswer": "Audio files in .wav, .mp3 and .m4a format are compatible.",        
        "FAQMusicSizeQuestion": "What is the maximum size for an audio file?",        
        "FAQMusicSizeAnswer": "The maximum size for an audio file is 10 Mb. Audio files with a bit rate higher than 128k and a duration longer than 30s will be automatically resized to these values for optimal weight.",
        "FAQStoreFrontQuestion": "What do you recommend for my store front?",
        "FAQStoreFrontAnswer": "We recommend using a short video with an element of life (a store that moves in the wind, a lively terrace, etc.). If your storefront is not suitable for a video, you can also use the inside of your restaurant.\nIf people are identifiable, be sure to ask them for permission.",        
        "FAQStoreFrontAnswer": "We recommend using a short video with an element of life (a store that moves in the wind, a lively terrace, etc.) but without big movement to not disturb the navigation. If your storefront is not suitable for a video, you can also use the inside of your restaurant.\nIf people are identifiable, be sure to ask them for permission.\nThink about using a table or a selfie tripod for better stability.",        
        "FAQCatUprealityOffer": "Understand the UpReality offer 🌊",
        "FAQCatUseMyStory": "Use my social website 🔗",
        "FAQCatMyStory": "Create my social website 🧑‍🍳",
        "FAQCatVideos": "Videos 📹",
        "FAQCatImages": "Images 📷",
        "FAQCatMusic": "Background music 🎶",
        "FAQCatMyStats": "My stats 📊",
        "FAQCatMyAccount": "My account ⚙️",        
        "Bio": "Mobile Product Manager, I was tired of navigating from one page to another in search of the right dining experience. I decided to use my knowledge in mobile technology to provide a discovery experience that makes the search as enjoyable as the moment at the table.",
        "american_restaurant": "American restaurant",
        "bakery": "Bakery",
        "bar": "Bar",
        "barbecue_restaurant": "Barbecue restaurant",
        "brazilian_restaurant": "Brazilian restaurant",
        "breakfast_restaurant" : "Breakfast restaurant",
        "brunch_restaurant": "Brunch restaurant",
        "cafe": "Cafe",
        "chinese_restaurant": "Chinese restaurant",  
        "coffee_shop": "Coffee shop",
        "fast_food_restaurant": "Fast food",
        "french_restaurant": "French restaurant",        
        "greek_restaurant": "Greek restaurant",
        "hamburger_restaurant" : "Hamburger restaurant",        
        "ice_cream_shop": "Ice cream shop",      
        "indian_restaurant": "Indian restaurant",
        "indonesian_restaurant": "Indonesian restaurant",
        "italian_restaurant": "Italian restaurant",
        "japanese_restaurant": "Japanese restaurant",
        "korean_restaurant"	: "Korean restaurant",
        "lebanese_restaurant": "Lebanese restaurant",        
        "meal_delivery": "Meal delivery",
        "meal_takeaway": "Meal takeaway",
        "mediterranean_restaurant": "Mediterranean restaurant",
        "mexican_restaurant": "Mexican restaurant",
        "middle_eastern_restaurant": "Middle eastern restaurant",
        "pizza_restaurant": "Pizza restaurant",
        "ramen_restaurant": "Ramen restaurant",
        "restaurant": "Restaurant",
        "sandwich_shop": "Sandwich shop",
        "seafood_restaurant": "Seafood restaurant",
        "spanish_restaurant": "Spanish restaurant",
        "steak_house": "Steak house",
        "sushi_restaurant": "Sushi restaurant",
        "thai_restaurant": "Thai restaurant",
        "turkish_restaurant": "Turkish restaurant",
        "vegan_restaurant": "Vegan restaurant",
        "vegetarian_restaurant": "Vegetarian restaurant",
        "vietnamese_restaurant": "Vietnamese restaurant",  
        "Allergens": "Allergens",
        "Soja": "Soja",
        "Pisces": "Pisces",
        "Egg": "Egg",
        "Mustard": "Mustard",
        "Molluscs": "Molluscs",
        "Lupine": "Lupine",
        "Milk": "Milk",
        "Sesame seeds": "Sesame seeds",
        "Gluten": "Gluten",
        "Nuts": "Nuts",
        "Crustaceans": "Crustaceans",
        "Cereals": "Cereals",
        "Celery": "Celery",
        "Peanuts": "Peanuts",
        "Sulphurous anhydrous": "Sulphurous anhydrous",      
    }
  },
  fr: {
    translation: {
        "ProductPageTitle": "Upreality - Le site web social de votre restaurant !",
        "ProductPageAdTitle": "Upreality - L'Instant Ad immersive de votre restaurant !",
        "Yes": "Oui",
        "No": "Non",
        "Infos": "Infos",
        "OpeningStatus":"Ouvert · Ferme à",
        "ClosedStatus": "Fermé · Ouvre à",
        "MoreInfos": "Plus d'infos",
        "CGoogle": "données ©Google",
        "GO": "Y ALLER",
        "BOOK": "RÉSERVER",
        "SAVE": "ENREGISTRER",
        "ShareTitle": "Envie de sortir au restaurant ?",
        "ShareText": "Le lien vers le site web social de {{name}} : ",
        "ShareTextDefaultName": "\"Restaurant non encore choisi\"",
        "BookTable": "Réserver une table",
        "Contact": "Contactez-nous",
        "BookTableInfo": "ou contactez-nous !",
        "BookTableInfoEmoji": "📅",
        "ContactInfoEmoji": "💬",
        "ShareEmoji": "👋",
        "Apply": "Rejoignez l'aventure",
        "ApplyEmoji": "🚀",
        "BookOnline": "en ligne",
        "BookPhone": "par téléphone",
        "BookEmail": "par email",
        "BookChat": "par chat",
        "BookEmailSubject": "Réserver",
        "ShareEmail": "par email",
        "ShareFacebook": "avec Facebook",
        "ShareCopy": "copier dans le presse-papier",
        "ShareOther": "autres moyens",
        "OrderOn": "Commander sur",
        "VirtualTourTitle": "intérieur",
        "MediasGalleryTitle": "menu",
        "DishesGalleryTitle": "plats",
        "TouchToPlay": "Touchez pour jouer !",
        "BookButton": "réserver",
        "ContactButton": "contacter",
        "ShareButton": "proposer",
        "ShareShareButton": "partager",
        "ApplyButton": "candidater",
        "ShareDialogTitle": "Proposer à un ami",
        "Hours": "Horaires",
        "HoursSubtitle": "7 prochains jours",
        "HoursInfoExtended": "horaires étendus sur certains restaurants",
        "Scroll": "Scroll",
        "HAPPY_HOUR": "Happy hours",
        "BREAKFAST": "Petit-déjeuner",
        "BRUNCH": "Brunch",
        "LUNCH": "Déjeuner",
        "DINNER": "Dîner",     
        "DRIVE_THROUGH": "Drive",
        "DELIVERY": "Livraison",
        "TAKEOUT": "À emporter",
        "KITCHEN": "Cuisine",
        "PICKUP": "Click & collect",
        "SENIOR_HOURS": "Heures senior",   
        "Reviews": "avis",
        "Location": "y aller",
        "Close": "Fermer",
        "StoryBgDesc": "Vitrine",
        "RestaurantContent": "Contenu media du restaurant",
        "TouchToPlay": "Toucher pour jouer !",
        "Star": "étoile",
        "Menu": "Carte",
        "Click": "cliquez",
        "Here": "ici",
        "ToRead": "pour lire",
        "Menus": "Formules",
        "Starter": "Entrées",
        "MainCourse": "Plats",
        "Dessert": "Desserts",
        "Wine": "Vins",
        "EndingPage": "Note",
        "Nutrients": "Valeurs nutritionnelles",
        "Saving": "Enregistrement...",
        "Saved": "Enregistré à ",
        "ChooseRestaurant": "Choisissez un restaurant",
        "ChooseRestaurantOr": "OU",
        "Login": "Me connecter",
        "CreateAccount": "Créer un compte",
        "AlreadyRegistered": "Déjà enregistré ?",
        "PlaceholderAutocompleaterHelp": "Saisissez le nom de votre restaurant pour que votre site web social soit configuré automatiquement",
        "PlaceholderAutocompleater": "le nom de mon restaurant",
        "PlaceholderRestaurantType": "mon type de restaurant (ex : bistro, etc.)",
        "PlaceholderRestaurantArea": "mon quartier ou mon adresse",
        "PlaceholderPhone": "mon no de téléphone",
        "PlaceholderChatMsg": "mon message d'accueil 😊",
        "ChooseRestaurantToStickers": "Stickers > ",
        "MyRestaurant": "Mon restaurant",
        "MyLogo": "Mon logo",
        "ChooseMyLogo": "Choisir mon logo",
        "MyMusic": "Ma musique d'ambiance",
        "ChooseMyMusic": "Ma Musique",
        "LogoInstagram": "celui d'Instagram",
        "FromInstagram": "depuis Instagram",
        "Or": "ou",
        "FromTheWeb": "depuis le web",
        "PasteWebLink": "collez ici votre lien web",
        "FromMyComputer": "depuis mon ordinateur",
        "Ok": "Ok",
        "YourColors": "Couleurs des boutons",
        "PrimaryColorBg": "fond",
        "PrimaryColorTxt": "texte",
        "PreviewButtonLabel": "aperçu",
        "ThemeToChooseRestaurant": " < 1. Infos",
        "ThemeToStoreFront": "3. Vitrine > ",
        "MyStoreFront": "Ma vitrine",
        "MyStickers": "Mes stickers",
        "StickersToRestaurant": " < 1. Restaurant",
        "StickersToActions": "3. Actions > ",
        "RestaurantStoreFront": "Vitrine du restaurant",
        "ChooseMyStoreFront": "Choisir ma vitrine",
        "ChooseMyStoreFrontHelp": "vidéo courte ou image",
        "ChooseMyInside": "Choisir mon intérieur",
        "ChooseMyInsideHelp": "vidéo courte ou image (2 max)",
        "ChooseMyPlates": "Choisir mes plats (+ équipe)",
        "ChooseMyPlatesHelp": "vidéo courte ou image (4 max)",
        "ChooseMyPlatesHelp2": "notre recommandation : 3 photos de plat + 1 vidéo de l'équipe",
        "RestaurantInside": "Intérieur du restaurant",
        "RestaurantInsideMax": "2 contenus maximum",
        "PlatesPreview": "Aperçu des plats (+ équipe)",
        "PlatesPreviewMax": "4 contenus maximum",
        "MyActions" : "Mes actions", 
        "BookButtonTitle": "Bouton Réserver",
        "SocialWebsiteConfig": "Configuration du site web social", 
        "AddMyMenu" : "Menu",
        "AddMyMenuDialog" : "Ajouter ma carte 📋",
        "AddMyMenuEN" : "Ajouter ma carte en anglais",
        "AddMyMenuENDialog" : "Ajouter ma carte en anglais 📋",
        "AddMyMenuHelp" : "page web, image ou PDF",  
        "AddSymbolFR": "FR",
        "AddSymbolEN": "EN",
        "AddMyDayMenu" : "Ajouter mon menu du jour",
        "AddMyDayMenuDialog" : "Ajouter mon menu du jour 📋",
        "ActionsToStickers": " < 2. Stickers",
        "MenuToUse": "4. Utiliser > ",
        "UseItToMenu": " < 3. Actions",
        "ConfigureMyPixels": "Configurer mes pixels",
        "ConfigureMyPixelsDialog": "Configurer mes pixels ⚙️",
        "Meta": "Meta",
        "TikTok": "TikTok",
        "aliasAlreadyExists": "alias déjà utilisé",
        "aliasNotValid": "lettres, chiffres et -",
        "Congratulation": "Félicitations 🍾",
        "StoryLink": "Lien de votre site web social",
        "CopyLink": "Copier le lien",
        "PossibleUsages": "Usages possibles",
        "SocialeProfile": "Profil social : en lien site web",        
        "SocialAds": "Publicité sociale : en lien action",
        "Website": "en QR Code ou Site Web à part entière",
        "SaveWork": "Sans compte UpReality, votre site web social sera supprimée au bout de 1 mois.",
        "Save": "Créer un compte",
        "MyStorys": "Mes sites web sociaux",
        "ChooseMyCover": "Choisir le style de ma couverture",        
        "MenuTitleColor": "Couleur du titre",
        "MenuTitleExample": "Entrée",
        "Cancel": "Annuler",
        "Confirm": "Valider",
        "Elegant": "Élégant",
        "Friendly": "Friendly",
        "Modern": "Moderne",
        "Simple": "Simple",
        "Custom": "Custom",
        "OptimMyMenu": "Format optimisé pour mobile",
        "OptimMyMenuHelp": "carte de moins de 50 éléments",
        "InfoOptimMyMenu": "Connectez-vous pour activer le format optimisé",
        "OptimStatus": "demandé le {{date}}",
        "MenuPreview": "aperçu de l'entête des pages",
        "DishImages": "Images des plats et correspondances",
        "Optional": "(optionnel)",
        "SendWithWeTransfer": "Envoyer avec WeTransfer",
        "LoginForOptimizedFormatp1": "Logguez-vous",
        "LoginForOptimizedFormatp2": " pour accéder au format optimisé",
        "ChooseYourMenuStyle": "Mettre en page ma carte au format mobile",
        "MenuStyle": "Style de la carte",
        "CoverStyle": "Style de la couverture",
        "ChooseYourMenuColor":  "Couleur d'entête des pages",
        "ChooseYourMenuColorHelp":  "La couverture utilise les couleurs définies sur l'onglet \"Thème\"",
        "ChooseYourMenuCustom" : "Je veux mon propre style sur le modèle de mon menu existant.",
        "ChooseYourMenuCustomAlreadyFormated" : "Mon menu custom déjà mis en page.",
        "ChooseStyleValidationHelp1": "Votre carte sera mise en page sous un délai de quelques jours. Essayez-la en cliquant sur le sticker \"menu\" de votre site web social.",
        "ChooseStyleValidationHelp2": "Vous pouvez utiliser votre site web social dès maintenant, la carte sera consultable sous la forme du fichier ou de la page d'origine jusque-là.",
        "MyStories": "Mes sites web sociaux",  
        "MenuEditor": "Editer un menu",      
        "XViews": " vues uniq.",
        "XUniqueViews": " vues uniques",
        "XAvgDurationTime": " s (moy.)",
        "Interest": "Intérêt : ",
        "XShares": " partages",
        "XBookingIntent": " intentions de réservation",
        "XContact": " contacts",
        "XItineraries": " itinéraires",
        "XReturns": " retours",
        "Launch": "Lancer",
        "Modify": "Modifier",
        "CopyWebLink": "Copier le lien web",
        "ChooseAFile": "Choisir un fichier",
        "FromMyCamera": "Depuis ma caméra",
        "FromMyFiles": "Depuis mes fichiers on mon appareil photo",
        "OpenMyCamera": "Ouvrir ma caméra",
        "ErrorImageFormat": "Merci de choisir un format d'image",
        "ErrorAudioFormat": "Merci de choisir un format audio",
        "LoginWithFacebookBusiness": "Me connecter avec Facebook",
        "LoginWithFacebookBusinessHelp": "Vous avez accès au compte Facebook Business du restaurant qui est connecté à son compte Instagram.",
        "LoginWithInstagram": "Me connecter avec Instagram",
        "LoginWithInstagramHelp": "Vous avez accès au compte Instagram Business du restaurant.",
        "LoginHelp": "Vous connecter avec Facebook ou par lien email vous crée automatiquement un compte UpReality si vous n'en avez pas déjà un.",
        "Login": "Me connecter",
        "BackToMyStories": "Mes sites web sociaux",
        "PublishMyChanges": "Publier",
        "PublishWarning": "Vos clients pourront voir les modifications apportées sur votre site web social, la mise à jour sera faite immédiatement.",
        "Publish": "Publier",
        "DeleteStory": "Supprimer mon site web social ?",
        "DeleteStoryWarning": "Ce site web social et ses statistiques seront supprimées définitivement. ",
        "Delete": "Supprimer",
        "ChangeRestaurantWarning": "Changer de restaurant réinitialisera toute votre saisie, les textes et images déjà enregistrés seront perdus.",
        "FileFormatError": "Merci de choisir un autre format de fichier",
        "FileSizeError": "Merci de choisir un fichier de moins de {{size}} Mo",
        "NoMediaFound": "Aucun contenu trouvé",
        "ModifyStoryWarning": "Les changements effectués sur ce site web social ne seront appliqués que lorsque vous les publierez ",
        "CreateNewStory": "Créer un nouveau site web social",
        "Preview": "Aperçu",
        "AbandonChanges": "Abandonner les changements",
        "Abandon": "Abandonner",
        "AbandonStoryWarning": "Les changements effectués sur ce site web social seront perdus.",
        "RestartStory": "Recommencer le site web social",
        "RestartStoryWarning": "Les changements effectués sur ce site web social seront perdus.",
        "Restart": "Recommencer",
        "AcknowledgeAbandonChanges": "Les changements ont bien été abandonnés.",
        "AckStorySavedPerm": "Vous êtes désormais enregistré sur l'application et votre site web social a été sauvegardée de manière permanente.",              
        "AckLinkCopied": "Lien copié !",
        "AckHoursThreshold": "Seuls les avis supérieurs à 4 sont affichés",
        "WarningDownsizeRunning": "Une optimisation de vos contenus multimédia est en cours, cela ne devrait prendre que quelques minutes.",
        "AckDisconnected": "Vous êtes maintenant déconnecté de votre compte UpReality.",
        "ErrorTitle": "Quelque chose a raté !",
        "ErrorMsg": "Si le problème persiste, merci de nous contacter.",
        "ErrorAction": "Réactualiser",
        "ChangeStyle": "Changer de style",
        "SelectFBPage": "Sélectionnez la page Facebook associée",	
        "ErrorNoFacebookPage": "Vous n'avez pas de page Facebook associée à votre compte. Veuillez-vous connecter directement depuis le compte instagram de votre restaurant.",
        "ErrorNoInstagramAccount": "Vous n'avez pas de compte Instagram associé à votre page Facebook. Veuillez-vous connecter directement depuis le compte instagram de votre restaurant.",
        "ErrorNoIGProfilePicture": "Vous n'avez pas de photo de profil Instagram. Veuillez sélectionner à la place un fichier ou un lien web.",        
        "ErrorDefault": "Erreur, merci de réessayer plus tard.",
        "PreviousPage": "Page précédente",
        "NextPage": "Page suivante",
        "YourWelcomeMsg": "Mon message d'accueil",
        "YourInfos": "Mes informations",
        "LinkBooking": "Lier mes plateformes de réservation",
        "ConfigureBooking": "Configurer ⚙️",
        "Booking": "Réservation",
        "Ordering": "Commande",
        "BookingOptionGoogleMaps": "Page Google Maps (défaut)",
        "BookingOptionLink": "Par lien",
        "BookingReplaceByLink": "remplacer par un lien web",
        "BookingLinkHint": "lien web de réservation",
        "BookingLinkHelp": "ne rien saisir si vous ne souhaitez pas de réservation en ligne",
        "BookingReplaceByMaps": "remplacer par la page Google Maps",
        "OrderingAddAnotherPlatform": "ajouter une plateforme",
        "OrderingLinkHint": "lien web de commande",
        "BookingOptionPhoneOn": "Par téléphone",
        "BookingOptionPhoneOff": "Par téléphone (désactivé)",        
        "BookingPhoneHint": "votre no de téléphone",
        "BookingOptionPhoneToOff": "ne pas autoriser la réservation par téléphone",
        "BookingOptionPhoneToOn": "autoriser la réservation par téléphone",
        "BookingOptionEmail": "Par email",
        "BookingEmailHint": "votre adresse email de réservation",     
        "BookingOptionChat": "Par chat", 
        "ConnectInstagram": "Connecter mon profil Instagram",
        "ConnectInstagramHelp": "Vous aurez accès à vos contenus Instagram pour les utiliser dans votre site web social (avatar, photo vitrine, photo plats, etc.).",        
        "IGProfileConnected": "Profil {{name}} connecté",
        "DisconnectInstagram": "déconnecter mon profil",
        "EnterEmail": "Entrez votre email pour vous connecter",
        "EnterEmailHelp": "Vous recevrez un email à l'adresse saisie avec un lien pour vous connecter sur l'app et revenir sur votre site web social.",
        "EnterEmailHelp2": "Vous pourrez ensuite vous connecter à Instagram pour importer vos contenus.", 
        "EnterEmailHint": "votre adresse email",
        "EmailSent": "Email envoyé, merci de vérifier votre boîte de réception pour revenir sur l'application avec votre compte activé. Il se peut que l'email soit dans vos spams.",
        "ConnectEmailLink": "Me connecter par lien email",
        "MyAccount": "Mon compte",
        "LogOut": "Me déconnecter",
        "LogOutWarning": "Vous serez déconnecté de votre compte UpReality.",
        "ManageMyAccount": "Gérer",
        "Hero1": "Le",
        "Hero2": " site web social",
        "Hero3": "de votre restaurant 🍹",
        "Hero41" : "La découverte se fait sur le fil social,",
        "Hero42" : "le choix sur votre site web UpReality.",
        "InstantAdHero1": "Une",
        "InstantAdHero2": " Instant Ad immersive",        
        "InstantAdHero3": "pour votre restaurant 🍹",
        "InstantAdHero41" : "Choisissez un format social plutôt qu’une page web",
        "InstantAdHero42" : " après votre pub sociale.",
        "CTA": "Créer en 1 clic",
        "InstantAdCTA": "Créer mon Instant Ad",
        "Create": "Créer moi-même",
        "ProfileInstagramHint": "profil Instagram du restaurant",        
        "Create1ClickHelp1": "Vous pouvez optionnellement nous envoyer photos, vidéos et menu par envoi WeTransfer.\nSans WeTransfer, nous créerons votre site web social à partir de votre contenu en ligne.",
        "Create1ClickHelp2": "Site web social modifiable par la suite sur demande ou manuellement via l'application",
        "MandatoryField": "Champ obligatoire",
        "AckCreate1Click": "Votre demande a bien été prise en compte, vous recevrez un message Instagram de confirmation sous une journée.",
        "SendMyContentWithWeTransfer": "Envoyer mon contenu avec WeTransfer",
        "CTA2": "Créer moi-même",
        "CTAbottom": "Créer en 1 clic",
        "Benefit1": "Comme si vous passiez devant",
        "SubBenefit11": "Le monde réel plutôt qu'un site web traditionnel",
        "SubBenefit11det": "Un format social plutôt qu'une page web textuelle et froide",
        "SubBenefit12": "Tout à portée pour se décider",
        "SubBenefit12det": "Information et action en 1 clic",
        "SubBenefit13": "Il n'y a plus qu'à entrer",
        "SubBenefit13det": "Des stats pour vérifier que votre site web social est plus engageant et converti mieux",
        "InstantAdBenefit1": "Comme si vous passiez devant",
        "InstantAdSubBenefit11": "Le monde réel plutôt que le site web",
        "InstantAdSubBenefit11det": "Un format social plutôt qu'une page web textuelle et froide",        
        "InstantAdSubBenefit12": "Tout à portée pour se décider",
        "InstantAdSubBenefit12det": "Information et action en 1 clic",
        "InstantAdSubBenefit13": "Il n'y a plus qu'à entrer",
        "InstantAdSubBenefit13det": "Des stats pour vérifier que votre Instant Ad est plus engageante et converti mieux",
        "Benefit2": "Encore plus facile à créer et mettre à jour",
        "SubBenefit21": "Intelligent, il réutilise vos données Google, Instagram et matériel multimédia existant",
        "SubBenefit22": "Léger, vos contenus sont optimisés pour un chargement plus rapide",
        "SubBenefit23": "Universel, vous pouvez l'utiliser sur tous vos supports digitaux et physiques",
        "InstantAdBenefit2": "Encore plus facile à créer et mettre à jour",
        "InstantAdSubBenefit21": "Simple, un outil dédié plutôt que l’éditeur intégré d’une plateforme publicitaire",
        "InstantAdSubBenefit22": "Léger, vos contenus sont optimisés pour un chargement plus rapide",
        "InstantAdSubBenefit23": "Universel, marche sur vos plateformes publicitaires TikTok et Meta",
        "CompareWebsite": "Comparer un site web adapté au mobile avec un site web social UpReality",
        "WebsiteQ1" : "Monde du texte et de l'ordinateur de bureau",
        "WebsiteQ2" : "Contenu texte long",
        "WebsiteQ3" : "La grande histoire",
        "StoryQ1" : "Monde du vivant et de la mobilité",
        "StoryQ2" : "Contenu vidéo interactif",
        "StoryQ3" : "Utile et facile",
        "FAQ": "FAQ",
        "FAQ1": "FAQ 1",
        "FAQanswer1": "Réponse 1",
        "FAQ2": "FAQ 2",
        "FAQanswer2": "Réponse 2",
        "FAQ3": "FAQ 3",
        "FAQanswer3": "Réponse 3",
        "FAQ4": "FAQ 4",
        "FAQanswer4": "Réponse 4",
        "AboutUs": "À propos",
        "AboutUsText": "Jacques ...",
        "Terms": "Conditions générales",
        "Privacy": "Confidentialité",
        "PublicationDate": "Date de publication : ",
        "NotPublished": "non publiée",
        "Loading": "Chargement...",
        "ErrorAccountExistsWithDifferentCredential": "Vous vous êtes connecté précédemment par lien email, pour pouvoir vous reconnecter avec Facebook, merci de vous connecter par lien email et de vous rendre dans vos préférences pour lier votre compte Facebook à votre email.",
        "ErrorFBNotInitialized": "Merci de désactiver votre bloqueur de publicité puis de rafraichir la page pour accéder à votre contenu Instagram.",
        "ErrorFBNotInitializedAfterLogin": "Merci de désactiver votre bloqueur de publicité pour accéder à votre contenu Instagram. Après rafraichissement, vous devrez resélectionner votre site web social depuis la liste, vos modifications seront bien sauvegardées",    
        "AckInstagramChat": "Vos clients peuvent désormais utiliser le chat Instagram pour vous contacter.",
        "TermsHelp": "Pour pouvoir utiliser l'application, vous devez accepter les conditions générales d'utilisation.",
        "TermsTitle": "Conditions d'utilisation",
        "TermsText1": "Je reconnais avoir lu et compris les ",
        "TermsText2": "conditions générales d'utilisation",
        "TermsText3": " et les accepte.",
        "ErrorEmailLinkExpired": "Le lien email a expiré, merci de vous reconnecter pour obtenir un nouveau lien email.",
        "TitleLogged": "Mes sites web sociaux",
        "TitleMaker": "Editeur de site web social",        
        "ContinueToInstagramTitle": "Continuer sur Instagram",
        "ContinueToInstagram": "Vous venez de vous connecter par email, nous allons maintenant vous connecter à Instagram pour vous permettre de sélectionner votre contenu.",
        "ContinueToInstagramProfile": "Vous venez de vous connecter par email, nous allons maintenant vous connecter à Instagram.",
        "HoursFromTo": "{{from}} à {{to}}",
        "InfoSwipe": "Faites glisser pour voir la suite",
        "InfoPreview": "Après chaque modification, cliquez sur le bouton 'Aperçu' pour voir le résultat.",
        "EmptyList": "Rien en cuisine !",
        "ClosePreview": "Fermer l'aperçu",                
        "TabMaker": "Editeur",
        "TabStories": "Mes sites web sociaux",
        "TabAccount": "Mon compte",
        "ManageMyAccountTitle": "Gérer mon compte",
        "AssociateAccounts": "Associer mes comptes",
        "AssociateFacebookToEmail": "Associer mon compte Facebook à mon email",
        "AssociateEmailToFacebook": "Associer mon compte email à mon compte Facebook",
        "EmailLinkedToFacebook": "Votre compte email est désormais associé à votre compte Facebook.",
        "EmailLinkedToFacebookStatus": "Compte email connecté au compte Facebook",
        "ErrorInstagramInAppBrowserTitle": "Cul de sac !",
        "ErrorInstagramInAppBrowserMsg": "Acceptez la popup pour revenir à l'application sur votre navigateur d'origine.",
        "ErrorInstagramInAppBrowserMsg1": "OU",
        "ErrorInstagramInAppBrowserMsg2": "Cliquez sur les paramètres en haut à droite puis \"Ouvrir dans Chrome\"",
        "MenuAllergens": "Liste des allergènes",
        "MenuDemoMenusTitle": "Formule {{menu}} (X €)",
        "MenuDemoMenusDesc1": "Plat + Dessert",
        "MenuDemoMenusDesc2": "Entrée + Plat + Dessert",
        "MenuDemoStarterTitle": "Entrée {{nb}}",
        "MenuDemoStarterDesc": "Composition de l'entrée {{nb}}",
        "MenuDemoStarterTitleDemo1": "Assiette de crudités",
        "MenuDemoStarterDescDemo1": "Salade, tomates, concombres, haricots verts",
        "MenuDemoStarterTitleDemo2": "Salade de chèvre chaud",
        "MenuDemoStarterDescDemo2": "Salade, chèvre chaud, tomates, noix",
        "MenuDemoStarterTitleDemo3": "Terrine de campagne",
        "MenuDemoStarterDescDemo3": "Fait maison, cornichons, salade",
        "MenuDemoStarterTitleDemo4": "Soupe de légumes",
        "MenuDemoStarterDescDemo4": "Soupe de légumes de saison",
        "MenuDemoStarterTitleDemo5": "Moules marinières",
        "MenuDemoStarterDescDemo5": "Cuites au vin blanc, frites", 
        "MenuDemoMainCourseTitle": "Plat {{nb}}",
        "MenuDemoMainCourseDesc": "Composition du plat {{nb}}",
        "MenuDemoMainCourseTitleDemo1": "Côte de porc grillée",
        "MenuDemoMainCourseDescDemo1": "Servie avec haricots verts",
        "MenuDemoMainCourseTitleDemo2": "Poulet rôti",
        "MenuDemoMainCourseDescDemo2": "Servi avec pommes de terre",
        "MenuDemoMainCourseTitleDemo3": "Confit de canard",
        "MenuDemoMainCourseDescDemo3": "Servi avec pommes de terre rissolées et salade verte",
        "MenuDemoMainCourseTitleDemo4": "Poisson du jour",
        "MenuDemoMainCourseDescDemo4": "Selon arrivage, servi avec riz",
        "MenuDemoMainCourseTitleDemo5": "Burger au fromage et bacon",
        "MenuDemoMainCourseDescDemo5": "Servi avec frites",
        "MenuDemoDessertTitle": "Dessert {{nb}}",
        "MenuDemoDessertDesc": "Composition du dessert {{nb}}",
        "MenuDemoDessertTitleDemo1": "Tarte aux framboises",
        "MenuDemoDessertDescDemo1": "Fait maison",
        "MenuDemoDessertTitleDemo2": "Crème brûlée",
        "MenuDemoDessertDescDemo2": "Fait maison",
        "MenuDemoDessertTitleDemo3": "Mousse au chocolat",
        "MenuDemoDessertDescDemo3": "Fait maison",
        "MenuDemoDessertTitleDemo4": "Salade de fruits",
        "MenuDemoDessertDescDemo4": "Fruits frais",
        "MenuDemoDessertTitleDemo5": "Glace",
        "MenuDemoDessertDescDemo5": "3 boules au choix",
        "WineDemoTitle": "Vin {{nb}}",
        "WineDemoPrices": "37.5 cl : X € - 75 cl : X €",
        "WineDemoPrices2": "12 cl : X €",
        "EndingPageDemoText": "Prix nets service compris\n\nNos plats sont préparés avec des produits frais et locaux, en collaboration avec les fermes environnantes.\n\nMoyens de paiements : titres restaurant, chèques vacances, carte bleu, etc.",
        "TransferToAnotherAccount": "Transférer vers un autre compte",
        "TransferRecipient": "Adresse email du nouveau compte",
        "TransferHint": "Le site web social à transférer",
        "TransferToAnotherAccountWarning": "Le site web social {{name}}, publiée le {{date}} sera transférée vers le compte UpReality associé à l'adresse email saisie. Si le compte n'existe pas encore, le site web social sera automatiquement affectée à sa création.",
        "TransferAction": "Transférer",
        "TransferInProgress": "{{name}} en cours de transfert vers {{email}}",    
        "SnackAcceptTransfer": "Accepter le transfert du site web social \"{{name}}\"",   
        "SnackAcceptTransferAction": "Accepter",
        "ErrorSubscriptionLimitReached": "Désolé, la limite d'inscription pour notre Beta est atteinte. Nous avons bien pris votre email et vous recontacterons dès qu'une place se libère.",        
        "DeleteMyAccount": "Supprimer mon compte",
        "DeleteMyAccountWarning": "Votre compte et vos sites web sociaux seront supprimés définitivement.",
        "DeleteAction": "Supprimer",
        "AckAccountDeleted": "Votre compte a bien été supprimé.",
        "ErrRecentLogin": "Votre dernier login est trop ancien, nous ne pouvons pas supprimer votre compte. Merci de vous relogguer et de recliquer sur ce bouton pour supprimer définitivement votre compte.",
        "Help": "Aide",
        "ReadFAQ": "Consulter les FAQ",
        "TranslateWithGoogle": "traduire avec Google",
        "DefaultTranslation": "en français",
        "CheckMyRights": "Vérifier mes droits",
        "ReadLegals": "Lire les mentions légales",
        "Legals": "Mentions légales",
        "ReadCgus": "Lire les conditions générales",
        "ReadPrivacy": "Lire la charte de confidentialité",
        "Credits": "Crédits",
        "Others": "Autres",
        "Images": "Images",
        "FAQPriceQuestion": "Le service est-il gratuit ?",
        "FAQPriceAnswer": "Le service tel que proposé actuellement est gratuit à vie pour nos premiers clients (dans la limite de 3 sites web sociaux par client), des options payantes seront proposées par la suite.",
        "FAQWebsiteSubstituteQuestion": "J'ai déjà un site web, pourquoi utiliser UpReality ?",
        "FAQWebsiteSubstituteAnswer": "UpReality est conçu pour être utilisé sur les réseaux sociaux, il est la version sociale de votre site web. Votre site web, plus adapté au monde du texte et de l'ordinateur de bureau, reste le plus pertinent pour des recherches sur le web.\n\nVous pouvez utiliser UpReality à la place ou en complément de votre site web sur votre profil social. \n\nSi vous ne possédez pas de site web, UpReality peut être une alternative plus économique, actuelle et facile à mettre à jour. En effet, UpReality peut être utiliser simplement et en total autonomie par un restaurateur.",
        "FAQInstantAdQuestion": "C'est quoi une Instant Ad ?",
        "FAQInstantAdAnswer": "Une Instant Ad est un format publicitaire social proposant une forme simplifiée de l’offre commerciale pour une expérience sans friction.\nElle prolonge la publicité in-feed par une expérience plein écran sous la forme d’une page web simplifiée (photos, vidéos, carrousels).",
        "FAQInstantAdDifferenceQuestion": "Quelle différence avec TikTok Instant Page et Meta Instant Experience ?",
        "FAQInstantAdDifferenceAnswer": "Là où Instant Page et Instant Experience proposent un format web simplifié, UpReality offre une vraie expérience immersive sous la forme du site web social.\nNotre Instant Ad propose un temps de chargement standard pour une expérience plus attractive et engageante, en cohérence avec le contenu social.",
        "FAQEasyToUseQuestion": "Est-ce facile à utiliser ?",
        "FAQEasyToUseAnswer": "UpReality est conçu pour être utilisé par des restaurateurs, il est simple et intuitif. Vous pouvez créer votre site web social en quelques clics et la mettre à jour en temps réel.",
        "FAQProContentsQuestion": "Puis-je utiliser des photos et vidéos que j'ai prises moi-même ou dois-je faire appel à un professionnel ?",
        "FAQProContentsAnswer": "Vous pouvez laisser libre court à votre créativité et utiliser vos propres photos et vidéos. Quelques conseils : \n",
        "FAQProContentsAnswerl0": "Prenez vos photos en portrait pour une meilleure utilisation de l'espace sur mobile.\nCentrez votre sujet, en effet les bords du haut et du bas peuvent être coupés sur les petits téléphones.",
        "FAQProContentsAnswerl1": "Priviliégiez la couleur et une lumière naturelle (ex à 11h)",
        "FAQProContentsAnswerl2": "Pour vos plats, mettez vous à la place du client à la bonne distance. La prise de vue de dessus est la plus répandue, mais vous pouvez aussi varier suivant les plats.",
        "FAQProContentsAnswerl3": "Misez sur le naturel, n'abusez pas des filtres, mais n'hésitez pas à jouer sur les contrastes et la luminosité pour faire ressortir vos photos.",
        "FAQProContentsAnswerl4": "Jouez des ustensiles qui font l'identité de votre restaurant (ex : planche en bois, verre de vin, etc.)",
        "FAQProContentsAnswerl5": "Vous pouvez également faire appel à un professionnel pour obtenir un résultat optimal.",
        "FAQGoogleDataQuestion": "D'où viennent les données de mon restaurant ?",
        "FAQGoogleDataAnswer": "Les données de votre restaurant : adresse, nom, no de téléphone, horaires, classe de prix proviennent de votre compte Google Business. Elles sont publiques et disponibles sur Google Maps et Google My Business. Si vous souhaitez les modifier, vous pouvez le faire sur Google My Business.",
        "FAQSocialNetworksQuestion": "Sur quel réseaux sociaux puis-je utiliser mon site web social ?",
        "FAQSocialNetworksAnswer": "Vous pouvez utiliser votre site web social sur tous les réseaux sociaux. UpReality est plus spécifiquement conçu pour être utilisé sur Instagram, TikTok, Snapchat et Facebook. \n\nAttention, UpReality ne se publie pas sur les réseaux sociaux en tant que contenu du réseau, c'est un lien web que l'on peut intégrer à différents endroits, comme le lien web de profil, la cible web d'un sticker ou d'une pub sociale, ou encore un simple lien dans un message.",
        "FAQSocialProfileQuestion": "Comment utiliser mon site web social sur mon profil social ?",
        "FAQSocialProfileAnswer": "Rendez-vous sur la rubrique \"site web\" de votre profil social et collez-y le lien de votre site web social. Il est possible que sur certains réseaux sociaux, vous deviez préalablement créer un compte professionnel.",
        "FAQSocialAdDestQuestion": "Comment utiliser mon site web social en tant que destination de publicité sociale ?",
        "FAQSocialAdDestAnswer": "Les sites web sociaux UpReality peuvent être utilisées pour des campagnes avec objectif de Trafic ou de Conversion. Elles s'utilisent en lien d'action de votre publicité sociale à la place du site web (destination > site web).\nDes statistiques d'interaction, notamment sur le bouton réserver, sont disponibles dans l'application sur la page de statistiques. Sur demande, nous pouvons également intégrer vos pixels TikTok ou Meta (contact@upreality.store).",
        "FAQWebsiteQuestion": "Comment utiliser mon site web social en tant que site web ?",
        "FAQWebsiteAnswer": "Votre site web social est déjà publiée en tant que site web, vous pouvez directement utiliser le lien. Nous mettrons prochainement en place un service vous permettant de brancher un nom de domaine existant ou de commander votre propre nom de domaine.\nEn attendant, vous pouvez brancher un sous-domaine que vous possédez (exemple : story.votrerestaurant.com) à l'url de votre site web social à l'aide d'une redirection 301.",
        "FAQSocialAdQuestion": "Comment utiliser mon site web social depuis une action sur une publicité sociale ?",
        "FAQSocialAdAnswer": "Vous pouvez utiliser votre site web social en lien d'action depuis une publicité sociale sur toutes les plateformes sociales, copiez le lien du site web social à la place du site web.", 
        "FAQOriginalWaysQuestion": "Quels autres moyens originaux me proposez-vous ?",
        "FAQOriginalWaysAnswer": "Vous pouvez utiliser votre site web social depuis : \n",
        "FAQOriginalWaysAnswerl0": "un QR Code (exemples d'utilisation : flyer, carte de visite, etc.).",
        "FAQOriginalWaysAnswerl1": "un sticker sur les réseaux sociaux qui le permettent comme Instagram.", 
        "FAQOriginalWaysAnswerl2": "un lien additionnel dans votre linktr.ee",
        "FAQOriginalWaysAnswerl3": "un post épinglé sur votre profil facebook pour que votre site web social soit toujours visible en haut du fil",
        "FAQOriginalWaysAnswerl4": "un post Google Business",
        "FAQOriginalWaysAnswerl5": "une campagne SMS",      
        "FAQStatsNoteQuestion": "Comment est calculée la note d'intérêt ?",        
        "FAQStatsNoteAnswer": "La note d'intérêt est la moyenne des notes d'intérêt de tous vos visiteurs. Elle est calculée en fonction de l'activité du visiteur sur votre site web social.",
        "FAQStatsNoteAnswerl1": "10s passée sur votre site web social donne 1 point.",
        "FAQStatsNoteAnswerl2": "Un clic sur \"Menu\", \"Intérieur\", \"Horaires\" ou \"Revues\" donne 2 points.\nUn clic sur \"Menu\" ou \"Intérieur\" et un sur \"Horaires\" ou \"Revues\" donnent 2.5 points.\nUn clic sur \"Menu\" et un sur \"Intérieur\" + un sur \"Horaires\" ou \"Revues\" donnent 3 points.",
        "FAQStatsNoteAnswerl3": "Un clic sur \"Itinéraire\" ou \"Partage\" donne 4 points.",
        "FAQStatsNoteAnswerl4": "Un clic sur \"Réserver\" donne 4.5 points.",
        "FAQStatsNoteAnswerl5": "Un retour (fermeture de la page web puis retour) sur le site web social donne 4.5 points.",
        "FAQStatsQuestion": "À quoi correspondent les statistiques proposées ?",
        "FAQStatsAnswerUniqueView": "1 vue unique correspond à la visite d'un utilisateur. Si celui-ci ferme son navigateur web et décide de revenir sur votre site web social, cette nouvelle visite ne sera pas comptée.",
        "FAQStatsAnswerMoy": "La durée moyenne correspond à la moyenne de durée de visite sur votre site web social.",
        "FAQStatsAnswerShares": "Le nombre de partages correspond au nombre de fois où un utilisateur a cliqué sur le bouton \"Partager\".",
        "FAQStatsAnswerBooks": "Le nombre d'intentions de réservation correspond au nombre de fois où un utilisateur a cliqué sur le bouton \"Réserver\".",
        "FAQStatsAnswerItinerary": "Le nombre d'itinéraires correspond au nombre de fois où un utilisateur a cliqué sur le bouton \"Itinéraire\".",
        "FAQStatsAnswerReturn": "Le nombre de retours correspond au nombre de fois où un utilisateur a fermé la page web puis est revenu sur votre site web social.",
        "FAQMenuTranslatedQuestion": "Ma carte au format optimisée mobile sera-t-elle traduite pour les touristes étrangers ?",
        "FAQMenuTranslatedAnswer": "Si la carte que nous faite parvenir contient la traduction anglaise, celle-ci sera bien intégrée à votre site web social. Pour les cartes sans traduction anglaise, les utilisateurs pourront obtenir une traduction automatisée directement depuis le site web social.",
        "FAQNoFacebookPageQuestion": "Lorsque je cherche à ajouter un contenu depuis Facebook, j'ai l'erreur \"Vous n'avez pas de page Facebook associée à votre compte\".",
        "FAQNoFacebookPageAnswer": "Pour utiliser cette fonctionnalité, vous devez avoir une page Facebook pour votre restaurant ainsi qu'un compte Instagram associé à cette page.",
        "FAQNoFacebookPageAnswer2": "Cliquez ici pour voir comment faire",
        "FAQVideoFormatQuestion": "Quels formats de vidéo puis-je utiliser ?",
        "FAQVideoFormatAnswer": "Les vidéos au format .mp4 et .mov (codec h.264 ou supérieur) sont compatibles.",
        "FAQVideoSizeQuestion": "Quelle est le poids maximal pour une vidéo ?",
        "FAQVideoSizeAnswer": "Le poids maximal pour une vidéo est de 50 Mo.",
        "FAQVideoRecommendedResolutionQuestion": "Quelle résolution de vidéo recommandez-vous ?",
        "FAQVideoRecommendedResolutionAnswer": "Nous vous recommandons 720 en largeur x 1280 en hauteur, le meilleur compromis entre taille et qualité. Les vidéos de résolution supérieure sont redimensionnées automatiquement vers cette résolution.",
        "FAQImageRecommendedResolutionQuestion": "Quelle résolution d'image recommandez-vous ?",
        "FAQImageRecommendedResolutionAnswer": "Nous vous recommandons d'utiliser des images au format portrait, 9/16 pour une meilleure utilisation de l'espace, au moins 720 en largeur x 1280 en hauteur pour une meilleure qualité d'image. Les images de résolution supérieure sont redimensionnées automatiquement vers cette résolution et compressées pour un poids optimal.",
        "FAQImageFormatQuestion": "Quels formats d'image puis-je utiliser ?",
        "FAQImageFormatAnswer": "Les images au format .jpg, .png, .gif, .webp, .svg et .bmp sont compatibles.", 
        "FAQImageSizeQuestion": "Quelle est le poids maximal pour une image ?",
        "FAQImageSizeAnswer": "Le poids maximal pour une image est de 10 Mo, seul l'avatar est limité à 512 ko.",
        "FAQMusicFormatQuestion": "Quels formats de fichier audio puis-je utiliser ?",
        "FAQMusicFormatAnswer": "Les fichiers audio au format .wav, .mp3 et .m4a sont compatibles.",
        "FAQMusicSizeQuestion": "Quel est le poids maximal pour un fichier audio ?",
        "FAQMusicSizeAnswer": "Le poids maximal pour un fichier audio est de 10 Mo. Les fichiers audio de bit rate supérieur à 128k et de durée supérieure à 30s seront redimensionnés automatiquement vers ces valeurs pour un poids optimal.",
        "FAQStoreFrontQuestion": "Que me recommandez-vous pour ma vitrine ?",
        "FAQStoreFrontAnswer": "Nous vous recommandons d'utiliser une vidéo courte avec un élément de vie (un store qui bouge au vent, une terrase animée, etc.) mais sans grand mouvement pour ne pas perturber la navigation. Si votre vitrine ne se prête pas à une vidéo, vous pouvez également utiliser l'intérieur de votre restaurant.\nSi des personnes sont identifiables, prenez soin de leur demander l'autorisation.\nPensez à l'utilisation d'une table ou d'un trépied à selfie pour une meilleure stabilité.",           
        "FAQCatUprealityOffer": "Comprendre l'offre UpReality 🌊", 
        "FAQCatUseMyStory": "Utiliser mon site web social 🔗",
        "FAQCatMyStory": "Créer mon site web social 🧑‍🍳",
        "FAQCatVideos": "Vidéos 📹",
        "FAQCatImages": "Images 📷",
        "FAQCatMusic": "Musique d'ambiance 🎶",
        "FAQCatMyStats": "Mes statistiques 📊",
        "FAQCatMyAccount": "Mon compte ⚙️",
        "Bio": "Product Manager mobile, j'étais fatigué de naviguer d'une page à l'autre à la recherche de la bonne expérience de restauration. J'ai décidé d'utiliser mes connaissances en technologie mobile pour fournir une expérience de découverte qui rende la recherche aussi plaisante que le moment à table.",        
        "LaunchDemoInstantAd": "Lancer la démo",
        "american_restaurant": "Restaurant américain",
        "bakery": "Boulangerie",
        "bar": "Bar",
        "barbecue_restaurant": "Restaurant barbecue",
        "brazilian_restaurant": "Restaurant brésilien",
        "breakfast_restaurant" : "Restaurant breakfast",
        "brunch_restaurant": "Restaurant brunch",
        "cafe": "Café",
        "chinese_restaurant": "Restaurant chinois",
        "coffee_shop": "Coffee shop",
        "fast_food_restaurant": "Fast food",
        "french_restaurant": "Restaurant français",
        "greek_restaurant": "Restaurant grec",
        "hamburger_restaurant" : "Restaurant hamburger",
        "ice_cream_shop": "Marchand de glace",
        "indian_restaurant": "Restaurant indien",
        "indonesian_restaurant": "Restaurant indonésien",
        "italian_restaurant": "Restaurant italien",
        "japanese_restaurant": "Restaurant japonais",
        "korean_restaurant"	: "Restaurant koréen",
        "lebanese_restaurant": "Restaurant libanais",
        "meal_delivery": "Livraison de repas",
        "meal_takeaway": "Repas à emporter",
        "mediterranean_restaurant": "Restaurant mediterranéen",
        "mexican_restaurant": "Restaurant mexicain",
        "middle_eastern_restaurant": "Restaurant oriental",
        "pizza_restaurant": "Pizzeria",
        "ramen_restaurant": "Restaurant ramen",
        "restaurant": "Restaurant",
        "sandwich_shop": "Sandwicherie",
        "seafood_restaurant": "Restaurant de poissons",
        "spanish_restaurant": "Restaurant espagnol",
        "steak_house": "Steakhouse",
        "sushi_restaurant": "Restaurant sushi",
        "thai_restaurant": "Restaurant thai",
        "turkish_restaurant": "Restaurant turc",
        "vegan_restaurant": "Restaurant vegan",
        "vegetarian_restaurant": "Restaurant vegetarien",
        "vietnamese_restaurant": "Restaurant vietnamien",
        "Allergens": "Allergènes",
        "Soja": "Soja",
        "Pisces":"Poissons",
        "Egg":"Oeuf",
        "Mustard":"Moutarde",
        "Molluscs":"Mollusques",
        "Lupine":"Lupin",
        "Milk":"Lait",
        "Sesame seeds":"Graines de sésame",
        "Gluten":"Gluten",
        "Nuts":"Fruits à coque",
        "Crustaceans":"Crustacés",
        "Cereals":"Céréales",
        "Celery":"Céleri",
        "Peanuts":"Arachides",
        "Sulphurous anhydrous":"Anhydre sulfureux",
    }
  }
};

i18n
  .use(LanguageDetector)  
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    //supportedLngs: ["en", "fr"],
    nonExplicitSupportedLngs: true,
    fallbackLng: "en",
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['navigator']
    }
  });

  export default i18n;