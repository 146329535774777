import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import {LoggedContext} from "./LoggedContext";
import { useTranslation } from 'react-i18next';
import { getFirestore, collection, query, getDocs, where , orderBy} from "firebase/firestore";
import { browserLocales } from '../player/Utils';
import { useMediaQuery } from 'react-responsive';
import { AppContext } from '../AppContext';
import MaterialButton from '../maker/component/MaterialButton';
import './Logged.css';
import { Snackbar } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { analyticsManager } from '../index.js';
import { acceptTransfer, getStoryTransfers } from './PreferencesController.js';
import { SnackbarContent } from '@mui/material';

function ListStories(props) {
    const fbResponse = useContext(AppContext).get("fbResponse");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");    
    const uid = useContext(LoggedContext).get("uid");
    const [storiesToTransfer, setStoriesToTransfer] = useState(null);
    const { t } = useTranslation();
    const [stories, setStories] = useState(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const windowDimensions = useContext(LoggedContext).get("windowDimensions");
    const navigate = useNavigate();

    useEffect(() => {        
        if (uid != null){
            getStories();
            if (storiesToTransfer == null){
            getStoryTransfers(fbResponse)
                .then((storiesToTransfer)=>{setStoriesToTransfer(storiesToTransfer)});          
            }
        }
      }, [uid]);

    useEffect(() => {
        analyticsManager.logListStoriesScreenViewTag();
        if (window.history.pushState) {   
            var newurl = window.location.protocol + "//" + window.location.host + "/logged";
            window.history.pushState({path:newurl},'',newurl);
        } 
    }, []);

    async function getStories(){              
        const db = getFirestore();        
        const q = query(collection(db, "stories"), where("uid", "==", uid), where("baseStory", "==", true), orderBy("savedTime", "desc"));        
        const querySnapshot = await getDocs(q);
        const storiesFromDB = [];
        querySnapshot.forEach((doc) => {     
            const story = doc.data();
            story.id = doc.id; 
            storiesFromDB.push(story);
        });        
        setStories(storiesFromDB);
    }

    async function getRating(story, stories){
        if (story.rating != null) return;

        const db = getFirestore();             
        const q = query(collection(db, "story_ratings"), where("storyId", "==", story.id));
        const querySnapshot = await getDocs(q);
        let ratings = 0;
        let ratingsNb = 0;
        await querySnapshot.forEach((doc) => {            
            ratings += doc.data().rating;
            ratingsNb ++;
        });        
        const newStories = [...stories];
        newStories.find((currentStory) => currentStory.id === story.id).rating = ratingsNb != 0 ? calculateRating(story, ratings, ratingsNb) : 0;
        setStories(newStories);    
           
        if (!isTabletOrMobile && story.id == stories[0].id) {                               
            props.setSelectedStory(newStories[0]);
        }   
        
        function calculateRating(currentStory, ratings, ratingsNb){
            const conversionRate = currentStory.bookingIntentNb / ratingsNb;
            let globalRating = 0;
            if (conversionRate > 0.06){
                globalRating += 3;
            }
            else if (conversionRate > 0.04){
                globalRating += 2.5;
            }
            else if (conversionRate > 0.03){
                globalRating += 2;
            }
            else if (conversionRate > 0.02){
                globalRating += 1.5;
            }
            else if (conversionRate > 0.01){
                globalRating += 1;
            }
            else if (conversionRate > 0.005){
                globalRating += 0.5;
            }
            else {
                globalRating += 0.0;
            }

            const engagementRating = ratings / ratingsNb;
            globalRating += engagementRating * 2 / 5;

            return globalRating;
        }
    }

    function showThumbnail(story){
        if (story.imageRef != null){
            return (
                <img alt="thumbnail of the story" src={story.imageRef} style={{width: 56, height: 100, objectFit: 'cover'}}/> 
            );
        }
        else if (story.videoRef != null){

            if (story.videoFSTnRef != null)
                return (
                    <img alt="thumbnail of the story" src={story.videoFSTnRef} style={{width: 56, height: 100, objectFit: 'cover'}}/> 
                );
            else 
                return (
                    <video muted alt="thumbnail of the story" style={{width: 56, height: 100, objectFit: 'cover', userSelect: 'none', pointerEvents: 'none'}}>
                        <source src={story.videoRef} />
                    </video>
                );
        }
        else {
            return (
                <img alt="thumbnail of the story" src="/images/placeholder_storefront.jpg" style={{width: 56, height: 100, objectFit: 'cover'}}/> 
            );
        }
    }

    function showAcceptTransferSnack(){
        const storyToTransfer = storiesToTransfer != null && storiesToTransfer.length > 0 ? storiesToTransfer[0] : null;        
        if (storyToTransfer){
            return (
                <Snackbar open={storyToTransfer != null} onClose={()=>{}} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
                    <SnackbarContent message={t('SnackAcceptTransfer', {name: storyToTransfer.posName})} action={
                        <MaterialButton style="text" onClick={()=>{
                            analyticsManager.logAcceptTransferTag(storyToTransfer.id);
                            acceptTransfer(storyToTransfer, uid)
                                .then(()=>{
                                    const acceptedStory = storiesToTransfer.shift();
                                    const newStoriesToTransfer = [...storiesToTransfer];                                    
                                    setStoriesToTransfer(newStoriesToTransfer);
                                    const newStories = [...stories];
                                    newStories.push(acceptedStory);
                                    setStories(newStories);
                                });
                        }} value={t('SnackAcceptTransferAction')}/>
                    } />
                </Snackbar>
            );
        }
        else return null;        
    }

    let storiesToDisplay = [];
    let divWidth = 360;
    if (isTabletOrMobile && windowDimensions != null) {
        divWidth = windowDimensions.width;
    }
    if (stories != null){ 
        storiesToDisplay = stories.map((story) => {            
            getRating(story, stories);
            
            const selectedBgColor = props.selectedStory != null && props.selectedStory.id === story.id ? "#b2ede7" : "white";
            const demoStory = (story.id === "cafeupreality");
            if (demoStory){
                story.posName = "Café UpReality";
                story.viewNb = 1245;
                story.duration = 1245 * 12.6;
                story.shares = 110;
                story.bookingIntentNb = 60;
                story.itineraryNb = 232;
                story.returnNb = 128;
            }

            // TODO avatarUrl <-> TN
            return (
                <div id={story.id} key={story.id} style={{marginBottom: 16, backgroundColor: selectedBgColor, borderRadius: 4, padding: 8}} onClick={()=>{analyticsManager.logClickStatDetailTag(story.id);props.setSelectedStory(story);}}>
                    <div style={{display: 'flex', flexDirection: 'row',  }}>
                        {showThumbnail(story)}
                        <div style={{paddingLeft: 8, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <div className='stat-restaurant-names' style={{fontSize: 18, width: divWidth - 56 - 16 * 2 - 8, }}>{story.posName}</div>
                            <div style={{fontSize: 16, display: 'flex', flexDirection: 'row', alignItems: 'flex-end', }}><div>{story.rating != null ? story.rating.toFixed(1) : 0}</div><div style={{marginBottom: 2}}> ⭐</div></div>
                            <div style={{display: 'flex', flexDirection: 'row', fontStyle: 'italic'}}>
                                <div style={{fontSize: 14, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center'}}><VisibilityIcon style={{ width: 14, height: 14, marginRight: 8}}/>{(story.viewNb != null ? story.viewNb : 0) + t('XViews')}</div>
                                <div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>
                                <div style={{fontSize: 14, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center'}}><AccessTimeIcon style={{ width: 14, height: 14, marginRight: 8}}/>{(story.duration != null ? story.duration / ((story.viewNb != null ? story.viewNb : 0) + (story.returnNb != null ? story.returnNb : 0)) : 0).toFixed(2) + t('XAvgDurationTime')}</div>
                            </div>
                            <div style={{width: divWidth - 16 * 2 - 56 - 8 - 2 * 8, fontSize: 12, fontStyle: 'italic', textAlign: 'end'}}>{story.publicationDate != null ? new Date(story.publicationDate).toLocaleString(browserLocales(), new Date().getTimezoneOffset()) : t('NotPublished')}</div>                            
                        </div>
                    </div>
                </div>
            );
        });
    }

    let pageHeight = makerDesktopHeight;
    let storyContainerHeight = pageHeight - 32 - (28 * 1.2 + 16 * 1.2) - (16 + 8 * 2 + 14 * 1.2);
    if (isTabletOrMobile && windowDimensions != null) {
        pageHeight = windowDimensions.height;
        storyContainerHeight = pageHeight - 32 - (28 * 1.2 + 16 * 1.2) - 56;        
    }        

    return (
        <div style={{height: pageHeight, backgroundColor: 'white', borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>
            <div style={{padding: 16,}}>
                <div style={{fontSize: 28}}>{t('MyStories')}</div>
                <br/>
                <div className='list-stories' style={{width: '100%', height: storyContainerHeight, backgroundColor: 'white',}}>
                    {stories != null && stories.length === 0 && <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginBottom: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <img alt="nothing in the kitchen" src="/images/empty_list.png"/>
                        {t('EmptyList')}
                    </div>
                    </div>}
                    {storiesToDisplay}
                </div>       
                {!isTabletOrMobile &&         
                    <div style={{display: 'flex', flexDirection: "column", justifyContent: 'center', marginTop: 16}}>
                        <MaterialButton style="filled-tonal" value={t('CreateNewStory')} onClick={()=>{analyticsManager.logCreateStoryTag();navigate("/maker");}}/>               
                    </div>
                }
                {showAcceptTransferSnack()}
            </div>      
        </div>
    );    
}

export default ListStories;
