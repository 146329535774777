import React, {useContext} from 'react';
import {MakerContext} from "./MakerContext";
import ChooseRestaurantForm from "./ChooseRestaurantForm";
import ChooseStickersForm from './ChooseStickersForm';
import ChooseActionsForm from './ChooseActionsForm';
import UseIt from './UseIt';

function MakerForm(props) {
    
    const activeForm = useContext(MakerContext).get("activeForm"); 
    
    const showChooseRestaurant = activeForm === "choose-restaurant";
    const showChooseStickers = activeForm === "choose-stickers";
    const showChooseActions = activeForm === "choose-actions";
    const showUseIt = activeForm === "use-it";

    return(
        <div style={{visibility: props.previewDisplayed != null && props.previewDisplayed ? 'hidden' : 'visible'}}>
            <ChooseRestaurantForm show={showChooseRestaurant} firstDisplayWarning={props.firstDisplayWarning} setFirstDisplayWarning={props.setFirstDisplayWarning} />
            <ChooseStickersForm show={showChooseStickers} />
            <ChooseActionsForm show={showChooseActions} />
            <UseIt show={showUseIt} />
        </div>
    );
}

export default MakerForm;