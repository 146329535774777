import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import {MakerContext} from "./MakerContext";
import { getFirestore, doc, deleteField, updateDoc, getDoc, deleteDoc, arrayUnion, arrayRemove, runTransaction } from "firebase/firestore";
import {getTextWidth, log, validURL} from '../player/Utils';
import MaterialButton from './component/MaterialButton';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './component/MobileMenu';
import {AppContext} from '../AppContext';
import './Maker.css';
import '../player/Player.css';
import { MEDIA_TYPE_PROFILE, cleanMakerQueryString, deleteFromStorage, updateAlias, updateMediasForTransformation } from './MakerController';
import ConfirmDialog from './component/ConfirmDialog';
import { functions, analyticsManager } from '..';
import MobileBottomBar from './component/MobileBottomBar';
import { Alert, Snackbar } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import ColorPicker from './component/ColorPicker';
import ShareIcon from '@mui/icons-material/Share';
import LineBreak from './component/LineBreak';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PixelDialog from './component/PixelDialog';
import LoginDialog from './LoginDialog';

function ChooseActionsForm(props) {
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const baseStory = useContext(MakerContext).get("baseStory");
    const reservable = useContext(MakerContext).get("reservable");
    const restaurantPhone = useContext(MakerContext).get("restaurantPhone");
    const setHighlight = useContext(MakerContext).get("setHighlight");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [showPublishConfirm, setShowPublishConfirm] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [showLinkBooking, setShowLinkBooking] = useState(false);
    const [dialogRestaurantPhone, setDialogRestaurantPhone] = useState("");
    const windowDimensions = useContext(MakerContext).get("windowDimensions");
    const [showPixelDialog, setShowPixelDialog] = useState(false);
    const [linkEditable, setLinkEditable] = useState(false);
    const [invalidAlias, setInvalidAlias] = useState(false);
    const [aliasInputWidth, setAliasInputWidth] = useState(0);
    const setInstagramAccountId = useContext(MakerContext).get("setInstagramAccountId");
    const instagramLoginType = useContext(MakerContext).get("instagramLoginType");
    const setInstagramLoginType = useContext(MakerContext).get("setInstagramLoginType");
    const [showIGConnectedSuccess, setShowIGConnectedSuccess] = useState(false);
    const [bypassConnectToIGForProfile, setBypassConnectToIGForProfile] = useState(false);
    const activePopup = useContext(MakerContext).get("activePopup");
    const setActivePopup = useContext(MakerContext).get("setActivePopup");   
    const setFbAccessToken = useContext(MakerContext).get("setFbAccessToken");  
    const { t } = useTranslation();
    
    const setActiveForm = useContext(MakerContext).get("setActiveForm");

    useEffect(() => {
        if (props.show) {
            setAliasInputWidth(getTextWidth(story.alias, "16px Roboto") + 2 * 4);// 20 input padding + buffer
        }
    }, [props.show]);

    useEffect(() => {
        if ((activePopup === MEDIA_TYPE_PROFILE) && props.show){                
            if (activePopup === MEDIA_TYPE_PROFILE){
                setBypassConnectToIGForProfile(true);
                setShowLinkBooking(true);
                log("active popup " + MEDIA_TYPE_PROFILE);
            }          
            setActivePopup(null);
            if (instagramLoginType === "ig")
                cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
        }
      }, [activePopup && props.show]);

      function onInstagramConnected(_igAccountId, _instagramLoginTypeFromWeb = null){ 
        log("ChooseActionsForm onInstagramConnected");
        analyticsManager.logConnectInstagramTag(storyId, true); 
        setShowIGConnectedSuccess(_igAccountId != null);        
    }

    function showIGConnectedSuccessSnack(){
        if (showIGConnectedSuccess){
            return (
                <Snackbar open={showIGConnectedSuccess} autoHideDuration={4000} onClose={()=>setShowIGConnectedSuccess(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}  sx={{ width: "90%" }}>
                    <Alert onClose={()=>setShowIGConnectedSuccess(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckInstagramChat')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }   

    async function publishStory(){
        setShowProgress(true);
        const db = getFirestore();    
        const editionVersionDocRef = doc(db, "stories", storyId);        
        const editedVersionDoc = await getDoc(editionVersionDocRef);
        // standard case
        if (editedVersionDoc.id !== editedVersionDoc.data().baseStoryId){            
            const baseDocRef = doc(db, "stories", editedVersionDoc.data().baseStoryId);        
            const update = editedVersionDoc.data();            
            update.publicationDate = new Date().getTime();
            update.savedTime = new Date().getTime();
            // stats            
            if (baseStory.duration != null) update.duration = baseStory.duration;
            if (baseStory.viewNb != null) update.viewNb = baseStory.viewNb;
            if (baseStory.shares != null) update.shares = baseStory.shares;
            if (baseStory.bookingIntentNb != null) update.bookingIntentNb = baseStory.bookingIntentNb;
            if (baseStory.itineraryNb != null) update.itineraryNb = baseStory.itineraryNb;
            if (baseStory.returnNb != null) update.returnNb = baseStory.returnNb;
            if (baseStory.rating != null) update.rating = baseStory.rating;            
            // WARNING FOR EVERY FIELD THAT CAN BE DELETED
            // already deleted in update, we need to update the base story with the deletion 
            if (baseStory.avatarUrl != null && update.avatarUrl == null){
                update.avatarUrl = deleteField();
            } 
            if (baseStory.avatarUrl != null && update.avatarUrl != baseStory.avatarUrl && baseStory.avatarUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){                           
                deleteFromStorage(baseStory.avatarUrl);
            }
            if (baseStory.musicUrl != null && update.musicUrl == null){
                update.musicUrl = deleteField();
            } 
            if (baseStory.musicUrl != null && update.musicUrl != baseStory.musicUrl && baseStory.musicUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){                           
                deleteFromStorage(baseStory.musicUrl);
            }
            if (baseStory.imageRef != null && update.imageRef == null){
                update.imageRef = deleteField();
            }         
            if (baseStory.imageRef != null && update.imageRef != baseStory.imageRef){                
                deleteFromStorage(baseStory.imageRef);
            }
            if (baseStory.videoRef != null && update.videoRef == null){
                update.videoRef = deleteField();
            }  
            if (baseStory.videoRef != null && update.videoRef != baseStory.videoRef){               
                deleteFromStorage(baseStory.videoRef);
            }   
            const videoChanged = update.videoRef != null && update.videoRef != baseStory.videoRef;
            const imageChanged = update.imageRef != null && update.imageRef != baseStory.imageRef
            const videoToImage = baseStory.videoRef != null && update.imageRef != null;
            const imageToVideo = baseStory.imageRef != null && update.videoRef != null;
            const noStoreFront = update.videoRef == null && update.imageRef == null;
            if (baseStory.shareTnRef != null && (videoChanged || imageChanged || videoToImage || imageToVideo || noStoreFront)){               
                update.shareTnRef = deleteField();
            }  
            if (baseStory.shareTnRef != null && (videoChanged || imageChanged || videoToImage || imageToVideo || noStoreFront)){               
                deleteFromStorage(baseStory.shareTnRef);
            } 
            if (baseStory.videoFSTnRef != null && (videoChanged || videoToImage || noStoreFront)){ 
                update.videoFSTnRef = deleteField();
            }  
            if (baseStory.videoFSTnRef != null && (videoChanged || videoToImage || noStoreFront)){
                deleteFromStorage(baseStory.videoFSTnRef);
            } 
            if (baseStory.tourMediasRef != null){
                for (let i = 0; i < baseStory.tourMediasRef.length; i++){
                    if (update.tourMediasRef == null || !Array.from(update.tourMediasRef).includes(baseStory.tourMediasRef[i])){                        
                        deleteFromStorage(baseStory.tourMediasRef[i]);
                    }
                }
            }                
            if (baseStory.tourMediasRef != null){                
                if (update.tourMediasRef == null){
                    update.tourMediasRef = deleteField();
                }
            }
                                   
            if (baseStory.mediasRef != null){
                for (let i = 0; i < baseStory.mediasRef.length; i++){
                    if (update.mediasRef == null || !Array.from(update.mediasRef).includes(baseStory.mediasRef[i])){                        
                        deleteFromStorage(baseStory.mediasRef[i]);
                    }
                }
            }
            if (baseStory.mediasRef != null){                
                if (update.mediasRef == null){
                    update.mediasRef = deleteField();
                }
            }

            if (baseStory.menuFile != null && update.menuFile == null){
                update.menuFile = deleteField();
            }  
            if (baseStory.menuFile != null && update.menuFile != baseStory.menuFile && baseStory.menuFile.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){               
                deleteFromStorage(baseStory.menuFile);
            } 

            if (baseStory.menuFileEn != null && update.menuFileEn == null){
                update.menuFileEn = deleteField();
            }  
            if (baseStory.menuFileEn != null && update.menuFileEn != baseStory.menuFileEn && baseStory.menuFileEn.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){               
                deleteFromStorage(baseStory.menuFileEn);
            } 

            if (baseStory.dayMenuFile != null && update.dayMenuFile == null){
                update.dayMenuFile = deleteField();
            }  
            if (baseStory.dayMenuFile != null && update.dayMenuFile != baseStory.dayMenuFile && baseStory.dayMenuFile.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){               
                deleteFromStorage(baseStory.dayMenuFile);
            } 
            if (baseStory.menuOptimizedFormat != null && update.menuOptimizedFormat == null){
                update.menuOptimizedFormat = deleteField();
            } 
            if (baseStory.menuReformatDate != null && update.menuReformatDate == null){
                update.menuReformatDate = deleteField();
            } 
            if (baseStory.menuId != null && update.menuId == null){
                update.menuId = deleteField();
            }
            if (baseStory.posChatLink != null && update.posChatLink == null){
                update.posChatLink = deleteField();
            } 
            if (baseStory.metaPixel != null && update.metaPixel == null){
                update.metaPixel = deleteField();
            } 
            if (baseStory.tiktokPixel != null && update.tiktokPixel == null){
                update.tiktokPixel = deleteField();
            } 
            if (baseStory.alias != null && update.alias == null){
                update.alias = deleteField();
            } 
            if (baseStory.aliasLowerCase != null && update.aliasLowerCase == null){
                update.aliasLowerCase = deleteField();
            } 
            if (baseStory.previousAlias != null && update.previousAlias == null){
                update.previousAlias = deleteField();
            } 
            if (baseStory.previousAliasLowerCase != null && update.previousAliasLowerCase == null){
                update.previousAliasLowerCase = deleteField();
            } 
            if (baseStory.storyLink != null && update.storyLink == null){
                update.storyLink = deleteField();
            }    
            if (baseStory.instagramProfileName != null && update.instagramProfileName == null){
                update.instagramProfileName = deleteField();
            } 
            if (baseStory.instagramProfileUserName != null && update.instagramProfileUserName == null){
                update.instagramProfileUserName = deleteField();
            } 
            if (baseStory.orderingPlatforms != null && update.orderingPlatforms == null){
                update.orderingPlatforms = deleteField();
            }             
            
            //
            update.baseStoryId = null;
            update.baseStory = true;
            update.neverPublishedAsLogged = deleteField();
            await updateDoc(baseDocRef, update);        
            await deleteDoc(editionVersionDocRef);    
            await updateMediasForTransformation(editedVersionDoc.data().baseStoryId, story, baseStory, story.uid);    
            const launchDownsizeMedias = httpsCallable(functions, "launchDownsizeMedias");
            launchDownsizeMedias({storyId: editedVersionDoc.data().baseStoryId});      
        }
        // case where the user is editing his first story created while he was not logged in
        else {            
            const update = {
                publicationDate: new Date().getTime(),                
                baseStoryId : null,
                baseStory: true,
                neverPublishedAsLogged: deleteField(),
                savedTime: new Date().getTime(),
            };
            await updateDoc(editionVersionDocRef, update);                        
            await updateMediasForTransformation(editedVersionDoc.id, story, null, story.uid);      
            const launchDownsizeMedias = httpsCallable(functions, "launchDownsizeMedias");
            launchDownsizeMedias({storyId: editedVersionDoc.id});         
        }          
        
        setShowProgress(false);
        setActiveForm("use-it");
    }

    function showUseItButton(){
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous)){
            return null;
        }
        else return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16, right: screenWidthToMakerForm+16, position: 'absolute'}}>
                <MaterialButton style="filled-tonal" onClick={() => {analyticsManager.logUseItScreenViewTag(storyId);setActiveForm("use-it");}} value={t('MenuToUse')} />
            </div>
        );  
    }

    async function onLinkBookingClick(){
        analyticsManager.logOpenPopupTag(storyId, "booking_link", true);
        setShowLinkBooking(true);
        setDialogRestaurantPhone("");
    }

    function showLinkBookingDialog(){

        async function close(){
            if (story.posBookingLink == "googlemaps" || document.getElementById("booking-link") != null && validURL(document.getElementById("booking-link").value) || document.getElementById("booking-link").value.trim() === ""){                                            
                analyticsManager.logOpenPopupTag(storyId, "booking_link", false);
                let unvalidatedOrderingLink = false;
                if (story.orderingPlatforms != null){
                    for (let i = 1; i < story.orderingPlatforms.length+1; i++){                        
                        if (!validURL(document.getElementById("ordering-link-" + i).value) && document.getElementById("ordering-link-" + i).value.trim() !== ""){
                            unvalidatedOrderingLink = true;
                            break;
                        }
                    }
                    
                    if (!unvalidatedOrderingLink){                         
                        for (let i = 1; i < story.orderingPlatforms.length+1; i++){
                            await addOrderingPlatform(i-1, document.getElementById("ordering-link-" + i).value);
                        }
                        if (story.orderingPlatforms.includes(""))
                            await deleteOrderingPlatform("");                                                
                    }                    
                }
                if (!unvalidatedOrderingLink){                                     
                    if (restaurantPhone === "" && document.getElementById("booking-phone") != null && document.getElementById("booking-phone").value != story.posPhone){
                        await updatePosPhone(document.getElementById("booking-phone").value);                                                   
                    }
                    if (document.getElementById("booking-link") != null && document.getElementById("booking-link").value != story.posBookingLink){
                        await updatePosBookingLink(document.getElementById("booking-link").value);
                    }
                }
                setShowLinkBooking(unvalidatedOrderingLink);
            }
            setDialogRestaurantPhone("");
            setTimeout(()=>{setHighlight("book")}, 1000);
        }

        async function updatePosBookingLink(link){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);
            let finalLink = link;
            if (finalLink === "googlemaps" && reservable === false)
                finalLink = null;
            if (finalLink != null && finalLink.trim() === "")
                finalLink = null;

            const update = {
                posBookingLink: finalLink,
                savedTime: new Date().getTime(),
            };                        
    
            await updateDoc(docRef, update);
        }

        async function updatePosPhone(phone){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);            

            if (phone !== "none"){
                let finalPhone = phone;
                if (finalPhone == null || finalPhone.trim() === "")
                    finalPhone = deleteField();

                const update = {
                    posPhone: finalPhone,
                    posBookingByPhone: true,
                    savedTime: new Date().getTime(),
                };                        
                await updateDoc(docRef, update);                                     
            }
            else {
                await updateDoc(docRef, {
                    posBookingByPhone: false,
                    savedTime: new Date().getTime(),
                });
            }            
        }

        function showBookingPhoneOption(){            
            if (story.posBookingLink == null && story.orderingPlatforms == null){               
                if (!story.posBookingByPhone){
                    return (
                        <>
                            <LineBreak/>
                            {t('BookingOptionPhoneOff')}                            
                            <MaterialButton lowercase={true} style="text" value={t('BookingOptionPhoneToOn')} onClick={()=>{analyticsManager.logAuthorizePhoneNumberTag(storyId, true);updatePosPhone(story.posPhone);setDialogRestaurantPhone(story.posPhone);}}/>                        
                        </>
                    );
                }
                else {
                    return (
                        <>           
                            <LineBreak/>   
                            {t('BookingOptionPhoneOn')}
                            <LineBreak/>
                            {showBookingPhoneOptionInput()}                        
                            <MaterialButton lowercase={true} style="text" value={t('BookingOptionPhoneToOff')} onClick={()=>{analyticsManager.logAuthorizePhoneNumberTag(storyId, false);updatePosPhone("none");setDialogRestaurantPhone("");}} />
                        </>
                    );
                }
            } else return null;

            function showBookingPhoneOptionInput(){                  
                if (story != null && (restaurantPhone !== "" || story.posPhone != null && story.posPhone !== "" && dialogRestaurantPhone === "")){                    
                    return (
                        <TextField id='booking-phone' type='text' style={{width: '100%'}} disabled value={restaurantPhone !== "" ? restaurantPhone : story.posPhone} />
                    );
                }
                else {                    
                    return (
                        <TextField id='booking-phone' type='text' style={{width: '100%'}} defaultValue={story.posPhone != null ? story.posPhone : ""} onBlur={(e)=>{analyticsManager.logChoosePhoneNumberTag(storyId, "booking_popup", true);updatePosPhone(e.target.value);setDialogRestaurantPhone(e.target.value);}} />
                    );
                }   
            }
        }        

        function showBookingWebOption(){            
            if (story.posBookingLink === 'googlemaps' && reservable === true){
                return (
                    <>
                        {t('BookingOptionGoogleMaps')}
                        <br/>
                        <MaterialButton lowercase={true} style="text" onClick={() => {analyticsManager.logReplaceGBookingByWebLinkTag(storyId);updatePosBookingLink(null);}} value={t('BookingReplaceByLink')} />                        
                    </>
                );
            }
            else {
                return (
                    <>
                        {t('BookingOptionLink')}<LineBreak/>
                        <TextField id='booking-link' type='url' error={story.posBookingLink != null && document.getElementById("booking-link") != null && !validURL(document.getElementById("booking-link").value)} style={{width: '100%'}} defaultValue={story.posBookingLink === 'googlemaps' || story.posBookingLink == null ? "": story.posBookingLink} onBlur={(e)=>{analyticsManager.logChooseBookingLinkTag(storyId, true);updatePosBookingLink(e.target.value)}} placeholder={t('BookingLinkHint')} />                        
                        {reservable === true && <div style={{marginTop: 8, fontSize: 12, fontStyle: 'italic'}}>{t('BookingLinkHelp')}</div>}
                        {reservable === true && <MaterialButton lowercase={true} style="text" onClick={() => {analyticsManager.logReplaceWebLinkByGBookingTag(storyId);analyticsManager.logChooseBookingLinkTag(storyId, false);updatePosBookingLink('googlemaps');}} value={t('BookingReplaceByMaps')} />}
                    </>
                );
            }
        }

        function showBookingChatOption(){

            function disconnectInstagramProfile(){
                setInstagramAccountId(null);
                setFbAccessToken(null);
                setInstagramLoginType(null);
    
                const db = getFirestore();        
                const docRef = doc(db, "stories", storyId);
                const update = {
                    instagramProfileName: deleteField(),
                    instagramProfileUserName: deleteField(),
                    posChatLink: deleteField(),
                    savedTime: new Date().getTime(),
                };                        
    
                updateDoc(docRef, update);
            }        
    
            if (story.instagramProfileUserName != null)
                return <>
                    <LineBreak/>
                    {t('BookingOptionChat')}   
                    <LineBreak/>                 
                    <div style={{display: 'flex', flexDirection: "row", alignItems: 'center', alignContent: 'center'}}>                    
                        <img alt="instagram logo" src="/images/ic_instagram_color.png" style={{width: 20, height: 20, marginRight: 8}}/>
                        <span style={{fontSize: 12, fontStyle: 'italic', marginRight: 6}}>{t('IGProfileConnected', {name: story.instagramProfileName})}</span>
                        <MaterialButton style="text" onClick={() => {analyticsManager.logConnectInstagramTag(storyId, false);disconnectInstagramProfile();}} value="X"/>                    
                    </div>
                    <br/>
                </>;
            else
                return <>
                        <LineBreak/>
                        {t('BookingOptionChat')}
                        <LineBreak/>
                        <LoginDialog origin="connect_instagram" title={t('ConnectInstagram')} buttonText={t('ConnectInstagram')} loginForContent={true} mediaContentType={MEDIA_TYPE_PROFILE} bypassFromIG={bypassConnectToIGForProfile} resetBypassFromIg={setBypassConnectToIGForProfile} onPageSelected={onInstagramConnected} />                    
                        <br/>
                    </>;
        } 

        function showOrderingOption(){

            let orderingLink = null;
            if (story.orderingPlatforms != null){
                let orderingLinkCounter = 0;
                orderingLink = story.orderingPlatforms.map((orderingPlatform)=>{
                    orderingLinkCounter++;
                    return <div key={orderingLinkCounter}><br/><div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}><TextField id={"ordering-link-" + orderingLinkCounter} type='url' error={story.orderingPlatforms[orderingLinkCounter-1].trim() !== "" && !validURL(story.orderingPlatforms[orderingLinkCounter-1])} style={{width: '100%'}} defaultValue={orderingPlatform} placeholder={t('OrderingLinkHint')} onBlur={(e)=>addOrderingPlatform(orderingLinkCounter-1, e.target.value)} /><MaterialButton style="text" value="X" reducedPadding={true} onClick={()=>{analyticsManager.logAddOrderingPlatformTag(storyId, false);deleteOrderingPlatform(document.getElementById("ordering-link-" + orderingLinkCounter).value);}}/></div></div>
                });
            }

            return (<>                        
                        <div style={{fontSize: 20, width: '100%'}}>                            
                            {t('Ordering')}                            
                            {orderingLink}
                            <LineBreak/>
                            <MaterialButton style="text" value={t('OrderingAddAnotherPlatform')} onClick={(e)=>{analyticsManager.logAddOrderingPlatformTag(storyId, true);addOrderingPlatform(story.orderingPlatforms != null ? story.orderingPlatforms.length : 0, e.target.value);}} />
                        </div>
                </>
                );                        
        }

        async function addOrderingPlatform(idx, link){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);                            
            
            await runTransaction(db, async (transaction) => {
                if (story.orderingPlatforms != null && link.trim() !== ""){
                    const deletePrevious = {
                        orderingPlatforms: arrayRemove(story.orderingPlatforms[idx]),
                        savedTime: new Date().getTime(),
                    };                                
                    await transaction.update(docRef, deletePrevious);
                }
            
                let update = {
                    orderingPlatforms: arrayUnion(link.trim()),
                    savedTime: new Date().getTime(),
                };                    
                await transaction.update(docRef, update);
              });
                                
        }

        async function deleteOrderingPlatform(link){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);
            const update = {
                orderingPlatforms: arrayRemove(link),
                savedTime: new Date().getTime(),
            };                                
            await updateDoc(docRef, update);  
            const uptodateStory = (await getDoc(docRef)).data();
            if (uptodateStory.orderingPlatforms != null && uptodateStory.orderingPlatforms.length === 0){
                const update2 = {
                    orderingPlatforms: deleteField(),
                    savedTime: new Date().getTime(),
                };                                
                await updateDoc(docRef, update2);  
            }
        }

        if (showLinkBooking){
            const additionalStyle = {};
            if (!isTabletOrMobile){
                additionalStyle.width = 320;
            }

            return (
                <Dialog onClose={close} open={showLinkBooking }>
                    <DialogTitle style={{userSelect: 'none', ...additionalStyle}}>{t('ConfigureBooking')}</DialogTitle>  
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", padding: 16}}>
                        <div style={{fontSize: 20}}>{t('Booking')}</div><LineBreak/>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: "flex-start"}}>                        
                            {showBookingWebOption()}                            
                            {showBookingPhoneOption()}
                            {showBookingChatOption()}
                            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                                <div style={{width: 140, height: 1, background: '#A0A0A070'}}/>
                            </div> 
                            {showOrderingOption()}
                        </div>
                    </div>
                </Dialog>
            ); 
        }
        else return null; 
    }

    if (props.show) {
        const availableWidth = (isTabletOrMobile ? windowDimensions.width : 360.0) - 2 * 16;
        const fullAliasInputWidth = Math.min(getTextWidth("upreality.store/p/", "16px Roboto") + aliasInputWidth, availableWidth);
        
        const modifyBtnMarginRight = Math.max((availableWidth - fullAliasInputWidth) / 2 - 2, 0); // 2 text button inner padding

        function checkValidity(e){
            const alias = e.target.value;
            const regex = /[^a-zA-Z0-9-]/g;
            const invalid = alias.match(regex) || alias === "";
            setInvalidAlias(invalid ? t('aliasNotValid') : false);
        }

        function resetAlias(){
            document.getElementById("alias-input").value = story.alias;
            setInvalidAlias(false);
            setLinkEditable(false);
            analyticsManager.logChangeLinkTag(storyId, false);
        }

        async function validateAlias(){
            if (invalidAlias) return;

            const alias = document.getElementById("alias-input").value;

            if (alias === story.alias) {
                setLinkEditable(false);
                return;
            }
            
            let validAlias = await updateAlias(storyId, story.alias, story.previousAlias, alias);                        
            setInvalidAlias(validAlias ? false : t('aliasAlreadyExists'));
            setLinkEditable(!validAlias);              
            analyticsManager.logChangeLinkTag(storyId, true);  
            if (validAlias){
                setAliasInputWidth(getTextWidth(alias, "16px Roboto") + 2 * 4);
            }     
        }
                
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;
        const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;
        const additionalStyle = isMobileBottomBarDisplayed ? {overflowY: "scroll", height: windowDimensions.height - 56} : {};

        return(
            <div>
                <div style={{padding: 16}}>
                    <div style={{...additionalStyle}}>
                        <div style={{fontSize: 28}}>{t('MyActions')}</div>
                        <div style={{fontSize: 20, marginTop: 20}}>{t('BookButtonTitle')}</div>
                        <br/>                   
                        <MaterialButton lowercase={true} style="outlined-tonal" onClick={() => onLinkBookingClick()} value={t('LinkBooking')}/>     
                        <div style={{fontSize: 20, marginTop: 20}}>{t('YourColors')}</div>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center', paddingTop: 10}}>
                            <div style={{textAlign: 'center'}}>
                                <label htmlFor="primaryColor" style={{marginTop: 100, fontSize: 14, fontStyle: 'italic'}}>{t('PrimaryColorBg')}</label>                                
                                <ColorPicker style={{marginTop: 4}} color={story.primaryColor} type="primaryColor" source="my_restaurant_form" />
                            </div>
                            <div style={{marginLeft: 8, textAlign: 'center'}}>
                                <label htmlFor="secondaryColor" style={{marginTop: 100, fontSize: 14, fontStyle: 'italic'}}>{t('PrimaryColorTxt')}</label>
                                <ColorPicker style={{marginTop: 4}} color={story.secondaryColor} type="secondaryColor" source="my_restaurant_form" />
                            </div>
                            <div style={{ marginLeft: 100, marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <label htmlFor="shareButton" style={{fontSize: 14, fontStyle: 'italic'}}>{t('PreviewButtonLabel')}</label>
                                <div className="maker-primary-button" style={{marginTop: 4, width: getTextWidth(t('ShareButton'), "14px Roboto") + 36, backgroundColor: story.primaryColor}}>                                    
                                    <ShareIcon id="shareButton" sx={{width: 16, height: 16, color: story.secondaryColor}} /><div className="maker-primary-button-text" style={{color: story.secondaryColor}}>{t('ShareButton')}</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div style={{fontSize: 20, marginTop: 20}}>{t('SocialWebsiteConfig')}</div>
                        <div style={{fontSize: 16, marginTop: 20}}>{t("StoryLink")}</div><br/>
                        <div width={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>                            
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{marginBottom: invalidAlias ? 22 : 0}}>upreality.store/p/</div><TextField id="alias-input" inputProps={{style: {padding: 2}}} onChange={(e)=>checkValidity(e)} error={invalidAlias} helperText={invalidAlias ? invalidAlias : null} type='text' style={{width: linkEditable ? '100%' : aliasInputWidth, }} disabled={!linkEditable} defaultValue={story.alias} />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end',}}>
                                <div style={{marginRight: linkEditable ? 0 : modifyBtnMarginRight}}>
                                    {!linkEditable && <MaterialButton style="text" value="modifier" reducedPadding={true} lowercase={true} onClick={()=>{setLinkEditable(true);analyticsManager.logChangeLinkTag(storyId, "");}} />}
                                    {linkEditable && <><MaterialButton style="text" value="x" onClick={()=>resetAlias()} reducedPadding={true} /><MaterialButton style="text" value="ok" onClick={()=>validateAlias()} reducedPadding={true} /></>}
                                </div>
                            </div>
                        </div> 
                        <br/>                                              
                        {fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous) &&                                                                        
                            <MaterialButton style="outlined-tonal" value={t("ConfigureMyPixels")} onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "configure_pixels", true);setShowPixelDialog(true);}}/>                                
                        }                         
                        <MobileMenu storyId={storyId} story={story}/>                        
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), left: 16, position: 'absolute'}}>
                            <MaterialButton id="stickers-btn" style="outlined-tonal" onClick={() => {analyticsManager.logChooseStickersScreenViewTag(storyId);setActiveForm("choose-stickers");}} value={t('ActionsToStickers')}/>
                        </div>     
                        {fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous) &&
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, position: 'absolute'}}>
                                <MaterialButton id="use-btn" style="filled-tonal" onClick={() => {analyticsManager.logOpenPopupTag(storyId, "publish", true);setShowPublishConfirm(true);}} value={t('PublishMyChanges')}/>
                            </div>
                        }
                        {showUseItButton()}                        
                        {isMobileBottomBarDisplayed && <div style={{height: 100}}/>}
                    </div> 
                    {showPublishConfirm && <ConfirmDialog showConfirm={showPublishConfirm} setShowConfirm={setShowPublishConfirm} showProgress={showProgress} setShowProgress={setShowProgress} dialogTitle={t('PublishMyChanges')} dialogText={t('PublishWarning')} dialogAction={t('Publish')} tagName="publish" onSuccess={()=>{analyticsManager.logPublishTag(storyId);publishStory();}} />}      
                </div>                 
                {showLinkBookingDialog()} 
                {showIGConnectedSuccessSnack()}
                <PixelDialog opened={showPixelDialog} onClose={()=>{setShowPixelDialog(false);}}/>
                <MobileBottomBar currentTab="0" storyId={storyId} />
            </div>
        );
    }
    else return null;
}

export default ChooseActionsForm;