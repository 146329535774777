import './Player.css';

import React, {useContext, useEffect, useState} from 'react';
import { PlayerContext } from './PlayerContext';
import {browserLocales, getTextWidth, getCurrencySymbol, removeCountryFromAddress} from './Utils';
import LocaleCurrency from 'locale-currency';
import { motion, useAnimate } from "framer-motion";
import { useTranslation } from 'react-i18next';


function RestaurantInfos(props){

    const { t } = useTranslation();

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const highlight = useContext(PlayerContext).get("highlight");
    const setHighlight = useContext(PlayerContext).get("setHighlight");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");
    const [scope, animate] = useAnimate();


    let refBtnType = "BookButton";
    if ((props.story.posBookingLink == null || props.story.posBookingLink === "none") /*&& props.story.orderingPlatforms == null*/ && !props.story.posBookingByPhone || props.story.posId === "ChIJC4g4KBNx5kcRH_z3vKUpnZw")
        refBtnType = "ContactButton";
    if (props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "WX2KsZRK4SNKT9hnNIp0"){
        refBtnType = "ApplyButton";
    }
    const maxTextWidth = Math.max(getTextWidth(t(refBtnType), 18 + "px Roboto condensed"), getTextWidth(t('ShareButton'), 18 + "px Roboto condensed"));    
    const buttonWidth = (8 + 16 + 4 + maxTextWidth + 8 - 10) * miniMultiplier + 16;    
    // 8 left margin of restaurant info - 4 space between restaurant infos and button - 8 right margin of the button
    const allowedWidthForInfos = storyDimensions.containerWidth - buttonWidth - (8 + 4 + 8) * miniMultiplier;

    useEffect(() => {
        if (highlight != null){

            let elementId = null;
            if (highlight.includes("restaurantInfos")){
                elementId = "restaurant-infos-container";
            }
            else if (highlight.includes("address")){
                elementId = "restaurant-infos-address";
            }
            else if (highlight.includes("chat")){
                elementId = "restaurant-infos-chat";
            }
            else if (highlight.includes("avatar")){
                elementId = "restaurant-infos-avatar";
            }

            const element = document.getElementById(elementId);
            if (elementId != null && element != null){                   
                const animation = async () => {
                    await animate(element, { background: "#FFFFFFFF"}, {duration: 0.6});
                    if (element != null)
                        animate(element, { background: "#FFFFFF00"}, {duration: 0.6});
                }
                
                animation();
            }
        }
      }, [highlight]);

    if (props.placeDetails != null && props.show){  
        if (props.storyId  === "YPuRVqGYO2Q2QFKEkRvQ"){
            let locale = browserLocales(true)[0];
            if (locale !== "fr"){
                props.story.posArea = "restaurants all over Paris";
            }
        }                         
        let businessTypeAddress = "";
        if (props.placeDetails.primaryTypeDisplayName != null) {            
            businessTypeAddress += props.placeDetails.primaryTypeDisplayName.text;                       
            businessTypeAddress += " · ";
        }

        if (props.story.posArea != null && props.story.posArea.length > 0)
            businessTypeAddress += props.story.posArea;
        else    
            businessTypeAddress += removeCountryFromAddress(props.placeDetails.formattedAddress, props.placeDetails.addressComponents);
        
        const allowedWidthForText = allowedWidthForInfos - (48 + 8) * miniMultiplier;

        const nameOn2lines = getTextWidth(props.placeDetails.displayName.text, "bold italic " + 16 * miniMultiplier + "px Roboto") > allowedWidthForText;
        const addressOn2lines = (8 + 48 + 8) * miniMultiplier + getTextWidth("italic " + businessTypeAddress, 12 * miniMultiplier + "px Roboto") > allowedWidthForText;
        //const infosHeight = 14 * 1.2 + (2 + addressOn2lines ? 1 : 0) * 12 * 1.2 + 14 * 1.2 + 8;
        const infosHeight = 92 * miniMultiplier;
        const topMargin = storyDimensions.containerHeight - infosHeight;
        const businessTypeAddressStyle = {
           
        };
        
        if (!nameOn2lines){
            businessTypeAddressStyle.display = 'flex';
            businessTypeAddressStyle.flexDirection = 'row';
            businessTypeAddressStyle.lineClamp = 2;
            businessTypeAddressStyle.display = '-webkit-box';            
            businessTypeAddressStyle.WebkitLineClamp = 2;
            businessTypeAddressStyle.WebkitBoxOrient = 'vertical';
        }  
        else {
            businessTypeAddressStyle.whiteSpace = 'nowrap';
        }      

        let ratingsPrice = props.placeDetails.rating + " ☆ (" + props.placeDetails.userRatingCount + ")";
        if (props.storyId === "LnSX2qKrthKsRmr1vcxc"){
            props.placeDetails.displayName.text = "Bioburger Lyon";
            ratingsPrice = ((props.placeDetails.rating * props.placeDetails.userRatingCount + 4.2 * 543) / (props.placeDetails.userRatingCount + 543)).toFixed(1) + " ☆ (" + (props.placeDetails.userRatingCount + 543) + ")";
        }
        if (props.storyId  === "ZcJ9o5kOjzeIvIZ4z0kw"){
            props.placeDetails.displayName.text = "Bioburger Strasbourg";
            ratingsPrice = ((props.placeDetails.rating * props.placeDetails.userRatingCount + 4.6 * 151) / (props.placeDetails.userRatingCount + 151)).toFixed(1) + " ☆ (" + (props.placeDetails.userRatingCount + 151) + ")";
        }        
        if (props.storyId  === "YPuRVqGYO2Q2QFKEkRvQ"){
            props.placeDetails.displayName.text = "Bioburger Paris";

            ratingsPrice = ((props.placeDetails.rating * props.placeDetails.userRatingCount + 4.6 * 921 + 4.5 * 285 + 4.5 * 288 + 4.3 * 163 + 4.7 * 175 + 4.3 * 1010 + 4.2 * 678) / (props.placeDetails.userRatingCount + 921 + 285 + 288 + 163 + 175 + 1010 + 678)).toFixed(1) + " ☆ (" + (props.placeDetails.userRatingCount + 921 + 285 + 288 + 163 + 175 + 1010 + 678) + ")";
        }
        if (props.storyId  === "zUEpyuVwo5Tws3hnvFds"){
            props.placeDetails.displayName.text = "Bioburger Bordeaux";
            ratingsPrice = ((props.placeDetails.rating * props.placeDetails.userRatingCount + 4.3 * 126) / (props.placeDetails.userRatingCount + 126)).toFixed(1) + " ☆ (" + (props.placeDetails.userRatingCount + 126) + ")";
        }
        if (props.storyId  === "LQ8cYZF1BrqCbKYOI7g7"){
            props.placeDetails.displayName.text = "Bioburger Val-de-Seine";            
        }        
        if (props.storyId  === "G4uGdmkDklxxMumN9FIc"){
            props.placeDetails.displayName.text = "Bioburger Brocherie";            
        }        
        if (props.storyId  === "4WlhflMBRftrhBabhzqL"){
            props.placeDetails.displayName.text = "Bioburger Jaude";            
        }
        if (props.storyId  === "clexvJMGmwlGXjY8KLaT"){
            props.placeDetails.displayName.text = "Bioburger Nantes";            
        }
        if (props.storyId  === "P4hss0ADGtE2wMY5fcrQ"){
            props.placeDetails.displayName.text = "Bioburger Observatoire";            
        }        
        if (props.storyId  === "nRyYRVND1xh34m32eGMa"){
            props.placeDetails.displayName.text = "Bioburger Zola";            
        }       
        if (props.storyId  === "rj1ZbTjACKeCMOqqAi4l"){
            props.placeDetails.displayName.text = "Bioburger Lille";            
        }  
        if (props.storyId  === "PaBK2iq1At5um1V6nNGo"){
            props.placeDetails.displayName.text = "Bioburger Foch";            
        }  
        if (props.storyId  === "1iDXY4hOyo1PVyrvCmvG"){
            props.placeDetails.displayName.text = "Bioburger Morgan";            
        }                  

        if (props.placeDetails.priceLevel != null) {                                                
            let locale = browserLocales()[0];
            //log("browserLocales : " + browserLocales()[0]);
            let currencySymbol = getCurrencySymbol(locale, LocaleCurrency.getCurrency(locale));
            ratingsPrice += " · " + currencySymbol.repeat(props.placeDetails.priceLevel);    
        }

        function showAvatar(){
            if (props.story.avatarUrl != null){
                return(
                    <div id="restaurant-infos-avatar" name="restaurant-infos-avatar">
                    <img alt="restaurant's avatar" src={props.story.avatarUrl} className="FBAvatarContainer" style={{width: 48 * miniMultiplier, height: 48 * miniMultiplier}} />                                    
                    </div>
                );
            }
            else {
                return(
                    <div id="restaurant-infos-avatar" name="restaurant-infos-avatar">
                    <img alt="restaurant's avatar placeholder" src="/images/avatar_placeholder.png" className="FBAvatarContainer" style={{background: '#FFFFFF', width: 48 * miniMultiplier, height: 48 * miniMultiplier}} />                                    
                    </div>
                );
            }
        }

        return (
                <div ref={scope} id="restaurant-infos-container" name="restaurant-infos-container" className="RestaurantInfosContainer" style={{top: topMargin, left: 8 * miniMultiplier, width: allowedWidthForInfos,}} >    
                    <div className="RestaurantDescriptionContainer" style={{fontSize: 12 * miniMultiplier}}>    
                        {showAvatar()  }
                        <div style={{position: 'relative', left: 8 * miniMultiplier}}>
                            <div id="restaurant-infos-name" name="restaurant-infos-name" className="RestaurantName" style={{marginTop: !nameOn2lines && !addressOn2lines ? 2 : 0, width: allowedWidthForText, fontSize: 16 * miniMultiplier,}}>{props.placeDetails.displayName.text !== "Holberton School Paris" ? props.placeDetails.displayName.text : "Holberton School"}</div>
                            <div id="restaurant-infos-address" name="restaurant-infos-address" className='RestaurantBusinessAddress' style={{marginTop: !nameOn2lines && !addressOn2lines ? 2 : 0, width: allowedWidthForText, ...businessTypeAddressStyle}}>{businessTypeAddress}</div>
                            <div className='not-selectable' style={{marginTop: !nameOn2lines && !addressOn2lines ? 2 : 0}}>{ratingsPrice}</div>
                        </div>
                    </div>  
                    {showChatTxt()}
                </div>
        );

    }
    else {
        return null;
    }

    function showChatTxt(){
        if (props.story.chatMsg != null){
            return (
                <div id="restaurant-infos-chat" name="restaurant-infos-chat" className="ChatText not-selectable" style={{marginTop: 2, fontSize: 14 * miniMultiplier, width: allowedWidthForInfos}}>{props.story.chatMsg.parseEmoji()}</div>  
            );
        }
        else {
            return null;
        }
    }
}

String.prototype.parseEmoji = function(){
    
    let parsedMsg = this;

    let matches = this.match(/\\u.{4}\\u.{4}/g);
    
    String.prototype.hexEncode = function(){
        var hex, i;
    
        var result = "";
        for (i=0; i<this.length; i++) {
            hex = this.charCodeAt(i).toString(16);
            result += ("000"+hex).slice(-4);
        }
    
        return result;
    }

    String.prototype.replaceEmoji = function(utf16Emoji){
        let first = utf16Emoji.replace("\\u", '').substring(0, 4);
        let second = utf16Emoji.replace("\\u", '').replace("\\u", '').substring(4);
        let emoji = String.fromCharCode(parseInt(first, 16), parseInt(second, 16));

        parsedMsg = parsedMsg.replace(utf16Emoji, emoji);
    }

    if (matches != null){
        for (const match of matches) {
            parsedMsg.replaceEmoji(match);
        }
    }

    return parsedMsg;
}

export default RestaurantInfos;