import './../../Player.css';
import React, {useState, useContext} from 'react';
import { isAndroid, isInstagramInAppBrowser, isIOS, isMacOS, isTikTokInAppBrowser, openFullscreen } from '../../Utils';
import { analyticsManager } from '../../../index';
import SecondaryButton, {locationType} from '../components/SecondaryButton';
import { appAnalyticsManager } from '../../Player';
import { PlayerContext } from '../../PlayerContext';
import WebPageMock from '../components/WebPageMock';
import { set } from 'firebase/database';

function LocationButton(props){
    //const [location, setLocation] = useState(null);
    const [frameOpened, setFrameOpened] = useState(false);
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
    const playMode = useContext(PlayerContext).get("playMode");    
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const playAudio = useContext(PlayerContext).get("playAudio");
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");

    if (props.story.posId == null) return (null);

    const open = () => {              
        if (!fromMaker && !fromDemo && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){            
            const ambiantPlayer = document.getElementById("audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                try {
                    ambiantPlayer.play();                
                } catch(error){}
            }
            setPlayAudio(true);
        }
        openFullscreen();
         
        analyticsManager.logCheckItineraryTag(props.storyId, playMode);
        if (appAnalyticsManager)
            appAnalyticsManager.clickedLocation();
        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "map",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "map"});
        }
    
        /*const success = function(pos) {
            setLocation(pos);
            window.open("https://www.google.com/maps/dir/?api=1&origin=" + pos.coords.latitude + "," + pos.coords.longitude + "&destination=" + props.placeDetails.displayName.text + ", " + props.placeDetails.formattedAddress);
            analyticsManager.logPlayContentTag("mapsitinerary", null);
        };
        const error = function(error) {
            window.open("https://www.google.com/maps/dir/?api=1&destination=" + props.placeDetails.displayName.text + ", " + props.placeDetails.formattedAddress);
            analyticsManager.logPlayContentTag("mapsitinerary", null);
        };
    
        const options = {
            enableHighAccuracy: true,
            timeout: 5000
          };*/
        
        // TODO ok for inapp browsers, test again with standalone browsers
        /*if (Android() && !isTikTokInAppBrowser() || iOS() && !isSnapchatInAppBrowser() && !isTikTokInAppBrowser() && !isFacebookInAppBrowser()){
            window.navigator.geolocation.getCurrentPosition(success, error, options);
        }
        else {*/
        
        if (props.storyId === "cJU0Fnom8MTjmokacI3I"){            
            if (! (isIOS() && isInstagramInAppBrowser())){
                window.open("https://www.holbertonschool.fr/contact");      
            }
            else {
                window.location.href = "https://www.holbertonschool.fr/contact";
            }
        }
        else if (playMode === "demo" || props.storyId != null && props.storyId.toLowerCase() === "cafeupreality")
            setFrameOpened(true);
        else {
            // does not work on iPhone 15 pro iOS 17 - the link does not work either when input directly on the browser bar
            /*if (isMacOS() || isIOS()){
                window.location.href = "http://maps.apple.com/?q="+encodeURI(props.placeDetails.displayName.text)+"&address=" + encodeURI(props.placeDetails.displayName.text + ", " + props.placeDetails.formattedAddress);
            }
            else {*/                
                if (! (isIOS() && isInstagramInAppBrowser())){
                    window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(props.placeDetails.displayName.text + ", " + props.placeDetails.formattedAddress));            
                }
                else {
                    window.location.href = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(props.placeDetails.displayName.text + ", " + props.placeDetails.formattedAddress);
                }                
            //}
        }
        //}
    }

    function closeFrame(){
        setFrameOpened(false);
    }

    if (props.show){
        if ((playMode === "demo" || props.storyId != null && props.storyId.toLowerCase() === "cafeupreality") && frameOpened){
            return <WebPageMock url={"/images/demo_itinerary.webp"} imageOverWebsite="true" close={closeFrame} open={frameOpened} width={storyDimensions.containerWidth} height={storyDimensions.containerHeight}/>;
        }
        else {
            if (props.placeDetails != null){
                return <SecondaryButton buttonType={locationType} open={open}/>;
            }
            else {
                return null;
            }
        }
    }
    else {
        return null;
    }
}

export default LocationButton;