
import './Player.css';

import React, {useState, useEffect, useRef} from 'react';
import { PlayerContext } from './PlayerContext.js';
import { StickerContext } from './StickerContext.js';
import { getFileNameFromUrl, getWindowDimensions, getFileFormat, isInstagramInAppBrowser, isIOS, printObj, checkMobile, isAndroid, isTikTokInAppBrowser, log, getTextWidth, addOpacityToHexColor, getIOSVersion } from './Utils.js';
import StoryVideo, {checkVideoRunning, getStoryDimensions} from './StoryVideo';

import SideMenu from './side_menu/SideMenu';
import RestaurantInfos from './RestaurantInfos';

import { collection, getDocs, getFirestore, onSnapshot, or, query, where } from "firebase/firestore";
import { getInstallations, getId} from "@firebase/installations";

import { doc, getDoc } from "firebase/firestore";
import {app, setWebFallback, analyticsManager, webFallback} from '../index';
import StickerInside from './overlay/stickers/StickerInside';
import FullScreenMediaPlayer from './overlay/stickers/FullScreenMediaPlayer';
import StickerGallery, {menuType} from './overlay/stickers/StickerGallery';
import {browserLocales, openFullscreen, demoJsonEn, demoJsonFr, isIPhone} from './Utils';
import AppAnalyticsManager from './analytics/AppAnalyticsManager';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from "@sentry/react";
import { useAnimate } from 'framer-motion';
import { use } from 'i18next';
import { set } from 'firebase/database';
import loadFont from 'ffont-loader';

// to have the var up-to-date when the setTimeout function occurs
var storyClicked1Time = false;
export function setStoryClicked1Time(value){
  storyClicked1Time = value;
}

var audioFromBgVideoOn = false;
export function setAudioFromBgVideoOn(on){
  audioFromBgVideoOn = on;
}

function Player(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [story, setStory] = useState(null);
  const [menuTitleFont, setMenuTitleFont] = useState(null);
  const [menuTextFont, setMenuTextFont] = useState(null);
  const storyIdParams = useParams();
  const fromDemoInstantAd = new URLSearchParams(window.location.search).get("fromDemoInstantAd");
  const [storyId, setStoryId] = useState((props.fromMaker || props.fromDemo) ? (props.storyId != null ? props.storyId : storyIdParams.storyId) : null);  
  const [placeDetails, setPlaceDetails] = useState(null);
  const [activeDrag, setActiveDrag] = useState(null);
  const [menuStickerDimensions, setMenuStickerDimensions] = useState({width: 0, height: 0});
  const [insideStickerDimensions, setInsideStickerDimensions] = useState({width: 0, height: 0});
  const [stickersOutOfScreen, setStickersOutOfScreen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const playerOnDesktop = ! isTabletOrMobile && ! props.fromMaker && !props.fromDemo;
  const [windowDimensions, setWindowDimensions] = useState(! playerOnDesktop ? props.windowDimensions : {width: 360, height: Math.min(720, props.windowDimensions.height - 72)});
  const [miniMultiplier, setMiniMultiplier] = useState(props.mini != null && props.mini && windowDimensions != null && windowDimensions.height ? 0.7 : 1);
  
  const [isFullScreenOpened, setisFullScreenOpened] = useState(false);
  const [mediaFullScreenOpened, setMediaFullScreenOpened] = useState(null);
     
  const [activeFeature, setActiveFeature] = useState(null);
  const [playInteractiveAnimation, setPlayInteractiveAnimation] = useState(props.fromDemo === "top");  
  const [stickerOpeningPlayed, setStickerOpeningPlayed] = useState(props.fromDemo);

  const storyDimensions = getStoryDimensions(story, windowDimensions);  

  // initialized after story is loaded, can be null while the story isnt
  const [playMode, setPlayMode] = useState(null);
  const idPrefix = props.fromDemo ? (props.fromDemo +"-") : "";

  const appAnalyticsManager = useRef(null);

  const [snackSwipeAlreadyDisplayed, setSnackSwipeAlreadyDisplayed] = useState(false);  
  const [menuStickerClicked, setMenuStickerClicked] = useState(false);
  const [insideStickerClicked, setInsideStickerClicked] = useState(false);
  const [shareButtonAlreadyHighlighted, setShareButtonAlreadyHighlighted] = useState(false);

  const storyRef = React.useRef(null);

  const { t } = useTranslation();  

  const [scope, animate] = useAnimate();

  let demoStory = false;

  const audioRef = React.createRef();
  const [playAudio, setPlayAudio] = useState(false);
  const [audioAlreadyDisabled, setAudioAlreadyDisabled] = useState(false);

  useEffect(() => {    
    if (audioRef.current != null && story != null && !props.fromDemo && story.musicUrl){
      if (playAudio && (audioRef.current.paused)){        
        audioRef.current.play();        
      }
      else if (! playAudio && ! audioRef.current.paused){
        audioRef.current.pause();
      }      
    }    
  }, [playAudio]);

  useEffect(() => {
    if (activeFeature != null){
      setStickerOpeningPlayed(true);
    }
    else {
      if (story){
        checkVideoRunning(story, idPrefix);
      }
    }
  }, [activeFeature]);

  const [retryBgVideo, setRetryBgVideo] = useState(true);
  useEffect(() => {    
    if (story && retryBgVideo){
      setRetryBgVideo(false);      
      setTimeout(()=>reloadVideoIfNoOverlays(story, idPrefix), 5000);
      setTimeout(()=>reloadVideoIfNoOverlays(story, idPrefix), 10000);      
    }

    function reloadVideoIfNoOverlays(story, idPrefix){
      const videoWithoutOverlays = document.getElementById("primary-btn-share") != null;
      if (videoWithoutOverlays){
        checkVideoRunning(story, idPrefix);
      }
    }
  }, [story, retryBgVideo]);

  if (! props.fromMaker && ! props.fromDemo && playerOnDesktop){
    //document.getElementsByTagName('body')[0].className = 'PatternBackground';
  }
  else if (! props.fromMaker && ! props.fromDemo){
    document.getElementsByTagName('body')[0].className = 'WhiteBackground';
  }

  const fetchStory = async()=>{
    let unsubscribe = null;  
    let unsubscribePostPublish = null;

    let storyDocument = null;
    let storyDocumentData = null;
    const db = getFirestore();
    
    if (props.fromMaker || props.fromDemo){
      storyDocument = await getDoc(doc(db, "stories", storyId));
      if (!storyDocument.exists() || storyDocument.data() == undefined){
        storyDocument = null;
      }
      if (storyDocument != null) {
        storyDocumentData = storyDocument.data();
      }
    }
    else {    
      if (process.env.NODE_ENV === 'development' || fromDemoInstantAd){
        const storiesRef = collection(db, "stories");
        const q = query(storiesRef, or(where("aliasLowerCase", "==", storyIdParams.storyId.toLowerCase()), where("previousAliasLowerCase", "==", storyIdParams.storyId.toLowerCase())));
        const querySnapshot = await getDocs(q);
        const storyDocs = querySnapshot.docs.filter((doc)=>doc.data().baseStory === true || doc.data().baseStory == null);
        storyDocs.forEach((doc) => {
          storyDocument = doc;
        });   
        if (storyDocument != null){      
          setStoryId(storyDocument.id);
          storyDocumentData = storyDocument.data();
        }
      }
      else { 
        try {                
          storyDocument = JSON.parse(document.getElementById("story").innerHTML);          
          document.getElementById("story").remove();        
        }
        catch (e){                        
          Sentry.captureMessage("Story not found while loading from html: " + e.message);
        }
        
        storyDocumentData = storyDocument;
        setStoryId(storyDocument.id);
      }
    }

    // TODO handle back press for all sub states
    // TODO everything material-ui
    // TODO windowDimensions and dp px

    if (storyDocument != null) {  
      
      demoStory = (storyDocument.id === "cafeupreality"); 

      if (! props.fromMaker && ! props.fromDemo){        
        document.getElementsByTagName('body')[0].style.backgroundColor = addOpacityToHexColor(storyDocumentData.primaryColor, 0.2);
      }

      if (! props.fromMaker && storyDocumentData.avatarUrl != null && ! demoStory){
        let link = document.querySelector("link[rel~='icon']");
        link.href = storyDocumentData.avatarUrl;
        link.type = "image/png";
        link.sizes = "any";
      }    

      setIsLoaded(true);            
      setStory(storyDocumentData);
      if (storyDocumentData.website){
        setWebFallback(storyDocumentData.website);                
      }
      else if (storyDocumentData.posName && storyDocumentData.posId) {
        setWebFallback("https://www.google.com/maps/search/?api=1&query=" + encodeURI(storyDocumentData.posName) + "&query_place_id=" + storyDocumentData.posId);                
      }
      
      // TODO limit to maker only or also used in player (eg with stats) ?
      unsubscribe = onSnapshot(doc(db, "stories", storyDocument.id), (fbDoc) => {              
        if (props.fromMaker && storyRef.current != null && storyRef.current.baseStoryId != null && fbDoc.data() == undefined && unsubscribePostPublish == null){
          unsubscribePostPublish = onSnapshot(doc(db, "stories", storyRef.current.baseStoryId), (doc) => {            
            if (doc.data() != undefined){
              // update with attributes that can change following resizing and thumbnail generation post publish              
              storyRef.current.width = doc.data().width;
              storyRef.current.height = doc.data().height;
              storyRef.current.offsetValue = doc.data().offsetValue;
              storyRef.current.imageRef = doc.data().imageRef;
              storyRef.current.videoRef = doc.data().videoRef;
              storyRef.current.mediasRef = doc.data().mediasRef;
              storyRef.current.tourMediasRef = doc.data().tourMediasRef;
              storyRef.current.dayMenuFile = doc.data().dayMenuFile;
              setStory(storyRef.current);
            }
          });
        }     
        if (fbDoc.data() == undefined || !props.fromMaker) return;        
        // case where the story is restarted from the maker     
        const updateStory = fbDoc.data();
        setStory(updateStory);        
      });      
  
      if (storyDocumentData != undefined){
        getPlaceDetails(storyDocumentData);
      }
      else {
        Sentry.captureMessage("storyDocumentData undefined");
      }

      // load custom fonts for menu      
      if (storyDocumentData.menuId && storyDocumentData.menuId !== "0"/* holberton */){
        const menuRef = doc(db, "menus", storyDocumentData.menuId);
        const menu = await getDoc(menuRef);
        if (menu.data().titleFont){
          loadFont({
            source: menu.data().titleFont.location,
            family: menu.data().titleFont.name,
          }).then(() => {
              setMenuTitleFont(menu.data().titleFont.name);
          });
        }
        if (menu.data().textFont){
          loadFont({
            source: menu.data().textFont.location,
            family: menu.data().textFont.name,
          }).then(() => {
              setMenuTextFont(menu.data().textFont.name);
          });
        }
      }
             
    } else {
      // TODO can happen on the maker when the story is deleted from a first login
      setIsLoaded(false);
      Sentry.captureMessage("Story not found " + (props.fromMaker || props.fromDemo ? storyId : storyIdParams.storyId));
    }    
    
    return () => {
      if (unsubscribe != null){
        unsubscribe();
      }
      if (unsubscribePostPublish != null){
        unsubscribePostPublish();
      }      
    }
  }  

  useEffect(() => {
    // to have a story version usable in firebase callbacks
    if (props.fromMaker && story != null){
      storyRef.current = story;
    }
  }, [story]);

  useEffect(() => {
    // maker : first place loading
    if (story != null && placeDetails == null && props.fromMaker){      
      getPlaceDetails(story);
    }
  }, [story != null && story.posId]);

  async function getPlaceDetails(storyData){   

    if (! demoStory){ 
      const posId = storyData.posId;

      if (posId != null) {         
          const language = browserLocales(true)[0] === "fr" ? "fr" : "en";
          getPlaceFromURL(posId, language)
            .catch((error) => {                 
              Sentry.captureMessage("Place first call by url failed: " + printObj(error)); 
              setTimeout(()=>getPlaceFromURL(posId, language).catch((e)=>{
                Sentry.captureMessage("Place second call by url failed: " + printObj(e));
                redirectIfPlaceKO();
              }), 2000);               
            });
      }

      function redirectIfPlaceKO(){
        if (webFallback){          
          window.location.replace(webFallback);
        }
      }

      function getPlaceFromURL(posId, language){        
        return fetch("https://places.googleapis.com/v1/places/"+posId+"?fields=displayName,primaryTypeDisplayName,rating,reviews,userRatingCount,priceLevel,formattedAddress,addressComponents,nationalPhoneNumber,currentOpeningHours,currentSecondaryOpeningHours,websiteUri,googleMapsUri&languageCode=" + language + "&key=" + process.env.REACT_APP_PLACE_DETAIL_API_KEY)                     
            .then(response => processResponse(response))
            .then(data => {               
              if (data != null){   
                setPlaceDetails(data);          
              }
              else {       
                Sentry.captureMessage("Place detail by url error, null response");
              }              
            })

          async function processResponse(response){
            if (response.ok){               
              return response.json(); 
            }
            else { 
              throw new Error(response.status);
            }
          }
      }
    }
    else {
      const language = browserLocales(true)[0];
      var placeDetails; 
      if (language === 'fr'){        
        placeDetails = JSON.parse(demoJsonFr);                
      }
      else {
        placeDetails = JSON.parse(demoJsonEn);     
      }
      setPlaceDetails(placeDetails);
    }    
  }  

  function propagateFocusChange(videoFocused){      
    checkBgVideoPlaying();
    if (videoFocused){    
      
      if (activeFeature === "menu"){
        analyticsManager.logOpenMenuTag(storyId, playMode, false);        
      }
      else if (activeFeature === "inside"){
        analyticsManager.logOpenInsideTag(storyId, playMode, false);        
      }
      else if (activeFeature === "hours"){
        analyticsManager.logCheckHoursTag(storyId, playMode, false);
      }
      else if (activeFeature === "reviews"){
        analyticsManager.logCheckReviewsTag(storyId, playMode, false);
      }      
      
      setActiveFeature(null);   
    }
  }        

  const setFullScreen = (url, fullScreen) => {
    if (fullScreen){
      setisFullScreenOpened(true);
      setMediaFullScreenOpened(url);            
    }
    else {
      setisFullScreenOpened(false);      
    }
  };

  const fullScreenMediaFormat = getFileFormat(getFileNameFromUrl(mediaFullScreenOpened));

  // in StoryVideo ??
  function handleResize(tryAgain = true) {     
    setWindowDimensions(getWindowDimensions()); 
    // handle resize not well taken into account by instagram in app browser on ios when scrolling at edges
    if (tryAgain && isInstagramInAppBrowser() && isIOS()) 
      setTimeout(()=>handleResize(false), 500);
  }

  useEffect(() => {    
    if (! props.fromMaker && ! props.fromDemo && ! playerOnDesktop){
      window.addEventListener('resize', handleResize);

      setTimeout(()=>handleResize(false), 1000);
      setTimeout(()=>handleResize(false), 2000);
      setTimeout(()=>handleResize(false), 4000); 
    }              

    // HP phone bg prevent the click to happen on mobile
    if (isTabletOrMobile && props.fromDemo === "top" && document.getElementById("hp-phonebg-top") != null){
      document.getElementById("hp-phonebg-top").addEventListener('click', manageClickOnRoot);
    }
    else if (isTabletOrMobile && props.fromDemo === "bottom" && document.getElementById("hp-phonebg-bottom") != null){
      document.getElementById("hp-phonebg-bottom").addEventListener('click', manageClickOnRoot);
    }
  
    return () => {     
      if (isTabletOrMobile && props.fromDemo === "top" && document.getElementById("hp-phonebg-top") != null){
        document.getElementById("hp-phonebg-top").removeEventListener('click', manageClickOnRoot);
      }
      else if (isTabletOrMobile && props.fromDemo === "bottom" && document.getElementById("hp-phonebg-bottom") != null){
        document.getElementById("hp-phonebg-bottom").removeEventListener('click', manageClickOnRoot);
      }
      if (! props.fromMaker && ! props.fromDemo && ! playerOnDesktop)
        window.removeEventListener('resize', handleResize);
    };

  }, []);

  function manageClickOnRoot(){        
    if (!props.fromMaker && !props.fromDemo && !playerOnDesktop){             
      if (!audioAlreadyDisabled && !playAudio){        
        const ambiantPlayer = document.getElementById("audio-player");
        const volumeBtnOn = document.getElementById("story-volume-btn-on");
        const volumeBtnOff = document.getElementById("story-volume-btn-off");
        if (ambiantPlayer != null && volumeBtnOn != null && volumeBtnOff != null){ 
          if (ambiantPlayer.paused){
            try {
              ambiantPlayer.play();
            }
            catch (e){}
          }
          volumeBtnOff.style.visibility = "visible";
          volumeBtnOn.style.visibility = "hidden";                    
        }
        setPlayAudio(true);
      }
      openFullscreen();
    }

    setStoryClicked1Time(true);
    setPlayInteractiveAnimation(false);    
    if (playInteractiveAnimation){
      // on demo play sticker animation after ending the touch to play one
      setStickerOpeningPlayed(false);
    }
    if (document.getElementsByClassName("MediasGalleryContainer") == null || document.getElementsByClassName("MediasGalleryContainer").length === 0){
      checkBgVideoPlaying();
    }            
  }
  
  // can be delayed and not finished on the first appAnalytics event (rare) -> if on every call
  useEffect(() => {    
    if (story == null || appAnalyticsManager.current != null) return;

    getAuth().authStateReady().then(async () =>{        
      let installId = await getId(getInstallations(app)).catch((e)=>Sentry.captureMessage("get firebase installation id: " + printObj(e)));
      let userTraceable = true;
      if (installId == null){              
        installId = Math.random() * 10000000000;
        userTraceable = false;
      }
         
      let play_mode = "play";
      if (props.fromDemo){
        play_mode = "demo";
      } else if (props.fromMaker){
        play_mode = "preview";
      }
      else if (getAuth().currentUser != null){          
        play_mode = "review";
      }   
      setPlayMode(play_mode);        
      
      const clientId = getAuth().currentUser == null ? installId : getAuth().currentUser.uid;

      if (userTraceable)
        analyticsManager.setUserId(clientId);

      analyticsManager.logPlayStoryScreenViewTag(storyId, play_mode, window.location.href.includes("fbclid"));
                  
      appAnalyticsManager.current = new AppAnalyticsManager(storyId, story.preview || play_mode !== "play", story.uid, clientId);     
      appAnalyticsManager.current.init();        
      appAnalyticsManager.current.updateViewNb(); 
    });
      
  }, [story]);

  useEffect(() => {         
    fetchStory();
  }, []);

  useEffect(() => {
    if (appAnalyticsManager.current != null){
      updateVisitCounter();
    }

    async function updateVisitCounter(){
      setTimeout(() => {   
        appAnalyticsManager.current.visitDurationCounter();
        if (appAnalyticsManager.current.totalDuration < 30){
          updateVisitCounter();
        }
      }, 2000);
    }
    
  }, [appAnalyticsManager.current != null]);
  
  useEffect(() => {
    if (props.featureReset != null && props.featureReset){
      setActiveFeature(null);
      props.setFeatureReset(false);
    }
  }, [props.featureReset]);

  useEffect(() => {
    if (props.resetStickerOutOfScreen){
      setStickersOutOfScreen(false);
      props.setResetStickerOutOfScreen(false);
    }
  }, [props.resetStickerOutOfScreen]);

  useEffect(() => {
    if (props.highlight != null){
        setTimeout(() => {props.setHighlight(null)}, 2000);
    }
  }, [props.highlight]);

  useEffect(() => {

    function handleOrientationChange(e){      
      setTimeout(() => handleResize(false), 200);
    }

    if (! checkMobile()) return;

    if (window.screen != null && window.screen.orientation != null)
      window.screen.orientation.addEventListener("change", handleOrientationChange);

    return () => {      
      if (window.screen != null && window.screen.orientation != null)
        window.screen.orientation.removeEventListener('change', handleOrientationChange);
    };
    
  }, []);

  let touchToPlayControls = useRef(null); 
  let insideControls = useRef(null);
  let menuControls = useRef(null);

  function showInteractiveAnimation(){
    if (playInteractiveAnimation ){      
      return (
        <img id="touch-to-play" src="images/ic_touch_app.png" style={{width: 0, height: 0, position: 'absolute', transform: "translate(-50%, -50%)",top: '50%',left: '50%', cursor: "pointer"}} />
      );
    }
    else {
      if (touchToPlayControls.current != null && insideControls.current != null && menuControls.current != null){
        touchToPlayControls.current.cancel();
        touchToPlayControls.current = null;
        // does not work (animation continue to play, only visible when clicking the share button during the animation)
        insideControls.current.cancel();
        insideControls.current = null;
        // same
        menuControls.current.cancel();
        menuControls.current = null;   
        const touchToPlay = document.getElementById("touch-to-play");
        if (touchToPlay != null){
          touchToPlay.style.display = "none";
        }
        const insideSticker = document.getElementById("inside");
        if (insideSticker != null){
          insideSticker.style.opacity = 1;          
        }
        const menuSticker = document.getElementById("menu");
        if (menuSticker != null){
          menuSticker.style.opacity = 1;
        }
      }
      return null;
    }
  }

  useEffect(() => {    
    if (playInteractiveAnimation && story != null && storyDimensions != undefined){            
      setTimeout(() => playAnimation(), 5000);
    }
  }, [playInteractiveAnimation && story != null && storyDimensions != undefined]);

  function playAnimation(){
    const touchToPlay = document.getElementById("touch-to-play");
      const insideSticker = document.getElementById("inside");
      const menuSticker = document.getElementById("menu");

      if (touchToPlay != null && insideSticker != null && menuSticker != null && !storyClicked1Time){  
        // use of width over scale (scale does not work with translate centering)
        const touchSequence = [  
          [touchToPlay, {opacity: 0}, {duration: 0.1}],
          [touchToPlay, {width: 192, height: 192}, {duration: 0.1}],
          [touchToPlay, {opacity: 1}, {duration: 0.6}],     
          [touchToPlay, { width: 192, height: 192, }, {ease: "easeOut", duration: 0.6}],
          [touchToPlay, { width: 96, height: 96}, {ease: "easeIn", duration: 0.6}],
          [touchToPlay, { width: 192, height: 192}, {ease: "easeOut", duration: 0.6}],
          [touchToPlay, { width: 96, height: 96}, {ease: "easeIn", duration: 0.6}],
          [touchToPlay, { width: 192, height: 192}, {ease: "easeOut", duration: 0.6}],              
          [touchToPlay, {opacity: 0}, {duration: 0.1}],
        ];
        touchToPlayControls.current = animate(touchSequence);

        const insideSequence = [
          [insideSticker, {opacity: 0}, {duration: 0.6}], 
          [insideSticker, {scale: 1}, {duration: 3.5}], 
          [insideSticker, {opacity: 1}, {duration: 0.1}]
        ];
        insideControls.current = animate(insideSequence);            

        const menuSequence = [
          [menuSticker, {opacity: 0}, {duration: 0.6}],
          [menuSticker, {scale: 1}, {duration: 3.5}],
          [menuSticker, {opacity: 1}, {duration: 0.1}]
        ];
        menuControls.current = animate(menuSequence);    
        
        setTimeout(() => playAnimation(), 8000);
      }
  }

  if (story == null || storyDimensions == undefined){     
    return (
      
      <div className="App">
        <div className="StoryVideoContainer" style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>          
          <img src="/images/sparkles.gif" className="Sparkles" width="220px"/>
        </div>
      </div>
    );
  }
  /*else if (demoStory){
    // sandbox to test components in isolation

    return (
      <>
        <div className="StoryVideoContainer" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}}>
          <PlayerContext.Provider value={new Map([["windowDimensions", windowDimensions], ["storyDimensions", storyDimensions], ["miniMultiplier", miniMultiplier], ["propagateFocusChange", propagateFocusChange], ["setFullScreen", setFullScreen], ["pauseVideo", pauseVideo], ["activeFeature", activeFeature], ["setActiveFeature", setActiveFeature], ["menuColor", story.menuColor], ["primaryColor", story.primaryColor], ["secondaryColor", story.secondaryColor], ["appAnalyticsManager", appAnalyticsManager.current], ["fromMaker", props.fromMaker], ["playMode", playMode], ["highlight", props.highlight], ["setHighlight", props.setHighlight]])}>
            
          </PlayerContext.Provider>
        </div>
      </>
    );
  }*/
 else {
  const showInfos = activeFeature == null;  
  const classVideo = props.fromMaker && ! isTabletOrMobile || props.fromDemo ? "StoryVideoContainerPreview" : "StoryVideoContainer";
  
    if (! playerOnDesktop){

      return (
              <div className="App">  
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="100%" height="100%" style={{position: 'absolute'}} onClick={()=>propagateFocusChange(true)}/>                
                <div onClick={()=>manageClickOnRoot()}>
                  {!props.fromDemo && story.musicUrl && <audio id="audio-player" ref={audioRef} preload="none" loop src={story.musicUrl}/>}            
                  <PlayerContext.Provider value={new Map([["windowDimensions", windowDimensions], ["storyDimensions", storyDimensions], ["miniMultiplier", miniMultiplier], ["propagateFocusChange", propagateFocusChange], ["setFullScreen", setFullScreen], ["pauseVideo", pauseVideo], ["activeFeature", activeFeature], ["setActiveFeature", setActiveFeature], ["menuColor", story.menuColor], ["primaryColor", story.primaryColor], ["secondaryColor", story.secondaryColor], ["appAnalyticsManager", appAnalyticsManager.current], ["fromMaker", props.fromMaker], ["playMode", playMode], ["highlight", props.highlight], ["setHighlight", props.setHighlight], ["fromDemo", props.fromDemo], ["playInteractiveAnimation", playInteractiveAnimation], ["setPlayInteractiveAnimation", setPlayInteractiveAnimation], ["stickerOpeningPlayed", stickerOpeningPlayed], ["setStickerOpeningPlayed", setStickerOpeningPlayed], ["playAudio", playAudio], ["resetAudio", props.resetAudio], ["setResetAudio", props.setResetAudio], ["setPlayAudio", setPlayAudio], ["audioAlreadyDisabled", audioAlreadyDisabled], ["setAudioAlreadyDisabled", setAudioAlreadyDisabled], ["playerOnDesktop", playerOnDesktop], ["menuStickerClicked", menuStickerClicked], ["setMenuStickerClicked", setMenuStickerClicked], ["insideStickerClicked", insideStickerClicked], ["setInsideStickerClicked", setInsideStickerClicked], ["setShareButtonAlreadyHighlighted", setShareButtonAlreadyHighlighted], ["shareButtonAlreadyHighlighted", shareButtonAlreadyHighlighted]])}>                  
                    <div className={classVideo} style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight }}>                
                        <SideMenu storyId={storyId} story={story} placeDetails={placeDetails}/>
                        <StoryVideo storyId={storyId} story={story} setVideoLoaded={props.setVideoLoaded} fromDemo={props.fromDemo}/>    
                        <StickerContext.Provider value={new Map([["activeDrag", activeDrag], ["setActiveDrag", setActiveDrag], ["setStickerDrop", props.setStickerDrop], ["menuStickerDimensions", menuStickerDimensions], ["setMenuStickerDimensions", setMenuStickerDimensions], ["setInsideStickerDimensions", setInsideStickerDimensions], ["insideStickerDimensions", insideStickerDimensions], ["snackSwipeAlreadyDisplayed", snackSwipeAlreadyDisplayed], ["setSnackSwipeAlreadyDisplayed", setSnackSwipeAlreadyDisplayed], ["stickersOutOfScreen", stickersOutOfScreen], ["setStickersOutOfScreen", setStickersOutOfScreen], ["stickersDraggable", props.stickersDraggable], ["menuTitleFont", menuTitleFont], ["menuTextFont", menuTextFont],])}>
                          <StickerInside selectedItem={mediaFullScreenOpened} placeDetails={placeDetails} storyId={storyId} story={story} setFullScreen={setFullScreen}/>                          
                          <StickerGallery selectedItem={mediaFullScreenOpened} storyId={storyId} story={story} placeDetails={placeDetails} mediasType={menuType} medias={story.mediasRef} setFullScreen={setFullScreen} fromMaker={props.fromMaker} setStoryPreviewCurrentScreen={props.setStoryPreviewCurrentScreen}/>                                                                                         
                        </StickerContext.Provider>
                        <RestaurantInfos show={showInfos} story={story} storyId={storyId} placeDetails={placeDetails}/>   
                        {showInteractiveAnimation()}        
                        {!placeDetails && <img src="/images/sparkles.gif" className="Sparkles" width="220px"/>}              
                    </div>   
                    <FullScreenMediaPlayer isFullScreenOpened={isFullScreenOpened} mediaFullScreenOpened={mediaFullScreenOpened} fullScreenMediaFormat={fullScreenMediaFormat} setFullScreen={setFullScreen} btnColor={story.secondaryColor}/>                           
                  </PlayerContext.Provider>    
                </div>            
              </div>
            );  
    }  
    else { 
      const containerHeight = windowDimensions.height + 40;
      const doublePhoneContainerHeight = windowDimensions.height + 20;
      const rightPhoneContainerHeight = windowDimensions.height + 32;

      const restaurantType = placeDetails && placeDetails.primaryTypeDisplayName && placeDetails.primaryTypeDisplayName.text;

      let typeAddressSeparator = "";
      if (getTextWidth(restaurantType + " · " + story.posArea, "16px Roboto") > 372 - 2 * 16 ){        
        typeAddressSeparator = "\n";        
      }
      else if (restaurantType != null && restaurantType.length > 0){
        typeAddressSeparator = " · ";
      }

      let address = story.posArea;
      if (story.posArea != null && story.posArea.length > 0){        
        address = story.posArea;
      }
      else {
        address = story.city;
      }


      return(        

        <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)"}}>
          <div style={{width: 840, height: containerHeight, display: "flex", flexDirection: 'row', justifyContent: 'center'}}>

            <div style={{width: 380, height: doublePhoneContainerHeight, position: 'relative', borderRadius: 30, padding: 6, background: 'white'}}>              
              {/* should stay same as 1st else */}
              <div className="App" onClick={()=>manageClickOnRoot()}>                  
              {!props.fromDemo && story.musicUrl && <audio id="audio-player" ref={audioRef} preload="none" loop src={story.musicUrl}/>}
                <PlayerContext.Provider value={new Map([["windowDimensions", windowDimensions], ["storyDimensions", storyDimensions], ["miniMultiplier", miniMultiplier], ["propagateFocusChange", propagateFocusChange], ["setFullScreen", setFullScreen], ["pauseVideo", pauseVideo], ["activeFeature", activeFeature], ["setActiveFeature", setActiveFeature], ["menuColor", story.menuColor], ["primaryColor", story.primaryColor], ["secondaryColor", story.secondaryColor], ["appAnalyticsManager", appAnalyticsManager.current], ["fromMaker", props.fromMaker], ["playMode", playMode], ["highlight", props.highlight], ["setHighlight", props.setHighlight], ["fromDemo", props.fromDemo], ["playInteractiveAnimation", playInteractiveAnimation], ["setPlayInteractiveAnimation", setPlayInteractiveAnimation], ["stickerOpeningPlayed", stickerOpeningPlayed], ["setStickerOpeningPlayed", setStickerOpeningPlayed],  ["playAudio", playAudio], ["resetAudio", props.resetAudio], ["setResetAudio", props.setResetAudio],["setPlayAudio", setPlayAudio], ["audioAlreadyDisabled", audioAlreadyDisabled], ["setAudioAlreadyDisabled", setAudioAlreadyDisabled], ["playerOnDesktop", playerOnDesktop], ["menuStickerClicked", menuStickerClicked], ["setMenuStickerClicked", setMenuStickerClicked], ["insideStickerClicked", insideStickerClicked], ["setInsideStickerClicked", setInsideStickerClicked], ["setShareButtonAlreadyHighlighted", setShareButtonAlreadyHighlighted], ["shareButtonAlreadyHighlighted", shareButtonAlreadyHighlighted]], )}>                                    
                  <div className={classVideo} style={{zIndex: 100, backgroundColor: "transparent",width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, borderRadius: 10, borderColor: 'white', borderWidth: 4, borderStyle: 'solid', pointerEvents: 'none'}}/>
                  <div className={classVideo} style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, /* addition to tablet */ }}>                
                      <SideMenu storyId={storyId} story={story} placeDetails={placeDetails}/>
                      <StoryVideo storyId={storyId} story={story} setVideoLoaded={props.setVideoLoaded} fromDemo={props.fromDemo}/>    
                      <StickerContext.Provider value={new Map([["activeDrag", activeDrag], ["setActiveDrag", setActiveDrag], ["setStickerDrop", props.setStickerDrop], ["menuStickerDimensions", menuStickerDimensions], ["setMenuStickerDimensions", setMenuStickerDimensions], ["setInsideStickerDimensions", setInsideStickerDimensions], ["insideStickerDimensions", insideStickerDimensions], ["snackSwipeAlreadyDisplayed", snackSwipeAlreadyDisplayed], ["setSnackSwipeAlreadyDisplayed", setSnackSwipeAlreadyDisplayed], ["stickersOutOfScreen", stickersOutOfScreen], ["setStickersOutOfScreen", setStickersOutOfScreen], ["stickersDraggable", props.stickersDraggable], ["menuTitleFont", menuTitleFont], ["menuTextFont", menuTextFont], ])}>
                        <StickerInside selectedItem={mediaFullScreenOpened} placeDetails={placeDetails} storyId={storyId} story={story} setFullScreen={setFullScreen}/>                          
                        <StickerGallery selectedItem={mediaFullScreenOpened} storyId={storyId} story={story} placeDetails={placeDetails} mediasType={menuType} medias={story.mediasRef} setFullScreen={setFullScreen} fromMaker={props.fromMaker} setStoryPreviewCurrentScreen={props.setStoryPreviewCurrentScreen}/>                                                                                         
                      </StickerContext.Provider>
                      <RestaurantInfos show={showInfos} story={story} storyId={storyId} placeDetails={placeDetails}/>   
                      {showInteractiveAnimation()}    
                      {!placeDetails && <img src="/images/sparkles.gif" className="Sparkles" width="220px"/>}                    
                  </div>  
                  <FullScreenMediaPlayer isFullScreenOpened={isFullScreenOpened} mediaFullScreenOpened={mediaFullScreenOpened} fullScreenMediaFormat={fullScreenMediaFormat} setFullScreen={setFullScreen} btnColor={story.secondaryColor}/>         
                </PlayerContext.Provider>                
              </div>
              {/* END */}
              
            </div>

            <div style={{width: 372, height: rightPhoneContainerHeight, marginLeft: 40, backgroundColor: 'white', borderRadius: 30, display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
              <div style={{marginTop: -100}}>
                <div style={{width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center'}}>
                  <div id="restaurant-infos-avatar" name="restaurant-infos-avatar">
                    <img alt="restaurant's avatar" src={story.avatarUrl != null ? story.avatarUrl : "/images/avatar_placeholder.png"} className="FBAvatarContainer" style={{background: '#FFFFFF', width: 96, height: 96}} />                                    
                  </div>
                  <div style={{marginTop: 10, fontSize: 20, paddingLeft: 16, paddingRight: 16, textAlign: 'center', fontWeight: "bold"}}>{placeDetails != null ? (placeDetails.displayName.text !== "Holberton School Paris" ? placeDetails.displayName.text : "Holberton School") : ""}</div>
                  <div style={{marginTop: 10, fontSize: 16, paddingLeft: 16, paddingRight: 16, textAlign: 'center'}}>{typeAddressSeparator === "\n" ? <><br/></> : ""}{restaurantType}{typeAddressSeparator === "\n" ? <><br/><br/></> : typeAddressSeparator}{address}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      );
    }
  }

  function checkBgVideoPlaying(){         
    if (document.getElementById(idPrefix + "bgVideo") != null){
      const video = document.getElementById(idPrefix + "bgVideo");
      if (!(storyId === "WX2KsZRK4SNKT9hnNIp0" || storyId === "cJU0Fnom8MTjmokacI3I" || storyId === "ABDe0HTpOfX5iVYDqrcJ" || storyId === "LnSX2qKrthKsRmr1vcxc" || storyId === "PaBK2iq1At5um1V6nNGo" || storyId === "1iDXY4hOyo1PVyrvCmvG" || storyId === "4WlhflMBRftrhBabhzqL" || storyId === "G4uGdmkDklxxMumN9FIc" || storyId === "LQ8cYZF1BrqCbKYOI7g7" || storyId === "P4hss0ADGtE2wMY5fcrQ" || storyId === "YPuRVqGYO2Q2QFKEkRvQ" || storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || storyId === "clexvJMGmwlGXjY8KLaT" || storyId === "nRyYRVND1xh34m32eGMa" || storyId === "rj1ZbTjACKeCMOqqAi4l" || storyId === "wfnhdxPJPy3v54dXJAr4" || storyId === "xlqSrGF4RJz9yfV2MQrr" || storyId === "zUEpyuVwo5Tws3hnvFds")){
        video.play();
      }
      else {
        if (!isIOS() && video.muted && audioFromBgVideoOn)
          fadeInVolume(video);
        else 
          video.play();
      }
    }
    else if (document.getElementById(idPrefix + "bgVideoContainer") != null && document.getElementById(idPrefix + "bgVideoContainer").children[0] != null){
      const video = document.getElementById(idPrefix + "bgVideoContainer").children[0];
      if (!(storyId === "WX2KsZRK4SNKT9hnNIp0" || storyId === "cJU0Fnom8MTjmokacI3I" || storyId === "ABDe0HTpOfX5iVYDqrcJ" || storyId === "LnSX2qKrthKsRmr1vcxc" || storyId === "PaBK2iq1At5um1V6nNGo" || storyId === "1iDXY4hOyo1PVyrvCmvG" || storyId === "4WlhflMBRftrhBabhzqL" || storyId === "G4uGdmkDklxxMumN9FIc" || storyId === "LQ8cYZF1BrqCbKYOI7g7" || storyId === "P4hss0ADGtE2wMY5fcrQ" || storyId === "YPuRVqGYO2Q2QFKEkRvQ" || storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || storyId === "clexvJMGmwlGXjY8KLaT" || storyId === "nRyYRVND1xh34m32eGMa" || storyId === "rj1ZbTjACKeCMOqqAi4l" || storyId === "wfnhdxPJPy3v54dXJAr4" || storyId === "xlqSrGF4RJz9yfV2MQrr" || storyId === "zUEpyuVwo5Tws3hnvFds")){
        video.play();
      }
      else {
        if (!isIOS() && video.muted && audioFromBgVideoOn)
          fadeInVolume(video);
        else 
          video.play();
      }
    }
  }  
  
  function pauseVideo(){     
    if (document.getElementById(idPrefix + "bgVideo") != null){      
      const video = document.getElementById(idPrefix + "bgVideo");
      if (!(storyId === "WX2KsZRK4SNKT9hnNIp0" || storyId === "cJU0Fnom8MTjmokacI3I" || storyId === "ABDe0HTpOfX5iVYDqrcJ" || storyId === "LnSX2qKrthKsRmr1vcxc" || storyId === "PaBK2iq1At5um1V6nNGo" || storyId === "1iDXY4hOyo1PVyrvCmvG" || storyId === "4WlhflMBRftrhBabhzqL" || storyId === "G4uGdmkDklxxMumN9FIc" || storyId === "LQ8cYZF1BrqCbKYOI7g7" || storyId === "P4hss0ADGtE2wMY5fcrQ" || storyId === "YPuRVqGYO2Q2QFKEkRvQ" || storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || storyId === "clexvJMGmwlGXjY8KLaT" || storyId === "nRyYRVND1xh34m32eGMa" || storyId === "rj1ZbTjACKeCMOqqAi4l" || storyId === "wfnhdxPJPy3v54dXJAr4" || storyId === "xlqSrGF4RJz9yfV2MQrr" || storyId === "zUEpyuVwo5Tws3hnvFds")){        
        try {
          video.pause();
        }
        catch (e){}
      }
      else {        
        if (!isIOS() && !video.muted && audioFromBgVideoOn)        
          fadeOutVolume(video);
        else
          try {
            video.pause();
          }
          catch (e){}
      }
    }
    else if (document.getElementById(idPrefix + "bgVideoContainer") != null && document.getElementById(idPrefix + "bgVideoContainer").children[0] != null){   
      const video = document.getElementById(idPrefix + "bgVideoContainer").children[0];
      if (!(storyId === "WX2KsZRK4SNKT9hnNIp0" || storyId === "cJU0Fnom8MTjmokacI3I" || storyId === "ABDe0HTpOfX5iVYDqrcJ" || storyId === "LnSX2qKrthKsRmr1vcxc" || storyId === "PaBK2iq1At5um1V6nNGo" || storyId === "1iDXY4hOyo1PVyrvCmvG" || storyId === "4WlhflMBRftrhBabhzqL" || storyId === "G4uGdmkDklxxMumN9FIc" || storyId === "LQ8cYZF1BrqCbKYOI7g7" || storyId === "P4hss0ADGtE2wMY5fcrQ" || storyId === "YPuRVqGYO2Q2QFKEkRvQ" || storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || storyId === "clexvJMGmwlGXjY8KLaT" || storyId === "nRyYRVND1xh34m32eGMa" || storyId === "rj1ZbTjACKeCMOqqAi4l" || storyId === "wfnhdxPJPy3v54dXJAr4" || storyId === "xlqSrGF4RJz9yfV2MQrr" || storyId === "zUEpyuVwo5Tws3hnvFds")){
        try {
          video.pause();
        }
        catch (e){}
      }
      else {    
        if (!isIOS() && !video.muted && audioFromBgVideoOn)    
          fadeOutVolume(video);
        else
          try {
            video.pause();
          }
          catch (e){}
      }
    }
  }
}

function requestAnimFrameCompat() {
  return window.requestAnimationFrame ||
         window.webkitRequestAnimationFrame ||
         window.mozRequestAnimationFrame ||
         window.oRequestAnimationFrame ||
         window.msRequestAnimationFrame ||
         function(callback) {
           // Fallback: utilise `setTimeout` avec un intervalle d'environ 16ms (pour 60fps)           
           return window.setTimeout(callback, 1000 / 60);
         };
};

export function fadeOutVolume(video, requestAnimeFrameFunction = requestAnimFrameCompat) {  
  const fadeStep = () => {
    if (video.volume > 0) {
      video.volume = Math.max(0, video.volume - 0.03);      
      try {
        requestAnimeFrameFunction()(fadeStep);      
      }
      catch (e){        
        video.muted = true;
        video.pause();
      }
    } else {      
      video.muted = true;
      video.pause();      
    }
  };
  fadeStep();
}

export function fadeInVolume(video, requestAnimeFrameFunction = requestAnimFrameCompat) {
  const fadeStep = () => {
    if (video.volume < 1) {
      video.volume = Math.min(1, video.volume + 0.03);
      try{
        requestAnimeFrameFunction()(fadeStep);            
      }
      catch (e){        
        video.muted = false;
        video.play();
        const volumeBtnOn = document.getElementById("story-volume-btn-on");
        const volumeBtnOff = document.getElementById("story-volume-btn-off");
        if (volumeBtnOn != null && volumeBtnOff != null){           
          volumeBtnOff.style.visibility = "visible";
          volumeBtnOn.style.visibility = "hidden";                    
        }
      }
    } else {
      video.muted = false;
      video.play();
      const volumeBtnOn = document.getElementById("story-volume-btn-on");
      const volumeBtnOff = document.getElementById("story-volume-btn-off");
      if (volumeBtnOn != null && volumeBtnOff != null){           
        volumeBtnOff.style.visibility = "visible";
        volumeBtnOn.style.visibility = "hidden";                    
      }
    }
  };
  fadeStep();
}

export function unmuteVideo(){ 
  if (document.getElementById("bgVideo") != null){
    document.getElementById("bgVideo").muted = false;
    //fadeInVolume(document.getElementById("bgVideo"));
  }
  else if (document.getElementById("bgVideoContainer") != null && document.getElementById("bgVideoContainer").children[0] != null){
    document.getElementById("bgVideoContainer").children[0].muted = false;
    //fadeInVolume(document.getElementById("bgVideoContainer").children[0]);
  }
}

export function muteVideo(){ 
  if (document.getElementById("bgVideo") != null){
    document.getElementById("bgVideo").muted = true;
    //fadeOutVolume(document.getElementById("bgVideo"));
  }
  else if (document.getElementById("bgVideoContainer") != null && document.getElementById("bgVideoContainer").children[0] != null){         
    document.getElementById("bgVideoContainer").children[0].muted = true;
    //fadeOutVolume(document.getElementById("bgVideoContainer").children[0]);
  }
}

export function isVideoMuted(){ 
  if (document.getElementById("bgVideo") != null){
    return document.getElementById("bgVideo").muted;
  }
  else if (document.getElementById("bgVideoContainer") != null && document.getElementById("bgVideoContainer").children[0] != null){
    return document.getElementById("bgVideoContainer").children[0].muted;
  }
}

export function getBackgroundVideoElement(){ 
  if (document.getElementById("bgVideo") != null){
    return document.getElementById("bgVideo");
  }
  else if (document.getElementById("bgVideoContainer") != null && document.getElementById("bgVideoContainer").children[0] != null){
    return document.getElementById("bgVideoContainer").children[0];
  }
}

export default Player;
