import './../../Player.css';
import React, {useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Image} from 'react-native';
import { PlayerContext } from '../../PlayerContext';
import { lighterColor } from '../../Utils';
import { useAnimate } from 'framer-motion';
import { setStoryClicked1Time } from '../../Player';
import { ButtonBase, styled } from '@mui/material';

export const hoursType = "hours";
export const reviewsType = "reviews";
export const locationType = "location";

function SecondaryButton(props){
    const { t } = useTranslation();
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const primaryColor = useContext(PlayerContext).get("primaryColor");
    const highlight = useContext(PlayerContext).get("highlight");
    const setHighlight = useContext(PlayerContext).get("setHighlight");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier"); 
    const setPlayInteractiveAnimation = useContext(PlayerContext).get("setPlayInteractiveAnimation");
    const [scope, animate] = useAnimate();

    let icon = "#";
    let x = storyDimensions.containerWidth + (- 36 - 8 - 8 ) * miniMultiplier;
    let y = 0;
    
    let buttonText = "";
    switch (props.buttonType){
        case hoursType: 
            icon = "/images/ic_access_time.png";        
            y = storyDimensions.containerHeight + (- 8 - 38 - 8 - 38 - 32 - 36 - 16 - 36 - 16 - 36) * miniMultiplier;
            buttonText = t("Hours").toLowerCase();
            break;
        case reviewsType: 
            icon = "/images/ic_star_half.png";
            y = storyDimensions.containerHeight + (- 8 - 38 - 8 - 38 - 32 - 36 - 16 - 36) * miniMultiplier;
            buttonText = t("Reviews");
            break;
        case locationType: 
            icon = "/images/ic_location_on.png";
            y = storyDimensions.containerHeight + (- 8 - 38 - 8 - 38 - 32 - 36) * miniMultiplier;
            buttonText = t("Location");
            break;
        default:
            break;
    }

    useEffect(() => {
        if (highlight != null){

            let elementId = null;
            if (highlight.includes("hours") && props.buttonType === hoursType){
                elementId = "secondary-btn-hours";                
            }
            else if (highlight.includes("reviews") && props.buttonType === reviewsType){                
                elementId = "secondary-btn-reviews";                
            }
            else if (highlight.includes("location") && props.buttonType === locationType){
                elementId = "secondary-btn-location";
            }

            const element = document.getElementById(elementId);
            if (elementId != null && element != null){
                const animation = async () => {
                    await animate(element, { background: "#FFFFFFFF"}, {duration: 0.6});
                    if (element != null)
                        animate(element, { background: "#FFFFFF00"}, {duration: 0.6});                    
                }                
                animation();
            }
        }
      }, [highlight]);

    function open(e){
        e.stopPropagation();
        setStoryClicked1Time(true);
        setPlayInteractiveAnimation(false);
        props.open();
    }

    // TODO click zone to 40
    
    const RoundButton = styled(ButtonBase)(({ theme }) => ({
        color: props.color,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: "#FFFFFF80", 
        },
    }));

    return (
        <RoundButton ref={scope} id={"secondary-btn-"+props.buttonType} name={"secondary-btn-"+props.buttonType} sx={{position: "absolute", zIndex: 10, color: 'white', borderRadius: 18, width: 44 * miniMultiplier, height: 44 * miniMultiplier, left: x, top: y, cursor: "pointer", display: 'flex', flexDirection: 'column', alignItems: 'center',}} onClick={open}>            
            <img alt={buttonText} src={icon} style={{width: 24 * miniMultiplier, height: 24 * miniMultiplier, pointerEvents: 'none'}} />
            <div style={{fontSize: 12 * miniMultiplier, color: 'white'}}>{buttonText}</div>
        </RoundButton>
    );
}

export default SecondaryButton;