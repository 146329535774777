import React, {useState, useEffect, useContext, useRef} from 'react';
import { getFirestore, doc, updateDoc, deleteField, arrayUnion, arrayRemove, getDoc, } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { getFileNameFromUrl, isImg, isVideo, getFileFormat, fetchImageSize, isFirefox, isIOS, isSafari, browserLocales} from '../player/Utils';
import {MakerContext} from "./MakerContext";
import './Maker.css';
import '../player/Player.css';
import { menuType, insideType } from '../player/overlay/stickers/StickerGallery';
import MaterialButton from './component/MaterialButton';
import MobileMenu from './component/MobileMenu';
import { useMediaQuery } from 'react-responsive';
import { deleteExistingMediaContent, MEDIA_TYPE_INSIDE_PREVIEW, MEDIA_TYPE_MENU_PREVIEW, deleteInsideMediaDB, cleanMakerQueryString, MEDIA_TYPE_MENU_FR, MEDIA_TYPE_MENU_EN } from './MakerController';
import ContentPickerDialog from './component/ContentPickerDialog';
import { analyticsManager } from '..';
import { AppContext } from '../AppContext';
import MobileBottomBar from './component/MobileBottomBar';
import useInterval from './component/UseInterval';
import { Snackbar, } from '@mui/material';
import MenuStylePreview from './component/MenuStylePreview';

function ChooseStickersForm(props) { 
    const [showPicker, setShowPicker] = useState(false);   
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const baseStory = useContext(MakerContext).get("baseStory");
    const setActiveForm = useContext(MakerContext).get("setActiveForm");
    const activePopup = useContext(MakerContext).get("activePopup");
    const setActivePopup = useContext(MakerContext).get("setActivePopup");
    const stickerDrop = useContext(MakerContext).get("stickerDrop");
    const setStickerDrop = useContext(MakerContext).get("setStickerDrop");   
    const setFeatureReset = useContext(MakerContext).get("setFeatureReset"); 
    const [touchStartElementPosition, setTouchStartElementPosition] = useState(-1);    
    const [touchStartMediaType, setTouchStartMediaType] = useState(null);
    const setHighlight = useContext(MakerContext).get("setHighlight");
    const [selectedMenuContents, setSelectedMenuContents] = useState([]);
    const [selectedInsideContents, setSelectedInsideContents] = useState([]);
    const [bypassContentPickerLogin, setBypassContentPickerLogin] = useState(false);   
    const [showMenuStyle, setShowMenuStyle] = useState(false);
    const instagramLoginType = useContext(MakerContext).get("instagramLoginType");
    const touchStartPositionRef = useRef(null);
    const dragTime = useRef(0);
    const [optimizedFormat, setOptimizedFormat] = useState(null);    
    const [showInfoCreateAccount, setShowInfoCreateAccount] = useState(false);
    const [refreshStyle, setRefreshStyle] = useState(null);

    const windowDimensions = useContext(MakerContext).get("windowDimensions");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const stickerMoveAreaWidth = isTabletOrMobile ? windowDimensions.width : 360.0;
    const stickerMoveAreaHeight = isTabletOrMobile ? windowDimensions.height : makerDesktopHeight;

    const { t } = useTranslation();

    useEffect(() => {
        if (props.show && (activePopup === MEDIA_TYPE_INSIDE_PREVIEW || activePopup === MEDIA_TYPE_MENU_PREVIEW)){            
            setShowPicker(activePopup);
            analyticsManager.logOpenPopupTag(storyId, "content_picker_" + activePopup, true);
            setBypassContentPickerLogin(true);
            setActivePopup(null);            
            if (instagramLoginType === "ig")
                cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
        }
      }, [activePopup && props.show]);

      const changeMenuStyleTemp = useRef();

    // iOS: menu preview does not refresh if we dont change the style -> we change the style and then we change it back for color change
    // TODO do not refresh style at start
    useEffect(() => {        
        if (props.show && (isIOS() || isSafari()) && story != null && story.menuColor != null){
            changeMenuStyleTemp.current = story.menuStyle;
            if (story.menuStyle === "simple"){
                setRefreshStyle("elegant");                    
            }
            else {
                setRefreshStyle("simple");
            }  
        }
    }, [props.show && (isIOS() || isSafari()) && story != null && optimizedFormat && story.menuColor]);

    useInterval(() => {
        if (changeMenuStyleTemp.current != null){
            setRefreshStyle(changeMenuStyleTemp.current);
            changeMenuStyleTemp.current = null;
        }
        else if (refreshStyle != null){
            setRefreshStyle(null);
        }
    }, props.show && (isIOS() || isSafari()) && (changeMenuStyleTemp.current != null || refreshStyle != null) ? 200 : null);

    useEffect(() => {
        if (story != null){            
            setOptimizedFormat(story.menuReformatDate != null);
        }
    }
    , [story]);

    async function addInsideMediaToStory(url, source, type){
        analyticsManager.logChooseInsideTag(storyId, source, type, true);
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);    
        const update = {
            tourMediasRef: arrayUnion(url),
            savedTime: new Date().getTime(),
        };
        if (story.tourMediasRef == null){
            update.virtualTourCenterXpct = 50;
            update.virtualTourCenterYpct = 25;
        }
        await updateDoc(docRef, update);
        setTimeout(()=>{setHighlight("inside")}, 1000);
    }

    async function addMenuMediaToStory(url, source, type){
        analyticsManager.logChooseMenuTag(storyId, source, type, true);
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {
            mediasRef: arrayUnion(url), 
            savedTime: new Date().getTime(),           
        };
        if (story.mediasRef == null){
            update.mediasCenterXpct = 50;
            update.mediasCenterYpct = 60;
        }
        await updateDoc(docRef, update);
        setTimeout(()=>{setHighlight("menu")}, 1000);
    }
    
    function getTouchElementPosition(e){              
        var mediasArray = Array.from(e.target.parentNode.parentNode.childNodes);
        let idx = -1;
        mediasArray.forEach((media) => { 
            if (media == e.target.parentNode) idx = mediasArray.indexOf(media);                
        });
        return idx;
    }

    function getTouchElementPositionFromCoords(e, containerId){    
        let containerElement = document.getElementById(containerId);    
        var mediasArray = Array.from(containerElement != null ? containerElement.childNodes : []);
        let idx = -1;
        mediasArray.forEach((media) => {
            if (media.className == "maker-gallery-container-box" && e.changedTouches[0].clientX > media.getBoundingClientRect().left && e.changedTouches[0].clientX < media.getBoundingClientRect().right && e.changedTouches[0].clientY > media.getBoundingClientRect().top && e.changedTouches[0].clientY < media.getBoundingClientRect().bottom){
                idx = mediasArray.indexOf(media);
            }            
        });
        return idx;
    }

    function onGalleryTouchStart(e){                
        setTouchStartElementPosition(getTouchElementPosition(e));
        setTouchStartMediaType(e.target.parentNode.parentNode.id);  
        dragTime.current = new Date().getTime();
        touchStartPositionRef.current = {targetId: e.target.id};         
    }

    function onGalleryTouchEnd(e){                       
        const touchEndElementPosition = getTouchElementPositionFromCoords(e, touchStartMediaType);        
        if (new Date().getTime() - dragTime.current < 200 && touchStartPositionRef.current.targetId === e.target.id && e.target.id.startsWith("delete-")){
            if (touchStartMediaType === "inside-gallery-container"){
                document.getElementById(e.target.id).click();
            }
            else if (touchStartMediaType === "menu-gallery-container"){
                document.getElementById(e.target.id).click();
            }            
            return false;
        }
        dragTime.current = 0;            
        touchStartPositionRef.current = null;
       
        if (touchEndElementPosition !== -1 && touchStartElementPosition !== touchEndElementPosition){
            if (touchStartMediaType === "inside-gallery-container"){
                invertInsideOrder();
            }
            else if (touchStartMediaType === "menu-gallery-container"){                
                const firstPosToInvert = touchStartElementPosition;
                const secondPosToInvert = touchEndElementPosition;                
                const medias = story.mediasRef;
                const tmp = medias[firstPosToInvert];
                medias[firstPosToInvert] = medias[secondPosToInvert];
                medias[secondPosToInvert] = tmp;

                updateMediaOrder(medias);
            }
        }
        setTouchStartElementPosition(-1);
        setTouchStartMediaType(null);
    }
    
    function showInsideGallery(){
        function showThumbnail(media){
            if (isImg(getFileFormat(getFileNameFromUrl(media)))){
                return (
                    <img alt={isFirefox() ? "":"thumbnail of a photo of the inside gallery"} onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} style={{color: 'transparent', borderRadius: 4,}} src={media} />
                );
            }
            else {
                return (
                    <video style={{borderRadius: 4,}}>
                        <source key={media} src={media} />
                    </video>
                );
            }
        }

        if (story != null && story.tourMediasRef != null){
            let mediaCounter = 0;
            const medias = story.tourMediasRef.map((media) =>{          
                mediaCounter++;
                return (
                    <div key={media} style={{position: 'relative', height: 100}} draggable="true" onDragStart={(e)=>handleDragStart(e)} onDragOver={(e)=>handleDragOver(e)} onDragEnd={(e)=>handleDragEnd(e)} onDrop={(e)=>handleDropForInsideMedia(e)} onTouchStart={(e)=>onGalleryTouchStart(e)} onTouchEnd={(e)=>onGalleryTouchEnd(e)} className="maker-gallery-container-box">
                        {showThumbnail(media)}
                        <div id={"delete-" + media} style={{cursor: 'pointer', background: "#FFFFFF", borderTopLeftRadius: 4, position: 'absolute', bottom: 0, right: 0, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteInsideMedia(media)}>x</div>
                    </div>
                );                                
            });
            return medias;
        }
        else return null;
    }

    async function deleteInsideMedia(media){
        analyticsManager.logChooseInsideTag(storyId, "inside", "photo", false);
        setFeatureReset(true);
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_INSIDE_PREVIEW, media)
            .then(()=>deleteInsideMediaDB(storyId, media))
            .then(()=>{
                const newSelectedInsideContents = Array.from(selectedInsideContents);
                newSelectedInsideContents.splice(selectedInsideContents.indexOf(media), 1);
                setSelectedInsideContents(newSelectedInsideContents);
            });                 
    }

    function showMenuGallery(){
        function showThumbnail(media){
            if (isImg(getFileFormat(getFileNameFromUrl(media)))){
                return (
                    <img alt={isFirefox() ? "":"thumbnail of a photo of the menu gallery"} onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} key={media} src={media} style={{color: 'transparent', borderRadius: 4,}}/> 
                );
            }
            else {
                return (
                    <video style={{borderRadius: 4,}}>
                        <source key={media} src={media} />
                    </video>
                );
            }
        }

        if (story != null && story.mediasRef != null){            
            const medias = story.mediasRef.map((media) =>{                                           
                return (
                    <div key={media} style={{position: 'relative', height: 100}} draggable="true" onDragStart={(e)=>handleDragStart(e)} onDragOver={(e)=>handleDragOver(e)} onDragEnd={(e)=>handleDragEnd(e)} onDrop={(e)=>handleDropForMenuMedia(e)} onTouchStart={(e)=>onGalleryTouchStart(e)} onTouchEnd={(e)=>onGalleryTouchEnd(e)} className="maker-gallery-container-box">
                        {showThumbnail(media)}
                        <div id={"delete-" + media} style={{cursor: 'pointer', background: "#FFFFFF", borderTopLeftRadius: 4, position: 'absolute', bottom: 0, right: 0, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteMenuMedia(media)}>x</div>
                    </div>
                );                                
            });
            return medias;
        }
        else return null;
    }

    async function deleteMenuMedia(media){        
        const imgOverVideo = isImg(getFileFormat(getFileNameFromUrl(media)));
        analyticsManager.logChooseMenuTag(storyId, "menu", imgOverVideo ? "photo" : "video", false);
        setFeatureReset(true);
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_MENU_PREVIEW, media)
            .then(()=>deleteMenuMediaDB())
            .then(()=>{
                const newSelectedMenuContents = Array.from(selectedMenuContents);
                newSelectedMenuContents.splice(selectedMenuContents.indexOf(media), 1);
                setSelectedMenuContents(newSelectedMenuContents);
            });

        async function deleteMenuMediaDB(){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);
            const update = {
                mediasRef: arrayRemove(media),
                savedTime: new Date().getTime(),
            };
            await updateDoc(docRef, update).then(
                () => deleteMenuMediaField(docRef)
            );
        }   
                
        async function deleteMenuMediaField(docRef){
            const story = await getDoc(docRef);    
            if (story.data().mediasRef != null && story.data().mediasRef.length === 0){
                const update2 = {
                    mediasRef: deleteField()
                };
                updateDoc(docRef, update2);                                
            } 
        }
    }

    let dragSrcEl = null;
    function handleDragStart(e) {
        e.target.opacity = '0.4';
        dragSrcEl = e.target;

        e.dataTransfer.effectAllowed = 'move';
      }
      
      function handleDragEnd(e) {
        e.target.opacity = '1';
      }    

      function handleDragOver(e) {
        e.preventDefault();
        return false;
      }

      function handleDropForInsideMedia(e) {
        e.stopPropagation(); // stops the browser from redirecting.        
        if (dragSrcEl != null && dragSrcEl.parentNode.parentNode.id == e.target.parentNode.parentNode.id && dragSrcEl !== e.target) {
            invertInsideOrder();
        }
        dragSrcEl = null;
    
        return false;
      }

      function handleDropForMenuMedia(e) {
        e.stopPropagation(); // stops the browser from redirecting.        
        if (dragSrcEl!= null && (dragSrcEl.parentNode.parentNode.id == e.target.parentNode.parentNode.id || dragSrcEl.parentNode.id == e.target.parentNode.parentNode.id)&& dragSrcEl !== e.target) {
            var mediasArray = Array.from(e.target.parentNode.parentNode.childNodes);
            mediasArray = mediasArray.filter((element) => element.className == "maker-gallery-container-box");
            
            const firstPosToInvert = mediasArray.findIndex((div) => div.childNodes[0].src == dragSrcEl.src || div.childNodes[0].childNodes[0] != null && dragSrcEl.childNodes[0] != null && div.childNodes[0].childNodes[0].src == dragSrcEl.childNodes[0].childNodes[0].src);
            const secondPosToInvert = mediasArray.findIndex((div) => div.childNodes[0].src == e.target.src || div.childNodes[0].childNodes[0] != null && div.childNodes[0].childNodes[0].src == e.target.src);
            const medias = story.mediasRef;
            const tmp = medias[firstPosToInvert];
            medias[firstPosToInvert] = medias[secondPosToInvert];
            medias[secondPosToInvert] = tmp;

            updateMediaOrder(medias);
          }
          dragSrcEl = null;
        
          return false;
      }

      async function invertInsideOrder(){
        analyticsManager.logDragNDropTag(storyId, "inside");     
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const firstTour = story.tourMediasRef[0];
        const update = {
            tourMediasRef: arrayRemove(firstTour),
            savedTime: new Date().getTime(),
        };
        await updateDoc(docRef, update).then(
            () => addNewPosition()
        );
        
        async function addNewPosition(){
            const update2 = {
                tourMediasRef: arrayUnion(firstTour)
            };
            await updateDoc(docRef, update2);
        }
    }

    async function updateMediaOrder(medias){  
        analyticsManager.logDragNDropTag(storyId, "menu");      
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {
            mediasRef: deleteField(),
            savedTime: new Date().getTime(),
        };
        await updateDoc(docRef, update).then(() =>
            addNewPosition()
        );
        
        async function addNewPosition(){
            const update2 = {
                mediasRef: medias
            };
            await updateDoc(docRef, update2);
        }
    }    

    function showInsideAdder(){
        if (story != null && (story.tourMediasRef == null || story.tourMediasRef.length < 2)){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_inside", true);setShowPicker(MEDIA_TYPE_INSIDE_PREVIEW);}}>
                    +
                </div>
            );
        }
    }

    function showMenuAdder(){
        if (story != null && (story.mediasRef == null || story.mediasRef.length < 4)){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_menu", true);setShowPicker(MEDIA_TYPE_MENU_PREVIEW);}}>
                    +
                </div>
            );
        }
    }

    useEffect(() => {
        updateStickerPositionOnDB();
       }, [stickerDrop]);

    async function updateStickerPositionOnDB(){        
        if (stickerDrop != null){           
            const xPct = Math.round((stickerDrop.x) * 100 / stickerMoveAreaWidth);
            const yPct = Math.round((stickerDrop.y) * 100 / stickerMoveAreaHeight);
                    
            //set x and y to firebase
            const db = getFirestore();   
            
            const docRef = doc(db, "stories", storyId);
            let update = {
                dimWithOffset: stickerDrop.dimWithOffset,
                offsetValue: stickerDrop.offsetValue,
                savedTime: new Date().getTime(),
            };

            if (stickerDrop.mediaType === insideType){
                update = {
                    virtualTourCenterXpct: xPct,
                    virtualTourCenterYpct: yPct,
                };
            }
            else if (stickerDrop.mediaType === menuType){
                update = {
                    mediasCenterXpct: xPct,
                    mediasCenterYpct: yPct,
                };
            }
            await updateDoc(docRef, update);

            setStickerDrop(null);
        }
    }    

    function showMenuAdderFR(){
        if (story.withMenu == null || !story.withMenu || !story.menuFile){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_full_menu_fr", true);setShowPicker(MEDIA_TYPE_MENU_FR);}}>
                    +<br/><div style={{fontSize: 16, fontWeight: 'bold'}}>{t("AddSymbolFR")}</div>
                </div>
            );
        }
    }

    function showMenuAdderEN(){
        if (story.withMenu == null || !story.withMenu || !story.menuFileEn){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_full_menu_en", true);setShowPicker(MEDIA_TYPE_MENU_EN);}}>
                    +<br/><div style={{fontSize: 16, fontWeight: 'bold'}}>{t("AddSymbolEN")}</div>
                </div>
            );
        }
    }

    function showMenuStylePreview(){        
        if (story.withMenu && fbResponse != null && !fbResponse.isAnonymous || fbResponse != null && fbResponse.isAnonymous){

            return (
                <div style={{marginTop: 10}}>                                          
                    {story.withMenu && fbResponse != null && !fbResponse.isAnonymous && 
                    <div style={{marginTop: 20, display: 'flex', flexDirection: "row", alignItems: 'center',}}>
                        <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, optimizedFormat ? "menu_style" : "cover_style", true);setShowMenuStyle(true);}} value={t('ChangeStyle')}/>
                        <div style={{marginLeft: (refreshStyle != null ? refreshStyle : story.menuStyle) == 'modern' ? 74 : 84}}>
                            <MenuStylePreview menuStyleTemp={(refreshStyle != null ? refreshStyle : story.menuStyle)} menuColorTemp={story.menuColor} multiplier={2.0} optimizedFormat={optimizedFormat} />
                        </div>                        
                    </div> 
                    }                                                    
                </div>    
            );
        }
    }

    function showInfoCreateAccountSnack(){
        if (showInfoCreateAccount){
            return (
                <Snackbar open={showInfoCreateAccount} message={t('InfoOptimMyMenu')} sx={{width : "100%"}} severity="info" autoHideDuration={4000} onClose={()=>setShowInfoCreateAccount(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}/>
            );
        }
        else return null;
    } 

    function showMenuSelection(menuVersion){
        const menuFile = menuVersion === MEDIA_TYPE_MENU_FR ? story.menuFile : story.menuFileEn;
        if (story.withMenu != null && story.withMenu && (menuVersion === MEDIA_TYPE_MENU_FR && story.menuFile || menuVersion === MEDIA_TYPE_MENU_EN && story.menuFileEn)){  
            let imgSrc= "";
            if (menuFile != null && !menuFile.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
                imgSrc = "/images/web_menu.png";
            }
            else if (menuFile != null && isImg(getFileFormat(getFileNameFromUrl(menuFile)))){
                imgSrc = "/images/img_menu.png";
            }
            else if (menuFile != null && getFileFormat(getFileNameFromUrl(menuFile)).toLowerCase() == "pdf"){
                imgSrc = "/images/pdf_menu.png";
            }

            return (
                <div style={{position: 'relative'}} className="maker-menu-box">
                    <img alt="file type of the menu" src={imgSrc} />
                    <div style={{position: 'absolute', top: 4, left: 4, fontSize: 12}}>{menuVersion === MEDIA_TYPE_MENU_FR ? t("AddSymbolFR") : t("AddSymbolEN")}</div>
                    <div style={{cursor: 'pointer', background: "#FFFFFF", borderTopLeftRadius: 4, position: 'absolute', bottom: 0, right: 0, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteMenu(menuVersion)}>x</div>
                </div>
            );                                            
        }
        else return null;
    }

    async function setMenuFile(url, source = null, type = null){        
        setMenuFileVersion(url, source, type, MEDIA_TYPE_MENU_FR);
    }

    async function setMenuFileEn(url, source = null, type = null){        
        setMenuFileVersion(url, source, type, MEDIA_TYPE_MENU_EN);
    }

    async function setMenuFileVersion(url, source = null, type = null, menuFileVersion){    
        const menuFile = menuFileVersion === MEDIA_TYPE_MENU_FR ? story.menuFile : story.menuFileEn;
        const tagSuffix = menuFileVersion === MEDIA_TYPE_MENU_FR ? "" : "en";
        
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);

        const emptyUrl = url === "";
        if (emptyUrl){
            url = deleteField();
            const fileFormat = getFileFormat(getFileNameFromUrl(menuFile));            
            const menuType = fileFormat == null ? "web_link" : fileFormat === "pdf" ? "pdf" : isImg(fileFormat) ? "photo" : isVideo(fileFormat) ? "video" : "web_link"
            analyticsManager.logChooseFullMenuTag(storyId, "choose_menu_form"+tagSuffix, menuType, false);
        }
        else {
            if (menuFileVersion === MEDIA_TYPE_MENU_FR){
                analyticsManager.logChooseFullMenuTag(storyId, source, type, true);
            }
            else if (menuFileVersion === MEDIA_TYPE_MENU_EN){
                analyticsManager.logChooseFullMenuEnTag(storyId, source, type, true);
            }
        }
        const update = {                   
            savedTime: new Date().getTime(),            
        };

        const noMoreMenuAfter = emptyUrl && (menuFileVersion === MEDIA_TYPE_MENU_FR && !story.menuFileEn || menuFileVersion === MEDIA_TYPE_MENU_EN && !story.menuFile);
        if (noMoreMenuAfter){
            update.withMenu = false;
        }
        else {
            update.withMenu = true;
        }

        if (menuFileVersion === MEDIA_TYPE_MENU_FR){
            update.menuFile = url;
        }
        else if (menuFileVersion === MEDIA_TYPE_MENU_EN){
            update.menuFileEn = url;
        }
        if (noMoreMenuAfter){
            update.menuId = deleteField();
            update.menuReformatDate = deleteField();
            update.menuOptimizedFormat = deleteField();
        }
        if (story.mediasRef == null){
            update.mediasCenterXpct = 50;
            update.mediasCenterYpct = 60;
        }
        
        await updateDoc(docRef, update);

        if (! noMoreMenuAfter)
            setTimeout(()=>{setHighlight("menu")}, 1000);
    }

    function deleteMenu(menuVersion){        
        deleteExistingMediaContent(story, storyId, baseStory, menuVersion)
            .then(()=>{if (menuVersion === MEDIA_TYPE_MENU_FR) {setMenuFile("");}else {setMenuFileEn("");}setFeatureReset(true);}); 
    }

    if (props.show) {
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;

        let onSuccess = null;
        let selectedContents = [];
        let setSelectedContents = null;
        let mediaContentType = null;
        switch (showPicker){
            case MEDIA_TYPE_MENU_FR:
                mediaContentType = MEDIA_TYPE_MENU_FR;
                onSuccess = setMenuFile;
                break;  
            case MEDIA_TYPE_MENU_EN:
                mediaContentType = MEDIA_TYPE_MENU_EN;
                onSuccess = setMenuFileEn;
                break; 
            case MEDIA_TYPE_INSIDE_PREVIEW:
                mediaContentType = MEDIA_TYPE_INSIDE_PREVIEW;
                selectedContents = selectedInsideContents;
                setSelectedContents = setSelectedInsideContents;
                onSuccess = addInsideMediaToStory;
                break;
            case MEDIA_TYPE_MENU_PREVIEW:
                mediaContentType = MEDIA_TYPE_MENU_PREVIEW;
                selectedContents = selectedMenuContents;
                setSelectedContents = setSelectedMenuContents;
                onSuccess = addMenuMediaToStory;
                break;
        }

        const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;
        const isDesktopTooSmall = !isTabletOrMobile && windowDimensions.height < 740;// 740 to calculate better using calculateMakerDesktopHeight()
        let additionalStyle = {};
        if(isMobileBottomBarDisplayed) additionalStyle = {overflowY: "scroll", height: windowDimensions.height - 56};
        if(isDesktopTooSmall) additionalStyle = {overflowY: "scroll", height: makerDesktopHeight - 16 * 2 - 36 - 8};

        return(
            <div>
                <div style={{padding: 16}}>
                    <div style={{...additionalStyle}}>
                        <div style={{fontSize: 28}}>{t('MyStickers')}</div>                           
                        <div style={{fontSize: 20, marginTop: 20}}>{t('RestaurantInside')}</div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <div id={"inside-gallery-container"} className='maker-gallery-container'>
                                {showInsideGallery()}
                                {showInsideAdder()}
                            </div>                        
                        </div>
                        <br/><br/>
                        <div style={{fontSize: 20}}>{t('AddMyMenu')}</div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <div>
                                {showMenuSelection(MEDIA_TYPE_MENU_FR)}
                                {showMenuAdderFR()}                                
                            </div>  
                            <div style={{marginLeft: 8}}>
                                {showMenuSelection(MEDIA_TYPE_MENU_EN)}
                                {showMenuAdderEN()}
                            </div>                                        
                        </div>                        
                        {showMenuStylePreview()} 
                        <div style={{fontSize: 16, marginTop: 20}}>{t('PlatesPreview')}</div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <div id={"menu-gallery-container"} className='maker-gallery-container'>
                                {showMenuGallery()}
                                {showMenuAdder()}
                            </div>                        
                        </div>
                        <MobileMenu storyId={storyId} story={story}/>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), left: 16, position: 'absolute'}}>
                            <MaterialButton id="restaurant-btn" style="outlined-tonal" onClick={() => { analyticsManager.logChooseRestaurantScreenViewTag(storyId);setActiveForm("choose-restaurant");}} value={t('StickersToRestaurant')} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, position: 'absolute'}}>
                            <MaterialButton id="actions-btn" style="filled-tonal" onClick={() => {analyticsManager.logChooseActionsScreenViewTag(storyId);setActiveForm("choose-actions");}} value={t('StickersToActions')}/>
                        </div>
                        <ContentPickerDialog showContentPickerDialog={showPicker != false} setShowContentPickerDialog={setShowPicker} mediaContentType={mediaContentType} bypassFromIG={bypassContentPickerLogin} resetBypassFromIg={setBypassContentPickerLogin} showMenuStyle={showMenuStyle} setShowMenuStyle={setShowMenuStyle} selectedContents={selectedContents} setSelectedContents={setSelectedContents} storyId={storyId} story={story} baseStory={baseStory} withProgress={true} onSuccess={onSuccess}  optimizedFormat={optimizedFormat} setOptimizedFormat={setOptimizedFormat}/> 
                        {isMobileBottomBarDisplayed && <div style={{height: 100}}/>}
                    </div>              
                </div>
                {showInfoCreateAccountSnack()}
                <MobileBottomBar currentTab="0" storyId={storyId} />
            </div>
        );
    }
    else return null;
}

export default ChooseStickersForm;