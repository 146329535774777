import './Player.css';

import React, {useContext, useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import { PlayerContext } from './PlayerContext.js';
import { useTranslation } from 'react-i18next';
import {getFileFormat, getFileNameFromUrl, getMimeTypeFromFileExtension, isInstagramInAppBrowser, isIOS, isIpad, isTikTokInAppBrowser} from './Utils';

function StoryVideo(props){
  const { t } = useTranslation();
  const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
  const storyDimensions = useContext(PlayerContext).get("storyDimensions");
  const playMode = useContext(PlayerContext).get("playMode");
  const fromDemo = useContext(PlayerContext).get("fromDemo");
  const idPrefix = fromDemo ? (fromDemo +"-") : "";
  const [lastVideoStarted, setLastVideoStarted] = useState(null);

  function showVideo(){     
    propagateFocusChange(true);    
    checkVideoRunning(props.story.videoRef, idPrefix);    
  }    

  useEffect(() => {
    if (props.story.videoRef == null){
      setLastVideoStarted(null);
    }
  }, [props.story.videoRef]);

  if (props.story.videoRef != null){

    let oncanplay = ()=>{};
    if (playMode === "preview"){
      oncanplay = () => {       
        // to have oncanplay trigger only 1 time 
        if (props.story.videoRef !== lastVideoStarted){
          props.setVideoLoaded(true);
          setLastVideoStarted(props.story.videoRef);
        }
      }
    }

    const videoMimeType = getMimeTypeFromFileExtension(getFileFormat(getFileNameFromUrl(props.story.videoRef)));
    if (! isIOS() || ! isTikTokInAppBrowser()){
      // playsinline make the video works on tiktok / ig tablet ios (wo : video opened in fs then close video refreshed the page) 
            
      let poster = "/images/transparent_image.png";
      if (isIpad() && isInstagramInAppBrowser()){
        poster = props.story.videoFSTnRef;
      }

      return (        
        <ReactPlayer id={idPrefix + "bgVideoContainer"} className="StoryVideo" onClick={showVideo} onContextMenu={(e) => e.preventDefault()} playsinline muted={true} controls={false} width={storyDimensions.originalVideoWidth == 0 ? storyDimensions.containerWidth : storyDimensions.originalVideoWidth} height={storyDimensions.originalVideoHeight == 0 ? storyDimensions.containerHeight : storyDimensions.originalVideoHeight} url={props.story.videoRef} playing={true} loop={true} style={{marginLeft: storyDimensions.offsetWidth, marginTop: storyDimensions.offsetHeight}} onReady={oncanplay} config={{attributes: {poster: poster, disablePictureInPicture: true}}}/>
      );
    }
    else {
      return (        
        <video id={idPrefix + "bgVideo"} className="StoryVideo" onClick={showVideo} onContextMenu={(e) => e.preventDefault()} autoPlay muted playsInline disablePictureInPicture loop width={storyDimensions.originalVideoWidth == 0 ? storyDimensions.containerWidth : storyDimensions.originalVideoWidth} style={{marginLeft: storyDimensions.offsetWidth, marginTop: storyDimensions.offsetHeight}} poster="/images/transparent_image.png">
          <source src={props.story.videoRef} type={videoMimeType} onCanPlay={oncanplay}/>  
        </video>
      );
    }      
  }
  else {    
    if (props.story.imageRef != null){
      //log("props.story.imageRef " + props.story.imageRef + " storyDimensions.originalVideoWidth " + storyDimensions.originalVideoWidth + " storyDimensions.offsetWidth " + storyDimensions.offsetWidth + " storyDimensions.offsetHeight " + storyDimensions.offsetHeight);
      return (
        <img alt={t('StoryBgDesc')} src={props.story.imageRef} className="StoryVideo" onContextMenu={(e) => e.preventDefault()} width={storyDimensions.originalVideoWidth} style={{marginLeft: storyDimensions.offsetWidth, marginTop: storyDimensions.offsetHeight}} onClick={()=>propagateFocusChange(true)}/>
      )
    }
    else {
      return(<>
        {!props.story.posId && <img src="/images/sparkles.gif" className="Sparkles" width="220px"/>}
        <img alt={t('StoryBgDesc')} src="/images/placeholder_storefront.jpg" className="StoryVideo" onContextMenu={(e) => e.preventDefault()} width={storyDimensions.originalVideoWidth} style={{marginLeft: storyDimensions.offsetWidth, marginTop: storyDimensions.offsetHeight}} onClick={()=>propagateFocusChange(true)}/>
        </>
      );
      // src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
    }
  }
}

export function getStoryDimensions(story, windowDimensions){
  
    const storyDimensions = {
      // dimensions of the video in the current player (with its specific dimensions) before center crop
      originalVideoWidth: 0,
      originalVideoHeight: 0,
      // dimensions of the current player
      containerWidth: 0,
      containerHeight: 0,
      // ~~~ the cropped dimension in the current player divided by 2 (to be used after to position the sticker relatively to its coordinate) or vice versa
      offsetWidth: 0,
      offsetHeight: 0,
      // ~~~ offset value reported in the current coordinate system or vice versa
      videoWidthOffset: 0,
      videoHeightOffset: 0,
    }
  
    if (story == null){
      return storyDimensions;
    } 

    let videoRatio = story.height / story.width;
    // trueDimensions = dimensions of the video before center crop on the player where it was recorded
    let trueVideoHeight = story.height;
    if (story.dimWithOffset === "height"){
      trueVideoHeight = story.height - story.offsetValue;
    }
    let trueVideoWidth = story.width;
    if (story.dimWithOffset === "width"){
      trueVideoWidth = story.width - story.offsetValue;
    }
    let trueVideoRatio = trueVideoHeight / trueVideoWidth;
  
    let displayHeight = windowDimensions.height;
    let displayWidth = windowDimensions.width;
    const portrait = windowDimensions.height > windowDimensions.width
    // now in full screen on tablets, (caused the menu to be not scrollable on snapchat browser)
    if (portrait){
      // nothing, smartphone portrait
      if (windowDimensions.width > 540 ){
        displayWidth = 540;    
      }
      if (windowDimensions.height > 960){
        displayHeight = 960;    
      } 
    }
    else {
      // tablet in particular 4/3 and desktop
      const ratioToApply = Math.max(videoRatio, 4/3);
      displayWidth = displayHeight / ratioToApply > windowDimensions.width ? windowDimensions.width : displayHeight / ratioToApply;      
    }
    
    let offsetWidth = 0;
    let offsetHeight = 0;
  
    let displayRatio = displayHeight / displayWidth;
    

    //log("displayRatio " + displayRatio + " videoRatio " + videoRatio + " trueVideoRatio " + trueVideoRatio)
  
    storyDimensions.containerWidth = displayWidth;
    storyDimensions.containerHeight = displayHeight;
  
    if (displayRatio >= trueVideoRatio){  
      // place will remain in height  
      if (story.dimWithOffset === "width") {  
        //log("cas 1");
          
        storyDimensions.originalVideoHeight = displayHeight;
        storyDimensions.originalVideoWidth = Math.round(displayHeight / videoRatio);

        offsetWidth = -1 * (displayWidth - storyDimensions.originalVideoWidth);
        storyDimensions.videoWidthOffset = Math.round(story.offsetValue * storyDimensions.originalVideoWidth / story.width);
      }
      else if (story.dimWithOffset === "height"){  
        //log("cas 2");
    
        storyDimensions.originalVideoHeight = Math.round(displayHeight * story.height / trueVideoHeight);
        storyDimensions.originalVideoWidth = Math.round(storyDimensions.originalVideoHeight / videoRatio);
         
        offsetHeight = 2 * (storyDimensions.originalVideoHeight - displayHeight) - Math.round(story.offsetValue * storyDimensions.originalVideoHeight / story.height);        
        offsetWidth = -1 * (displayWidth - storyDimensions.originalVideoWidth);    
        storyDimensions.videoHeightOffset = Math.round(story.offsetValue * storyDimensions.originalVideoHeight / story.height);    
      }
    } 
    else {
      // place will remain in width
      if (story.dimWithOffset === "height"){  
        //log("cas 3");
          
        storyDimensions.originalVideoWidth = Math.round(displayWidth * story.width / trueVideoWidth);
        storyDimensions.originalVideoHeight = Math.round(storyDimensions.originalVideoWidth * videoRatio);
        
        offsetHeight = storyDimensions.originalVideoHeight - displayHeight; 
        storyDimensions.videoHeightOffset = Math.round(story.offsetValue * storyDimensions.originalVideoHeight / story.height);     
      }
      else if (story.dimWithOffset === "width") { 
        //log("cas 4"); 
    
        storyDimensions.originalVideoWidth = Math.round(displayWidth * story.width / trueVideoWidth);
        storyDimensions.originalVideoHeight = Math.round(storyDimensions.originalVideoWidth * videoRatio);
  
        offsetWidth = Math.round(story.offsetValue * storyDimensions.originalVideoWidth / story.width);        
        offsetHeight = (storyDimensions.originalVideoHeight - displayHeight);
        storyDimensions.videoWidthOffset = Math.round(story.offsetValue * storyDimensions.originalVideoWidth / story.width);      
      }
     
    }
    //log("window w: " + windowDimensions.width + ", window h: " + windowDimensions.height);
    //log("cont w: " + storyDimensions.containerWidth + ", cont h: " + storyDimensions.containerHeight);
    //log("story w: " + storyDimensions.originalVideoWidth + ", story h: " + storyDimensions.originalVideoHeight);
  
    storyDimensions.offsetWidth = offsetWidth / 2 * -1;
    storyDimensions.offsetHeight = offsetHeight / 2 * -1;
  
    //log("clip w: " + storyDimensions.offsetWidth + ", clip h: " + storyDimensions.offsetHeight);

    return storyDimensions;
  }

export function checkVideoRunning(story, idPrefix){
  if (story.videoRef != null){
    const reactPlayerContainer = document.getElementById(idPrefix+"bgVideoContainer");
    if (reactPlayerContainer != null){
      const video = reactPlayerContainer.getElementsByTagName("video")[0];        
      playOrReload(video); 
    }
    else if (document.getElementById(idPrefix+"bgVideo") != null){
      const video = document.getElementById(idPrefix+"bgVideo");        
      playOrReload(video);    
    }
  }

  function playOrReload(video){      
    video.play().catch((error) => {          
      if (video.readyState === 0) {            
        video.load();
      }
    }); 
  }
}

export default StoryVideo;