import { useContext } from "react";
import { PlayerContext } from "../../PlayerContext";

const DialogContainer = ({children, ...props})=>{
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");

    if (props.opened){
        return (
            <div id="dialog-background" name="dialog-background" style={{position: "absolute", zIndex : 20, width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, background: "#FFFFFFB0", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", ...props.style}} onClick={(e)=>{if (e.target.id == "dialog-background") props.onClose();}}>
                <div style={{backgroundColor: "white", borderRadius: 20,}}>                    
                    {children}
                </div>
            </div>
        );
    }
    else return null;
}

export default DialogContainer;