import './../../Player.css';

import React, {useContext, useState} from 'react';
import { PlayerContext } from '../../PlayerContext';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { openFullscreen, getTextWidth, isAndroid, isInstagramInAppBrowser, isTikTokInAppBrowser } from '../../Utils';
import Sticker, { getStickerPosition} from './Sticker';
import { analyticsManager } from '../../../index';
import { appAnalyticsManager } from '../../Player';
import { insideType, menuType } from './StickerGallery';
import { set } from 'firebase/database';

// TODO restore last position bearing tilt on reopening
function StreetViewTour(props){
  // TODO 1 pixel at the top of title
  const [isVirtualTourOpened, setIsVirtualTourOpened] = useState(false);

  const pauseVideo = useContext(PlayerContext).get("pauseVideo");
  const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
  const activeFeature = useContext(PlayerContext).get("activeFeature");
  const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
  const storyDimensions = useContext(PlayerContext).get("storyDimensions");
  const secondaryColor = useContext(PlayerContext).get("secondaryColor");
  const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
  const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
  const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
  const fromMaker = useContext(PlayerContext).get("fromMaker");
  const fromDemo = useContext(PlayerContext).get("fromDemo");
  const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
  const playAudio = useContext(PlayerContext).get("playAudio");

  const { t } = useTranslation();
  const virtualTourStickerPosition = getStickerPosition(props.story, storyDimensions, insideType);

  const showInside = activeFeature == null || activeFeature === "inside";  

  function showStreetView(){    
    const panorama = new window.google.maps.StreetViewPanorama(
        document.getElementById("pano"), {fullscreenControl: false, addressControl: false, zoomControl: false, panControl: false, linksControl: true }
      );
    panorama.setPano(props.story.panoId);
    panorama.setVisible(true);
  }

  const openVirtualTour = () => {    
    if (!fromMaker && !fromDemo && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){
      setPlayAudio(true);
      const ambiantPlayer = document.getElementById("audio-player");
      if (ambiantPlayer && ambiantPlayer.paused){
        try {
          ambiantPlayer.play();                
        } catch(error){}
      }
    }
    pauseVideo(); 
    //checkBgVideoPlaying();    
    openFullscreen();
    setActiveFeature("inside");
    setIsVirtualTourOpened(true);

    if (appAnalyticsManager)
      appAnalyticsManager.clickedInside();

    // TODO do better
    setTimeout(showStreetView, 200);
  };

if (isVirtualTourOpened && activeFeature === "inside"){
    return (
      <div className="VirtualTourContainer">
        <div className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}}/>        
        <div className="VirtualTour" style={{width: storyDimensions.containerWidth - 64, height: Math.round(storyDimensions.containerHeight * 0.9)}}>      
          <div id="pano" className="VirtualTourPano" style={{width: storyDimensions.containerWidth - 64, height: Math.round(storyDimensions.containerHeight * 0.9)}}>
            <div className="VirtualTourTitle" style={{top: 0, left: 0, padding: 4, borderBottomRightRadius: 8}}>{t('VirtualTourTitle')}</div>
          </div> 
          <IconButton style={{position: 'absolute', top: 0, right: 0, zIndex: 30 }} onClick={propagateFocusChange}>
              <CloseIcon style={{color: secondaryColor, width: 48, height:48}} />
          </IconButton>           
        </div>        
      </div>
    );  
  }
  else {
    return <Sticker show={showInside} stickerType={insideType} open={openVirtualTour}/>;
  }

  function getStickerPosition(story, storyDimensions, stickerType){
    const stickerDimensions = {
      width: 0,
      height: 0
    };
    stickerDimensions.width = 8 + 16 + 4 + getTextWidth(t('VirtualTourTitle'), 22 + "px Roboto condensed") + 8;
    stickerDimensions.height = 8 + 22 * 1.2 + 8;

    const position = {
      x: 0,
      y: 0
    }
  
    if (story == null){
      return position;
    }          

    let selectorHeight = stickerDimensions.height / 2;
    let selectorWidth = stickerDimensions.width / 2;
  
    let xPositionPct = 0;
    let yPositionPct = 0;
    if (stickerType === menuType){
      xPositionPct = story.mediasCenterXpct;
      yPositionPct = story.mediasCenterYpct;
    }
    else if (stickerType === insideType){
      xPositionPct = story.virtualTourCenterXpct;
      yPositionPct = story.virtualTourCenterYpct;
    }

    //log("storyDimensions.videoWidthOffset " + storyDimensions.videoWidthOffset + " storyDimensions.videoHeightOffset " + storyDimensions.videoHeightOffset + " storyDimensions.offsetWidth " + storyDimensions.offsetWidth + " storyDimensions.offsetHeight " + storyDimensions.offsetHeight + " storyDimensions.originalVideoWidth " + storyDimensions.originalVideoWidth + " storyDimensions.originalVideoHeight " + storyDimensions.originalVideoHeight  + " xPositionPct " + xPositionPct + " yPositionPct " + yPositionPct + " selectorWidth " + selectorWidth + " selectorHeight " + selectorHeight);
 
    position.x = storyDimensions.videoWidthOffset / 2 + storyDimensions.offsetWidth + Math.round((storyDimensions.originalVideoWidth - storyDimensions.videoWidthOffset) * xPositionPct / 100) - selectorWidth;  
    position.y = storyDimensions.videoHeightOffset / 2 + storyDimensions.offsetHeight + Math.round((storyDimensions.originalVideoHeight - storyDimensions.videoHeightOffset) * yPositionPct / 100) - selectorHeight;

    return position;
  }
}

export default StreetViewTour;