import { useTranslation } from "react-i18next";
import { getTextWidth } from "../../player/Utils";
import { getEmojiFromPageType } from "../../player/overlay/stickers/Menu";
import {Image} from 'react-native';
import { MakerContext } from "../MakerContext";
import { useContext } from "react";

function MenuStylePreview(props) {
    const story = useContext(MakerContext).get("story");
    const { t } = useTranslation();  
    let titleStyle: CSSProperties = {};
    let imgSrc = "";
    let imgStyle: CSSProperties = {};
    let titleAddition = "";
    let textWidthAddition = 0;        
    if (t('Starter').length < 8)
        textWidthAddition = 6;
    switch (props.menuStyleTemp){
        case "elegant":
            titleStyle = {color: '#404040', fontSize: 44 / props.multiplier, fontFamily: 'Alex brush'};
            imgSrc = "/images/separatorElegant.png";
            // TODO make it proportional to text length
            imgStyle = {marginTop: 10 / props.multiplier, width: 120 / props.multiplier, height:8 / props.multiplier};
            break;
        case "friendly":
            titleStyle = {color: '#404040', fontSize: 36 / props.multiplier, fontFamily: 'Roboto condensed', fontWeight: 'bold'};
            imgSrc = "/images/separatorFriendly.png";
            titleAddition = " " + getEmojiFromPageType("Starter");
            imgStyle = {marginTop: 10 / props.multiplier, width: getTextWidth(t("Starter"), "36px Roboto condensed") / props.multiplier + textWidthAddition / props.multiplier, height:16 / props.multiplier};                
            break;
        case "modern":
            titleStyle = {color: '#404040', fontSize: 36 / props.multiplier, fontFamily: 'Roboto condensed', fontWeight: 'bold'};
            imgSrc = "";
            imgStyle = {};
            break;
        case "simple":    
            titleStyle = {color: '#404040', fontSize: 36 / props.multiplier, fontFamily: 'Roboto'};
            imgSrc = "/images/separatorSimple.png";
            imgStyle = {marginTop: 10 / props.multiplier, width: getTextWidth(t("Starter"), "36px Roboto") / props.multiplier, height:3 / props.multiplier};
            break;
        case "custom":    
            titleStyle = {color: '#404040', fontSize: 36 / props.multiplier, fontFamily: 'Roboto'};
            imgSrc = "";
            imgStyle = {marginTop: 10 / props.multiplier, width: getTextWidth(t("Custom"), "36px Roboto") / props.multiplier, height:3 / props.multiplier};
            break;
    }

    if (props.menuStyleTemp !== "modern"){   
        
        return (
            <div className="menu-page-title-container" style={{marginTop: 0}}>
                <div style={{...titleStyle}}>{props.menuStyleTemp !== "custom"? t("Starter") + titleAddition : t("Custom")}</div>
                {props.menuStyleTemp !== "custom" && <Image source={imgSrc} style={{...imgStyle,}} tintColor={props.optimizedFormat? props.menuColorTemp : story.primaryColor}/>}
            </div> 
        );
    }
    else {
        const titleSizeRoboto = getTextWidth(t("Starter"), "36px Roboto condensed");      
        const separatorLeftX = 0;
        const separatorRightX = (separatorLeftX + titleSizeRoboto + 27 - 92 + 27)/props.multiplier;            
        
        return (
            <div className='menu-page-title-container' style={{marginTop: 0, height: 66 / props.multiplier}}>
                <div style={{position: 'relative', width: (titleSizeRoboto/ props.multiplier + 2 * 27/ props.multiplier) , height: 66 / props.multiplier}}>
                    <div className='menu-page-title' style={{...titleStyle, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>{t("Starter")}</div>
                    <Image source="/images/separatorModernLeftLittle.png" style={{left: separatorLeftX, height: 66 / props.multiplier, width: 92 / props.multiplier, position:'absolute', top: '50%', transform: 'translate(0%, -50%)'}} tintColor={props.optimizedFormat? props.menuColorTemp : story.primaryColor}/>
                    <Image source="/images/separatorModernRightLittle.png" style={{left: separatorRightX, height: 66 / props.multiplier, width: 92 / props.multiplier, position:'absolute', top: '50%', transform: 'translate(0%, -50%)'}} tintColor={props.optimizedFormat? props.menuColorTemp : story.primaryColor}/>                            
                </div> 
            </div>                
        );
    }  
}

export default MenuStylePreview;