import React, {useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {MakerContext} from "./MakerContext";
import MaterialButton from './component/MaterialButton';
import {AppContext} from '../AppContext';
import { useNavigate } from "react-router-dom";
import { cleanMakerQueryString, updateStoryOrGoToStories } from './MakerController';
import LoginDialog from './LoginDialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { analyticsManager } from '..';
import MobileBottomBar from './component/MobileBottomBar';
import { useMediaQuery } from 'react-responsive';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

function UseIt(props) {
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const navigate = useNavigate();    
    const { t } = useTranslation();
    
    const setActiveForm = useContext(MakerContext).get("setActiveForm");
    const [showLogin, setShowLogin] = useState(false);      
    const [showAckLinkCopied, setShowAckLinkCopied] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const verticalMarginBtwElmts = makerDesktopHeight > 680 ? 60 : 40;    

    function showSaveButton(){
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous)){
            return null;
        }
        else {
            return (
                <div>                    
                    <div style={{fontSize: 14, fontStyle: 'italic', textAlign: "start", marginTop: verticalMarginBtwElmts}}>{t('SaveWork')}</div><br/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <MaterialButton style="filled" value={t("Save")} onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "use_it", true);setShowLogin(true);}}/>                                                
                    </div>
                </div>
            );
        }        
    }

    function onLogin(user){
        updateStoryOrGoToStories(user, storyId, story)
            .then((navigateToLogged)=>{
                if (navigateToLogged) 
                    navigate("/logged");
                else 
                    cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
                });
    }

    function showFormReturnOrMyStories(){
        if (!(fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous))){
            const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;

            return (                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), left: 16, position: 'absolute'}}>
                <MaterialButton style="outlined-tonal" onClick={() => {analyticsManager.logChooseActionsScreenViewTag(storyId);setActiveForm("choose-actions");}} value={t('UseItToMenu')} />
            </div>  
            );
        }
    }

    function showAckLinkCopiedSnack(){
        if (showAckLinkCopied){
            return (
                <Snackbar open={showAckLinkCopied} autoHideDuration={2000} onClose={()=>setShowAckLinkCopied(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowAckLinkCopied(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckLinkCopied')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    } 

    if (props.show) {                     

        async function copyLink(){
            analyticsManager.logCopyLinkTag(story.baseStoryId != null ? story.baseStoryId : storyId, "use_it");
            const db = getFirestore();  
            const docRef = doc(db, "stories", story.baseStoryId != null ? story.baseStoryId : storyId);
            const storyDoc = await getDoc(docRef);            
            navigator.clipboard.writeText(storyDoc.data().storyLink);
            setShowAckLinkCopied(true);
        }

        return(
            <div>
                <div style={{padding: 16, height: '100%', textAlign: 'center'}}>
                    <div style={{marginTop:verticalMarginBtwElmts, fontSize: 28}}>{t('Congratulation')}</div>                    
                    <div style={{fontSize: 20, marginTop: verticalMarginBtwElmts}}>{t("StoryLink")}</div><br/>
                    <div width={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <div><a href={story.storyLink} target="_blank">https://upreality.store/p/{story.alias}</a></div>
                        </div>
                    </div>                    
                    <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <MaterialButton style="filled" onClick={()=>copyLink()} value={t("CopyLink")} />
                    </div>                    
                    <div style={{fontSize: 20, marginTop: verticalMarginBtwElmts}}>{t('PossibleUsages')}</div><br/>
                    {t('SocialeProfile')}<br/><br/>                    
                    {t('SocialAds')}<br/><br/>
                    {t('Website')}
                    {showSaveButton()}
                    <LoginDialog origin="use_it" showLogin={showLogin !== false} customLoginButton={showLogin != false} title={t('Save')} resetLoginDialog={setShowLogin} onSuccess={(result)=>onLogin(result.user)}/>
                    {showFormReturnOrMyStories()}  
                    {showAckLinkCopiedSnack()}                          
                </div>
                <MobileBottomBar currentTab="0" storyId={storyId} /> 
            </div>
        );
    }
    else return null;
}

export default UseIt;