import {Image} from 'react-native';
import { PlayerContext } from '../../PlayerContext';
import { useContext, useState } from 'react';
import { getPlus200Color, isMacOS, isSafari } from '../../Utils';
import { ButtonBase, styled } from '@mui/material';
import { get } from 'firebase/database';

function CloseButton(props){
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier"); 
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        if (!(isMacOS() && isSafari())){
            setIsHovered(true);
        }
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const RoundButton = styled(ButtonBase)(({ theme }) => ({
        color: "white",
        backgroundColor: "transparent",
        '&:hover': {
            backgroundColor: "FFFFFF80", 
        },
    }));

    /*function showWhiteSquare(){
        let whiteSquare = document.getElementById("close-btn-img");
        if (whiteSquare != null){            
            whiteSquare.style.visibility = "visible";
        }    

         onLoad={()=>showWhiteSquare()}
    }*/

    return (
        <div onClick={(e)=>props.onClick(e)} style={{display: "flex", flexDirection: "column",  justifyContent: "center", alignContent: "center", alignItems: "center", ...props.style}} className={props.className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {/*!props.transparentCross && <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" style={{position: "absolute", visibility: 'hidden', width: (props.width ? props.width * miniMultiplier : 50 * miniMultiplier)/1.5, height: (props.height ? props.height * miniMultiplier : 48 * miniMultiplier)/1.5, borderRadius: 8 * miniMultiplier, backgroundColor: "white", pointerEvents: 'none'}}/>*/}
            <Image source={props.forceImage ? props.forceImage : "images/round_close.png"} style={{width: props.width ? props.width * miniMultiplier : 50 * miniMultiplier, height: props.height ? props.height * miniMultiplier : 48 * miniMultiplier, pointerEvents: 'none', }} tintColor={!props.forceImage ? isHovered ? getPlus200Color(props.color) : props.color : null}/>            
            <RoundButton sx={{position: 'absolute', borderRadius: 20, width: props.width ? props.width * miniMultiplier : 50 * miniMultiplier, height: props.height ? props.height * miniMultiplier : 48 * miniMultiplier}}/>
        </div>
    );
}

export default CloseButton;