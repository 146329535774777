import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MaterialButton from './component/MaterialButton';
import { MEDIA_TYPE_AVATAR, MEDIA_TYPE_PROFILE, acceptTermsForUser, cleanMakerQueryString, createUserIfFirstLogin, getFBBusinessPages, getUser, isTermsAccepted, loginToAccessInstagramContent, loginToAccessInstagramContentWithRedirect, logloginByRedirect, onPageSelectedForImages, saveInstagramProfile } from './MakerController';
import { useContext } from 'react';
import {AppContext} from "../AppContext";
import {MakerContext} from "./MakerContext";
import { CircularProgress, DialogContent, RadioGroup, TextField } from '@mui/material';
import { Radio } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import * as Sentry from "@sentry/react";
import { isAndroid, log, printObj } from '../player/Utils';
import { FacebookAuthProvider, getAuth, getRedirectResult, sendSignInLinkToEmail, signInWithPopup, signInWithRedirect, signOut } from 'firebase/auth';
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useNavigate } from "react-router-dom";
import { analyticsManager, app } from '..';
import { getId, getInstallations } from '@firebase/installations';
import LineBreak from './component/LineBreak';

function LoginDialog(props) {
    // TODO a close by successfull login should not send a ga event popup false
    // TODO unify way to close dialog, with or without analytics event

    const [showLogin, setShowLogin] = useState(props.customLoginButton === true);
    
    const [dialogErrorMsg, setDialogErrorMsg] = useState(null);
    const [showPageChooser, setShowPageChooser] = useState(false); 
    const [showEmailChooser, setShowEmailChooser] = useState(false);
    const [showContinueToInstagram, setShowContinueToInstagram] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [fbPages, setFbPages] = useState(null); 
    const [fbLoginResponse, setFbLoginResponse] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [fromRedirectOrAutologinTested, setFromRedirectOrAutologinTested] = useState(false);
    const navigate = useNavigate();

    const makerCtx = useContext(MakerContext);
    let fbAccessToken = null;
    let setFbAccessToken = null;
    let instagramAccountId = null;
    let setInstagramAccountId = null;
    let instagramLoginType = null;
    let setInstagramLoginType = null;
    let story = null;
    let storyId = null;    
    let justSignedFromRedirect = null;
    if (makerCtx != null){
        fbAccessToken = makerCtx.get("fbAccessToken");
        setFbAccessToken = makerCtx.get("setFbAccessToken");
        instagramAccountId = makerCtx.get("instagramAccountId");
        setInstagramAccountId = makerCtx.get("setInstagramAccountId");
        instagramLoginType = makerCtx.get("instagramLoginType");
        setInstagramLoginType = makerCtx.get("setInstagramLoginType");
        story = makerCtx.get("story");
        storyId = makerCtx.get("storyId");
        justSignedFromRedirect = makerCtx.get("justSignedFromRedirect");
    }
    const fbResponse = useContext(AppContext).get("fbResponse");

    const { t } = useTranslation();

    useEffect(() => {      
        if (props.bypassFromIG === true ){  
            log("LoginDialog bypass IG"); 
            showLoginOrPageContent();
            props.resetBypassFromIg(false);                            
        }
      }, [props.bypassFromIG]);

    useEffect(() => {     
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous) && emailSent ){   
            window.close();
        }
    }, [fbResponse, emailSent]);

    const currentRoute = useLocation();
    useEffect(() => {    
        // conflict with auto login from product page
        async function observeAuth(){ 
            // On the maker, then account login with fb redirect
            // TODO redirect on the page where the user was before login
            const auth = getAuth();
            await auth.authStateReady();
            await getRedirectResult(auth)
                .then((response) => {                                     
                    if (response != null){  
                        // query string is cleared in the onSuccess callback (used in the callback of MyAccountMenu when a create account is made from UseIt)
                        // redirect from facebook login                        
                        setShowLogin(true);
                        setShowProgress(true);
                        logloginByRedirect(analyticsManager, response.user.uid);
                        createUserIfFirstLogin(response.user.uid, response.user.email, response.user.providerData[0].uid, response)     
                            .then(
                                (response)=>{    
                                    const anonymousUid = fbResponse != null && fbResponse.isAnonymous ? fbResponse.uid : null;                    
                                    logLogin(response.getBack.user.uid, anonymousUid);                                                                                                                                                                              
                                    if (response.termsAccepted){
                                        close();
                                        props.onSuccess(response.getBack);
                                    }
                                    else {                         
                                        setFbLoginResponse(response.getBack);
                                        analyticsManager.logOpenPopupTag(storyId, "terms", true);
                                        setShowTerms(true);
                                    }                        
                                }
                            )                
                            .catch((error)=>{                     
                                if (error.code === "auth/account-exists-with-different-credential"){                        
                                    setDialogErrorMsg(t('ErrorAccountExistsWithDifferentCredential'));
                                    return;
                                }
                                try {
                                    setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                                    Sentry.captureMessage(printObj(error));
                                }
                                catch (e){
                                    setDialogErrorMsg(t('ErrorDefault'));
                                    Sentry.captureMessage(printObj(e));
                                }
                            })
                            .finally(()=>setShowProgress(false));

                    }
                    else {
                        // auto redirect to logged page if already logged on the product page
                        if (currentRoute.pathname === "/"){                            
                            const currentUser = getAuth().currentUser;
                            if (currentUser != null && (currentUser.isAnonymous == null || ! currentUser.isAnonymous)) {                 
                                getUser(currentUser.uid).then((user) => {                
                                    if (user.termsAccepted)
                                        navigate("/logged");
                                });            
                            }
                        }
                    }
                }).catch((error) => {                     
                    if (error.code === "auth/account-exists-with-different-credential"){     
                        setShowLogin(true);                   
                        setDialogErrorMsg(t('ErrorAccountExistsWithDifferentCredential'));                        
                    }                    
                    else if (error.code === 'auth/internal-error') {
                        Sentry.captureMessage("observeAuth auth/internal-error: " + printObj(error));
                        setShowLogin(true);
                        setDialogErrorMsg(t('ErrorSubscriptionLimitReached'));                        
                    }
                    else {
                        setShowLogin(true);
                        setDialogErrorMsg(t('ErrorDefault'));
                        Sentry.captureMessage(printObj(error));
                    }
                  }); 
        }

        // for a simple login from maker page we need to wait for the story to be created        
        if (!fromRedirectOrAutologinTested && (currentRoute.pathname === "/maker" && story != null || currentRoute.pathname === "/")){
            observeAuth(); 
            setFromRedirectOrAutologinTested(true);       
        }
    }, [story]);

    function close() {
        analyticsManager.logOpenPopupTag(storyId, props.origin, false);
        setShowProgress(false);
        setDialogErrorMsg(null);
        setShowLogin(false);
        if (props.resetLoginDialog != null)
            props.resetLoginDialog(false);
    } 
    
    function showProgressDialog(){                
        if (showProgress){
            return (
                <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16, paddingBottom: 32}}>                       
                    <CircularProgress sx={{color: '#2FC4B5', }}/>
                </DialogContent>
            );
        }else return null;
    }

    function showLoginDialog(){
        if (showLogin || props.showLogin)
            return (                
                <Dialog onClose={close} open={showLogin || props.showLogin}>     
                    <DialogTitle style={{userSelect: 'none'}}>{props.title}</DialogTitle>                 
                    {!showProgress && <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", padding: 16, paddingTop: 0}}>
                        {! isAndroid() && props.loginForContent === true && (<>{t('ConnectInstagramHelp')}<LineBreak/></>)}
                        {! isAndroid() && props.loginForContent === true && (<>{t('LoginWithFacebookBusinessHelp')}<LineBreak/></>)}    
                        {! isAndroid() && props.loginForContent !== true && (props.title === t('CreateAccount') || window.location.pathname.includes("maker")) && 
                            (<>{t('LoginHelp')} <LineBreak/></>)}    
                        {! isAndroid() && <MaterialButton style="facebook" icon="/images/ic_facebook.png" onClick={() => {simpleOrForContentFBLogin()}} value={t('LoginWithFacebookBusiness')}/>}
                        
                        <LineBreak/>
                        {props.loginForContent === true && props.mediaContentType != MEDIA_TYPE_AVATAR && (<><LineBreak/>{t('LoginWithInstagramHelp')}<LineBreak/></>)}
                        {props.loginForContent === true && props.mediaContentType != MEDIA_TYPE_AVATAR && (<><MaterialButton style="instagram-filled" color="#FFFFFF" bgGradientStart="#F1000C" bgGradientEnd="#C20098" icon="/images/ic_instagram.png" onClick={() => {instagramLogin()}} value={t('LoginWithInstagram')}/></>)}                        
                        {props.loginForContent !== true && !(props.origin === "preferences" && props.title === t('AssociateEmailToFacebook')) && (<><MaterialButton style="filled" color="#FFFFFF" icon="/images/ic_email.png" onClick={() => {analyticsManager.logLoginTag("email_link");analyticsManager.logOpenPopupTag(storyId, "choose_email", true);setShowEmailChooser(true);}} value={t('ConnectEmailLink')}/></>)}
                        {showErrorMsg()}
                    </div>}
                    {showProgressDialog()}    
                    
                </Dialog>
            ); 
        else return null;

        function simpleOrForContentFBLogin(){
            setShowProgress(true);
            analyticsManager.logLoginTag("facebook");
            if (props.loginForContent === true)
                selectFBPageForIGContent();
            else
                simpleLogin();
        }

        async function selectFBPageForIGContent(){
            //if (! isAndroid()){
                loginToAccessInstagramContent(setFbAccessToken, storyId, story.uid, !fbResponse.isAnonymous)
                    .then((response)=> isTermsAccepted(storyId, response))
                    .then(
                        (termsResponse)=>{                     
                            setFbPages(termsResponse.getBack);                        
                            setDialogErrorMsg(null);                        
                            
                            if (termsResponse.termsAccepted){
                                close();
                                analyticsManager.logOpenPopupTag(storyId, "choose_page", true); 
                                setShowPageChooser(true);
                            }
                            else {
                                analyticsManager.logOpenPopupTag(storyId, "terms", true);
                                setShowTerms(true);
                            }
                        }
                    )                
                    .catch((error)=>{                     
                        if (error.code === "auth/account-exists-with-different-credential"){
                            setDialogErrorMsg(t('ErrorAccountExistsWithDifferentCredential'));
                            return;
                        }
                        else if (error.code === 'auth/internal-error') {
                            Sentry.captureMessage("selectFBPageForIGContent auth/internal-error: " + printObj(error));
                            setDialogErrorMsg(t('ErrorSubscriptionLimitReached'));                            
                            return;                            
                        }
                        try {
                            setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                            Sentry.captureMessage(printObj(error));
                        }
                        catch (e){
                            setDialogErrorMsg(t('ErrorDefault'));
                            Sentry.captureMessage(printObj(error));
                        }
                    })
                    .finally(()=>setShowProgress(false));
            /*}
            else {
                await storeUserId();
                if (window.history.pushState) {   
                    const anonymousUid = fbResponse != null && fbResponse.isAnonymous ? fbResponse.uid : null;
                    const loggedUid = fbResponse != null && !fbResponse.isAnonymous ? fbResponse.uid : null;
                    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;    
                    newurl += "?redirect_to=" + props.mediaContentType + "&story_id=" + storyId + "&ani=" + anonymousUid + "&loi=" + loggedUid;
                    window.history.pushState({path:newurl},'',newurl);
                }
                loginToAccessInstagramContentWithRedirect();
            }*/
        }                        

        function showErrorMsg(){
            if (dialogErrorMsg != null){
                return (
                    <div style={{color: 'red', textAlign: 'start', fontSize: 14}}><br/><br/>{dialogErrorMsg}</div>
                );
            }
            else return null;
        }
    }

    async function simpleLogin(){   
        const anonymousUid = fbResponse != null && fbResponse.isAnonymous ? fbResponse.uid : null;
        if (window.history.pushState) {   
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;    
            if (currentRoute.pathname === "/maker" && props.mediaContentType == null){    
                newurl += "?login_title=" + props.title + "&ani=" + anonymousUid;
            }
            else if (currentRoute.pathname === "/") {   
                newurl += "?login_title=" + props.title + "&ani=" + anonymousUid;
            }
            window.history.pushState({path:newurl},'',newurl);
        }      
        const auth = getAuth();
        const provider = new FacebookAuthProvider();      
        provider.addScope('email');
        provider.setCustomParameters({
            'display': 'popup'
          });

        //if (! isAndroid()){        
            signInWithPopup(auth, provider)
                .then((response) => createUserIfFirstLogin(response.user.uid, response.user.email, response.user.providerData[0].uid, response))     
                .then(
                    (response)=>{                             
                        logLogin(response.getBack.user.uid, anonymousUid);                                                                                                                                                                              
                        if (response.termsAccepted){
                            close();
                            props.onSuccess(response.getBack);
                        }
                        else {                         
                            setFbLoginResponse(response.getBack);
                            analyticsManager.logOpenPopupTag(storyId, "terms", true);
                            setShowTerms(true);
                        }                        
                    }
                )                
                .catch((error)=>{                                            
                    if (error.code === "auth/account-exists-with-different-credential"){                        
                        setDialogErrorMsg(t('ErrorAccountExistsWithDifferentCredential'));
                        return;
                    }
                    else if (error.code === 'auth/internal-error') {
                        Sentry.captureMessage("simpleLogin auth/internal-error: " + printObj(error));
                        setDialogErrorMsg(t('ErrorSubscriptionLimitReached'));
                        return;
                    }
                    try {
                        setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                        Sentry.captureMessage(printObj(error));
                    }
                    catch (e){
                        setDialogErrorMsg(t('ErrorDefault'));
                        Sentry.captureMessage(printObj(e));
                    }
                })
                .finally(()=>setShowProgress(false));
        /*}
        else {
            await storeUserId();
            signInWithRedirect(auth, provider);
        }*/                  
    } 

    async function logLogin(uid, anonymousUid){
        const firebaseInstallationId = await getId(getInstallations(app));
        if (anonymousUid != null)
             analyticsManager.logLoginFromAnonymousTag(uid, anonymousUid);
        else
            analyticsManager.logFBIdToLoggedTag(firebaseInstallationId, uid);
    }

    function showTermsDialog(){
        if (showTerms){ 
            const label = { inputProps: { 'aria-label': 'Checkbox' } };       
            return (
                <Dialog onClose={()=>{}} open={showTerms}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('TermsTitle')}</DialogTitle>  
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", padding: 16}}>                        
                        {t('TermsText1')}<span style={{textDecoration: 'underline', color: '#0000EE', cursor: 'pointer'}} onClick={(e)=>{analyticsManager.logReadCGUTag("login");window.open("/terms/cgu.pdf", "_blank");}}>{t('TermsText2')}</span>{t('TermsText3')} 
                        <Checkbox {...label} id="terms" name="terms" sx={{
                            color: "#2FC4B5",
                            '&.Mui-checked': {
                            color: "#2FC4B5",
                            },
                        }}/>
                        <div style={{marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', alignItems: "flex-end", alignContent: 'flex-end', justifyContent: 'flex-end'}}>
                            <MaterialButton style="outlined-tonal" onClick={()=>cancel()} value={t("Cancel")} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<MaterialButton style="filled" onClick={()=>confirm()} value={t("Confirm")} />
                        </div>
                    </div>                        
                </Dialog>
            );              
        }
        else return null;

        function confirm(){            
            if (document.getElementById("terms").checked === false)
                return;

            close();
            analyticsManager.logAcceptTermsTag(true);
            if (props.loginForContent === true){                                               
                acceptTermsForUser(fbResponse.uid);
                setShowPageChooser(true);
            }
            else {                                     
                acceptTermsForUser(fbLoginResponse.user.uid);
                setDialogErrorMsg(null);
                setShowLogin(false);
                if (props.resetLoginDialog != null)
                    props.resetLoginDialog(false);
                props.onSuccess(fbLoginResponse);
            }           
        }

        function cancel(){           
            analyticsManager.logAcceptTermsTag(false);
            signOut(getAuth())
                    .then(() => {
                        if (props.loginForContent !== true){
                            close();
                        }
                    })
                    .then(() => {navigate("/?ackDisconnected=true");}); 
        }

        function close(){          
            setShowTerms(false);
        }
    }

    function instagramLogin(){    
        if (fbResponse == null || fbResponse != null && fbResponse.isAnonymous){
            analyticsManager.logLoginTag("email_link");
            analyticsManager.logOpenPopupTag(storyId, "choose_email", true);
            setShowEmailChooser(true);
        }
        else {
            if (justSignedFromRedirect){
                setShowContinueToInstagram(true);
                analyticsManager.logOpenPopupTag(storyId, "continue_instagram", true);
            }
            else {
                selectContentOnInstagram();
            }
        }
    }

    function showContinueToInstagramDialog(){
        if (showContinueToInstagram){
    
            return (
                <Dialog onClose={close} open={showContinueToInstagram}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('ContinueToInstagramTitle')}</DialogTitle>  
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", padding: 16, paddingTop : 0}}>
                        {props.mediaContentType === MEDIA_TYPE_PROFILE ? t('ContinueToInstagramProfile') : t('ContinueToInstagram')}
                        <LineBreak/>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: "flex-end", alignContent: 'flex-end', justifyContent: 'flex-end'}}>
                            <MaterialButton style="filled" onClick={()=>{selectContentOnInstagram();}} value={t("Confirm")} />
                        </div>
                    </div>
                </Dialog>
            );              
        }
        else return null;        

        function close(){
            setShowContinueToInstagram(false);
            analyticsManager.logOpenPopupTag(storyId, "continue_instagram", false);
        }
    }

    function selectContentOnInstagram(){
        analyticsManager.logLoginTag("instagram");
        let redirectUri = process.env.REACT_APP_HOST + "/maker";
        window.localStorage.setItem('redirect_to', props.mediaContentType);                
        window.location= "https://www.instagram.com/oauth/authorize?client_id=705854840346593&redirect_uri=" + redirectUri + "&scope=business_basic&response_type=code&state=" + story.baseStoryId; //always connected in this state and always a baseStoryId
    }

    function showEmailChooserDialog(){
        if (showEmailChooser){
    
            return (
                <Dialog onClose={close} open={showEmailChooser}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('EnterEmail')}</DialogTitle>  
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", padding: 16}}>
                        {showFormOrAckMsg()}
                    </div>
                </Dialog>
            );              
        }
        else return null;

        function showFormOrAckMsg(){
            if (emailSent === false){
                return (
                    <>
                        <div style={{fontStyle: 'italic'}}>
                        {t('EnterEmailHelp')}<br/>
                        {props.loginForContent === true && (<>{t('EnterEmailHelp2')}<br/><br/></>)}
                        <br/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                            <TextField id="email" type="text" placeholder={t('EnterEmailHint')} style={{marginRight: 8}}/><MaterialButton style="text" onClick={()=>document.getElementById('email').value=""} value="X"/>
                        </div>                       
                        <div style={{marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', alignItems: "flex-end", alignContent: 'flex-end', justifyContent: 'flex-end'}}>
                            <MaterialButton style="outlined-tonal" onClick={close} value={t("Cancel")} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<MaterialButton style="filled" onClick={()=>{sendEmail(document.getElementById("email").value);}} value={t("Confirm")} />
                        </div>
                    </>
                );
            }
            else {
                return (<>{t('EmailSent')}</>);
            }
        }

        function sendEmail(email){          
            const auth = getAuth();
            const anonymousIdQueryParams = fbResponse != null ? (fbResponse.isAnonymous ? "&ani=" + fbResponse.uid : "") : "";
            const storyIdQueryParams = storyId != null ? "&story_id=" + storyId : "";
            const actionCodeSettings = {                    
                url: process.env.REACT_APP_HOST + "/maker?redirect_to=" + (props.mediaContentType != null ? props.mediaContentType : "logged") + anonymousIdQueryParams + storyIdQueryParams,
                handleCodeInApp: true,
                };
            analyticsManager.logSendEmailLinkTag();
            sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {                       
                    window.localStorage.setItem('emailForSignIn', email);
                    storeUserId();
                    setEmailSent(true);
                });            
        }

        function close(){
            if (! emailSent){
                analyticsManager.logOpenPopupTag(storyId, "choose_email", false);
                setShowEmailChooser(false);
            }
        }
    }

    async function storeUserId(){
        const firebaseInstallationId = await getId(getInstallations(app));                
        window.localStorage.setItem('previous_user_id', fbResponse != null ? fbResponse.uid : firebaseInstallationId);
        window.localStorage.setItem('previous_user_id_type', fbResponse != null ? "anonymous" : "firebase");
    }

    function showPageChooserDialog(){
        if (showPageChooser){
            const radiosList = fbPages.map((page) => {                
                return (
                    <FormControlLabel key={page.id} value={page.connected_instagram_account.id} control={<Radio />} label={page.name} />
                );
            });
    
            return (
                <Dialog onClose={close} open={showPageChooser}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('SelectFBPage')}</DialogTitle>  
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", padding: 16}}>
                        <RadioGroup id="facebook-page" name="facebook-page" onChange={()=>{analyticsManager.logChoosePageTag(storyId);onPageSelected();}}>
                            {radiosList}
                        </RadioGroup>
                    </div>
                </Dialog>
            );              
        }
        else return null;
    
        function close(){  
            analyticsManager.logOpenPopupTag(storyId, "choose_page", false);          
            setShowPageChooser(false);
        } 

        async function onPageSelected(){              
            setShowPageChooser(false);            
            return onPageSelectedForImages(storyId, fbAccessToken, props.mediaContentType)
                .then((igAccountId)=>{
                    setInstagramAccountId(igAccountId);
                    setInstagramLoginType("fb");
                    const result = {
                        igAccountId: igAccountId,
                        loginType: "fb"
                    };
                    return result;
                })
                .then((result)=>props.onPageSelected(result.igAccountId, result.loginType))
                .catch((error)=>{
                    setFbAccessToken(null);
                    setInstagramAccountId(null);  
                    setInstagramLoginType(null);    
                    props.onPageSelected(error, "fb")
                    Sentry.captureMessage(printObj(error));
                });                            
        }
        
    }

    function showLoginOrPageContent(){        
        const popupToOpen = new URLSearchParams(window.location.search).get("redirect_to");
        const storyIdFromParams = new URLSearchParams(window.location.search).get("story_id");

        if (fbAccessToken != null && instagramAccountId != null){              
            props.onPageSelected(instagramAccountId, instagramLoginType);   
            if (props.mediaContentType === MEDIA_TYPE_PROFILE){
                saveInstagramProfile(storyId, instagramAccountId, fbAccessToken, instagramLoginType !== "ig" );
            }     
        }
        else if (fbResponse != null && !fbResponse.isAnonymous && (fbResponse.providerData[0].providerId === "password" && fbResponse.providerData.length === 1 || fbResponse.providerData.length === 2 && justSignedFromRedirect === "email")){
            // do not propose facebook login when signin by email without fb association
            instagramLogin();
        }
        // fbAccessToken is set 
        else if (fbResponse != null && !fbResponse.isAnonymous && popupToOpen != null && storyIdFromParams != null){
            // redirect from facebook login with redirect
            cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
            setShowProgress(true);
            getFBBusinessPages(fbAccessToken)                
                .then(
                    (response)=>{                     
                        setFbPages(response);                        
                        setDialogErrorMsg(null);                        
                        
                        close();
                        analyticsManager.logOpenPopupTag(storyIdFromParams, "choose_page", true); 
                        setShowPageChooser(true);                        
                    }
                )                
                .catch((error)=>{                     
                    try {
                        setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                        Sentry.captureMessage(printObj(error));
                    }
                    catch (e){
                        setDialogErrorMsg(t('ErrorDefault'));
                        Sentry.captureMessage(printObj(error));
                    }
                })
                .finally(()=>setShowProgress(false));
        }
        else{            
            analyticsManager.logOpenPopupTag(storyIdFromParams, props.origin, true);
            setShowLogin(true);
        }
    }

    function showInstagramLoginBtn(){
        if (props.customLoginButton == null){
            if (props.filled === "app")
                return <MaterialButton style="filled" icon="/images/ic_login_white.png" value={props.buttonText} onClick={()=>showLoginOrPageContent()}/>;
            else if (props.filled === "instagram")
                return <MaterialButton style="instagram-filled" icon="/images/ic_instagram.png" bgGradientStart="#F1000C" bgGradientEnd="#C20098" value={props.buttonText} onClick={()=>showLoginOrPageContent()}/>;
            else
                return <MaterialButton  style="instagram-outlined" icon="/images/ic_instagram_color.png" value={props.buttonText} onClick={()=>showLoginOrPageContent()}/>;
        } else return null;
    }

    return <>
            {showInstagramLoginBtn()}
            {showLoginDialog()}
            {showTermsDialog()}
            {showContinueToInstagramDialog()}
            {showPageChooserDialog()}
            {showEmailChooserDialog()}
            </>;    
}

export default LoginDialog;